import CopyButton from '../JMDashboard/CopyButton'
import { DDSTypography } from 'den-design-system'
import JMStringConstants from '../../../constants/JMStringConstants'
import React from 'react'
import pallete from '../../../global/newPallete'
import { useStoreContext } from '../../../store/StoreContext'

const JMIntegrate = () => {
  const store = useStoreContext()
  const codeString = `# import JobManager to monitor the Jobs in the application.
const { JobManager } = require('@foxsenseinnovations/vigil-sdk-js');

cron.schedule('<cron-expression>',() => {
    try {
        // log that the job triggered with a start message.
        JobManager.captureJobStart({
            jobSlug: <job-slug>,
            message: <job-start-message>
        });
        ...
        ...

        // log that the job has completed its execution with a success message.
        JobManager.captureJobEnd({
             jobSlug: <job-slug>,
            message: <job-end-message>
        });
    } catch(error) {
        // if the job fails at some point,
        // then its failure can be logged with a failure message.
        JobManager.captureJobFailure({
             jobSlug: <job-slug>,
            message: <job-failure-message>
        });
    }
});`
  return (
    <div className='flex flex-col pt-[16px] pb-[20px] gap-[24px]'>
      <div className='flex flex-col py-[8px] gap-[8px] w-full'>
        <DDSTypography.Title
          type='h1'
          variant='bold'
          color={pallete.colors.textDark3}
        >
          {JMStringConstants.INTEGRATE_TITLE} 🔭
        </DDSTypography.Title>
        <DDSTypography.Title
          type='h4'
          variant='medium'
          color={pallete.colors.darkGrey}
        >
          {JMStringConstants.INTEGRATE_CAPTION}
        </DDSTypography.Title>
      </div>
      <div
        className={`flex flex-col py-[16px] px-[20px] gap-[16px] border rounded-[6px] ${store.uiStore.getSidebarExpanded() ? ' md:h-[520px] 2xl:h-[690px]' : 'h-[520px] 2xl:h-[690px]'}`}
        style={{ borderColor: pallete.colors.stroke2 }}
      >
        <div className='flex justify-between items-center'>
          <div className='w-full'>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {JMStringConstants.CODE_SNIPPET}
            </DDSTypography.Paragraph>
          </div>
          <CopyButton copyText={codeString} />
        </div>
        <div
          className='flex flex-col p-[16px] overflow-auto h-full rounded-[6px]'
          style={{ backgroundColor: pallete.colors.surface6 }}
        >
          <pre>
            <code>
              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark5}
              >
                {codeString}
              </DDSTypography.Paragraph>
            </code>
          </pre>
        </div>
      </div>
    </div>
  )
}

export default JMIntegrate
