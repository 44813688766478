import React, { useEffect, useState } from 'react'
import {
  getColorOfFeature,
  getIsMonthlyLimit,
  mapFeatureLabels
} from '../../../../../utils/SubscriptionUtils'

import { DDSTypography } from 'den-design-system'
import { Feature } from '../../../../../enum/Feature.enum'
import { OrganizationUserRoles } from '../../../../../enum/OrganizationUserRoles.enum'
import Spinner from '../../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../../constants/StringConstants'
import Table from '../../../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../../components/common/DDS/Toast/Toast'
import { getOrganizationUsageReport } from '../../../../../api/subscription/Subscription.service'
import { getUserDetails } from '../../../../../api/user/User.service'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../../global/newPallete'
import { replaceUrl } from '../../../../../utils/UrlUtils'
import routeConstants from '../../../../../constants/RouteConstants'
import { useStoreContext } from '../../../../../store/StoreContext'

type SubscriptionDetails = {
  id: string
  plan: any
  planId: string
  status: any
  jobMonitoring: number
  apiMonitoring: number
  applicationHealthCheck: number
  errorManagement: number
  project: number
  environment: number
  user: number
  dataRetention: number
  nextBillingAt: Date
  additionalBenefits?: any
  billingPeriodUnit: string
  billingPeriod: number
  subscriptionAmount: string
}

const BillingTable = () => {
  const [totalCount, setTotalCount] = useState<number>()
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<SubscriptionDetails>()
  const [featureUsageData, setFeatureUsageData] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const storeContext = useStoreContext()

  useEffect(() => {
    setIsLoading(true)
    getUserDetails()
      .then((userResponse: any) => {
        const organizationIndex = userResponse.data.organizations.findIndex(
          (org: any) => {
            return (
              org.id === storeContext.userStore.getSelectedOrganization().id &&
              org.membership.role !== OrganizationUserRoles.MEMBER
            )
          }
        )
        storeContext.userStore.setOrganizations([
          ...userResponse.data.organizations
        ])

        // if (organizationIndex === -1) {
        //   navigate(routeConstants.UNAUTHORIZED_PAGE)
        // }

        const subscriptionDetails = storeContext.userStore
          .getOrganizations()
          .find(
            (organization: any) =>
              organization.id === storeContext.scopeStore.getOrganizationId()
          ).subscription

        setSubscriptionDetails(subscriptionDetails)
        storeContext.uiStore.setGlobalLoader(false)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (subscriptionDetails) {
      setIsLoading(true)
      getOrganizationUsageReport()
        .then((response: any) => {
          const featureArray = [
            Feature.USER,
            Feature.PROJECT,
            Feature.ENVIRONMENT
          ]
          const filteredData = response.data.usageData.filter(
            (element: any) => {
              if (!featureArray.includes(element['feature'])) return element
            }
          )
          setFeatureUsageData(filteredData)
        })
        .catch((error) => {
          console.log(
            'Error while fetching organization usage report :: ',
            error
          )
          if (error.data.statusCode !== 400)
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.SUBSCRIPTION.GET_ORGANIZATION_USAGE
            })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [storeContext.userStore.getOrganizations(), subscriptionDetails])

  useEffect(() => {
    storeContext.breadcrumbStore.setMultipleBreadcrumbsOptions([
      {
        label: (
          <div
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            className='cursor-not-allowed '
          >
            <div
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              className='pointer-events-none'
            >
              Manage Organization
            </div>
          </div>
        ),
        link:
          replaceUrl(
            routeConstants.MANAGE_ORGANIZATION,
            storeContext.scopeStore.getScope()['organization-id'] as string
          ) + '?tab=1'
      },
      {
        label: 'Billing',
        link:
          replaceUrl(
            routeConstants.MANAGE_ORGANIZATION,
            storeContext.scopeStore.getScope()['organization-id'] as string
          ) + '?tab=1'
      }
    ])
  }, [storeContext.breadcrumbStore.getBreadcrumbsOptions()])

  const columns = [
    {
      columnHeaderStyle: { width: '300px' },
      columnDataStyle: { width: '300px' },
      heading: (
        <div className='flex justify-start text-start  row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Features
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'feature',
      render: (text: any) => {
        return (
          <div className='flex justify-center text-start row items-start'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              color={pallete.colors.textDark4}
              className='tooltip-container sm:block flex items-center overflow-hidden max-w-[300px]'
              style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
            >
              {mapFeatureLabels(text)}
            </DDSTypography.Paragraph>
          </div>
        )
      }
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Total Utilised
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'active',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={getColorOfFeature(fullItem)}
            className='tooltip-container sm:block overflow-hidden truncate '
          >
            {getIsMonthlyLimit(fullItem) ? `${text} / month` : text}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Maximum Limit
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'maxLimit',
      render: (text: any, fullItem: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {getIsMonthlyLimit(fullItem) ? `${text} / month` : text}
          </DDSTypography.Paragraph>
        </div>
      )
    }
  ]
  return (
    <div>
      <div className='py-[24px]'>
        {subscriptionDetails ? (
          <Table
            tableStyle={{
              borderColor: pallete.colors.stroke2
            }}
            data={featureUsageData}
            columns={columns}
            showPagination={false}
            totalCount={totalCount as number}
            loading={isLoading}
          />
        ) : !isLoading ? (
          <DDSTypography.Paragraph size='para'>
            Reach out to{' '}
            <a href={`mailto:${StringConstants.SUPPORT_EMAIL}`}>
              {StringConstants.SUPPORT_EMAIL}
            </a>{' '}
            to get payment information and past invoices
          </DDSTypography.Paragraph>
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default observer(BillingTable)
