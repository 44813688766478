import ApiManager from './Api.manager'
import HttpConstants from '../constants/HttpConstants'
import UrlConstants from '../constants/UrlConstants'

export const login = async (payload: {
  email: string
  password: string
  captcha: string | null
}) => {
  const usecase = UrlConstants.SIGNIN.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.POST_METHOD, payload)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        if (error.status === 400) {
          reject(error?.data?.message ?? error?.data?.error?.message)
        } else if (error.status === 401) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}

export const forgotPassword = async (payload: {
  email: string
  captcha: string | null
}) => {
  const usecase = UrlConstants.FORGOT_PASSWORD.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.POST_METHOD, payload)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}

export const resetPassword = async (payload: {
  password: string
  $token: string
}) => {
  const usecase = UrlConstants.RESET_PASSWORD.USECASE
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(usecase, HttpConstants.POST_METHOD, payload)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(`Error in ${usecase}:`, error)
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}

/**
 * Service function to resend reset password link
 * @param {string} email - email of the user who requests resend reset password
 */
export const resendResetPassword = async (email: string) => {
  return new Promise((resolve, reject) => {
    ApiManager.makeApiCall(
      UrlConstants.RESEND_RESET_PASSWORD.USECASE,
      HttpConstants.POST_METHOD,
      {
        email
      }
    )
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data)
        } else {
          throw response
        }
      })
      .catch((error) => {
        console.log(
          `Error in ${UrlConstants.RESEND_RESET_PASSWORD.USECASE} ::`,
          error
        )
        if (error.status === 400) {
          reject(error.data.error.message)
        } else if (error.status === 401) {
          reject(error.data.message)
        } else {
          reject(error.data)
        }
      })
  })
}
