import React, { useEffect, useState } from 'react'
import {
  UrlWithOrgProjEnvIds,
  getAllQueryParamsAsObjectFromUrl
} from '../../../utils/UrlUtils'
import { msToTimeUnits, timeZone } from '../../../utils/DateTimeUtils'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { ChevronRight } from '@carbon/icons-react'
import { DDSTypography } from 'den-design-system'
import { Link } from '@mui/material'
import NoFilterImage from '../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../assets/images/setting.svg'
import Spinner from '../../../components/common/Spinner/Spinner'
import Status from '../../../components/APIMonitoring/Components/Status'
import StringConstants from '../../../constants/StringConstants'
import Table from '../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import { gaPageConstants } from '../../../constants/GAConstants'
import { getGroupsByApiUserId } from '../../../api/apiMonitoring/ApiMonitoring.service'
import { isNull } from 'lodash'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { timeFormats } from '../../../enum/TIME'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

const GroupedAPILogUserTable = () => {
  const [tableData, setTableData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)

  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()
  const { id } = useParams()

  const store = useStoreContext()
  const gaEventTracker = useAnalyticsEventTracker(
    gaPageConstants.API_MONITORING_TABLE
  )

  const viewGroupedApiDataById = async () => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    getGroupsByApiUserId({
      id: id as string,
      ...allQueryParams,
      size: StringConstants.DEFAULT_TABLE_SIZE
    })
      .then((response: any) => {
        setTableData(response.data)
        setTotalCount(response.totalCount)
        setCurrentPage(response.page)
        store.apiDetailsStore.setRefresh(false)
        store.filterStore.setFilters(response.filters)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
      viewGroupedApiDataById()
    } else {
      setSearchParams({ page: '1' })
    }
  }, [store.scopeStore.getScope(), searchParams])

  useEffect(() => {
    if (store.apiDetailsStore.getRefresh() === true) {
      setLoading(true)
      store.uiStore.setGlobalLoader(true)
      viewGroupedApiDataById()
    }
  }, [store.apiDetailsStore.getRefresh()])

  const columns = [
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-start text-start row items-start'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.ENDPOINT}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'urlTemplate',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start max-w-[200px]'>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text}
              className={`override-tooltip-arrow ${
                typeof text === 'string' && text.length > 15 ? text : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate max-w-[200px]'
                >
                  {text || text === 0 ? text : '-'}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row items-center justify-center text-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.HTTP_METHOD}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'httpMethod',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            {ApiGroupingStringConstants.API_COUNT}
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'apiCount',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text || text === 0 ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Last Call Date
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'lastCallDate',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {timeZone(
              store.userStore.getOrganizationPreference().timeZone,
              text,
              timeFormats.fullMonthDateYearTimeWithSeconds
            )}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Status
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'successApiCount',
      render: (text: any, fullItem: any) => {
        return (
          <div className='flex justify-center items-center'>
            <Status
              count={fullItem?.apiCount - fullItem?.successApiCount}
              label='Failure'
              textColor={pallete.colors.warningRed1}
              bgColor={pallete.colors.warningRed5}
              borderTopLeftRadius='4px'
              borderBottomLeftRadius='4px'
            />
            <Status
              count={fullItem?.successApiCount}
              label='Success'
              textColor={pallete.colors.warningGreen}
              bgColor={pallete.colors.warningGreenBg}
              borderTopRightRadius='4px'
              borderBottomRightRadius='4px'
            />
          </div>
        )
      }
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Average Response Time
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'averageResponseTimeInMilliSeconds',
      render: (text: any) => (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
            style={{ justifyContent: 'center' }}
            className='tooltip-container sm:block overflow-hidden truncate'
          >
            {text ? msToTimeUnits(text) : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },

    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => (
        <div className='cursor-pointer'>
          <ChevronRight
            size={16}
            onClick={() => {
              // Get the organization time zone or default to UTC
              const orgTimeZone =
                store.userStore.getOrganizationPreference()?.timeZone || 'UTC'

              // Get the current date and January 1 in the organization's time zone
              const nowInOrgTimeZone = moment.tz(orgTimeZone)
              const startOfYear = nowInOrgTimeZone
                .clone()
                .startOf('year')
                .toISOString()
              const endOfCurrentDay = nowInOrgTimeZone
                .clone()
                .endOf('day')
                .toISOString()

              // Parse current URL search parameters
              const searchParams = new URLSearchParams(window.location.search)
              const currentFilters: Record<string, string> = {}

              // Extract relevant filters or fallback to defaults
              currentFilters['created-from'] =
                searchParams.get('created-from') || startOfYear
              currentFilters['created-to'] =
                searchParams.get('created-to') || endOfCurrentDay
              currentFilters['time-zone'] =
                searchParams.get('time-zone') || orgTimeZone

              // Construct the base route using UrlWithOrgProjEnvIds
              const baseRoute = UrlWithOrgProjEnvIds(
                routeConstants.GROUPED_USER_API_LOGS_DETAILS.replace(
                  ':apiGroupId',
                  fullItem.id as string
                ).replace(':apiUserId', id as string),
                store
              )

              // Construct new search parameters with filters
              const newSearchParams = new URLSearchParams({
                apiOverviewTab: '1',
                ...currentFilters
              })

              // Navigate to the constructed route with updated query parameters
              navigate(`${baseRoute}?${newSearchParams.toString()}`)
            }}
            color={pallete.colors.FillButton}
          />
        </div>
      )
    }
  ]
  return (
    <>
      {loading || store.uiStore.getGlobalLoader() || !tableData ? (
        <Spinner />
      ) : totalCount > 0 ? (
        <div className='mt-[24px] flex w-full '>
          <Table
            tableStyle={{
              borderColor: pallete.colors.stroke2
            }}
            data={tableData}
            columns={columns}
            totalCount={totalCount}
            rowsPerPage={10}
            currentPage={currentPage}
            onPageChange={(page: number) => {
              const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                location.search
              )
              setCurrentPage(page)
              setSearchParams({ ...allQueryParams, page: page.toString() })
            }}
            loading={loading}
          />
        </div>
      ) : (
        <>
          <NoRecord
            imageSrc={
              totalCount === 0 && store.filterStore.getFiltersApplied()
                ? NoFilterImage
                : NoRecordImage
            }
            style={{ height: '74vh' }}
            text={
              totalCount === 0 && store.filterStore.getFiltersApplied() ? (
                <>No results found for applied filter.</>
              ) : (
                <>
                  Configure API Monitoring.
                  <Link
                    href={StringConstants.FULLSTACK_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ marginLeft: '4px' }}
                  >
                    Learn More
                  </Link>
                </>
              )
            }
          />
        </>
      )}
    </>
  )
}

export default observer(GroupedAPILogUserTable)
