import { Link, useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'

import { ProjectType } from '../../enum/ProjectType.enum'
import { isAuthenticatedUser } from '../../utils/AuthUtils'
import { isEmpty } from 'lodash'
import { observer } from 'mobx-react-lite'
import routeConstants from '../../constants/RouteConstants'
import { useStoreContext } from '../../store/StoreContext'

export default (
  Page: React.ComponentType<any>,
  accessRoles: string[],
  restrictProjectType?: ProjectType
) => {
  const SecureComponent = (props: any) => {
    const location = useLocation()
    const storeContext = useStoreContext()
    const navigate = useNavigate()
    useEffect(() => {
      let isAuthorized = false
      const isUserLoggedIn: boolean = isAuthenticatedUser()
      storeContext.userStore.setIsLoggedIn(isUserLoggedIn)
      if (isEmpty(storeContext.userStore.getEmail())) {
        let route = routeConstants.REDIRECT
        if (location && location.pathname) {
          const searchParams = location.search
            ? '&' + location.search.slice(1)
            : ''
          route += '?redirect=' + location.pathname + searchParams
        }
        navigate(route)
      } else {
        const role = storeContext.userStore.getUserRole()

        for (const roleName of accessRoles) {
          if (role === roleName) {
            isAuthorized = true
            break
          } else if (roleName === 'ALL') {
            isAuthorized = true
            break
          }
        }

        if (restrictProjectType) {
          if (
            restrictProjectType ===
            storeContext.projectAndEnvironmentStore.getSelectedProject().type
          ) {
            isAuthorized = false
          } else {
            isAuthorized = true
          }
        }
        if (!isAuthorized) {
          navigate(routeConstants.UNAUTHORIZED_PAGE)
        }
      }
    }, [storeContext.scopeStore.getScope()])

    useEffect(() => {
      if (!storeContext.userStore.getIsLoggedIn()) {
        navigate(routeConstants.SIGN_IN)
      }
    }, [storeContext.userStore.getIsLoggedIn()])

    return (
      <>{storeContext.userStore.getIsLoggedIn() ? <Page {...props} /> : null}</>
    )
  }
  return observer(SecureComponent)
}
