import { Box, TextField } from '@mui/material'
import {
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
  StaticDatePicker
} from '@mui/x-date-pickers'
import React, { useEffect } from 'react'
import { isSameDay, isWithinInterval } from 'date-fns'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { styled as MUIStyled } from '@mui/system'
import StringConstants from '../../../../constants/StringConstants'
import moment from 'moment'
import palette from '../../../../global/pallete'
import styled from '@emotion/styled'
import { useStoreContext } from '../../../../store/StoreContext'

interface DateRangePickerProps {
  startDate?: Date | null
  endDate?: Date | null
  onChange?: (dateRange: { start: Date | null; end: Date | null }) => void
  disableLastDays?: boolean
  dateSelect?: boolean
  AHCMinDate?: boolean
  APITimeFormat?: boolean
}
type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean
  isFirstDay: boolean
  isLastDay: boolean
}

const CustomPickersDay = MUIStyled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay'
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    backgroundColor: palette.colors.dateRangeBackground,
    color: palette.colors.white,
    '&:hover, &:focus': {
      backgroundColor: palette.colors.primary
    }
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  }),
  ...(isLastDay && {
    backgroundColor: palette.colors.primary,
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  })
})) as React.ComponentType<CustomPickerDayProps>

const DatePickerWrapper = styled.span`
  .MuiCalendarPicker-root .MuiTypography-root.MuiTypography-caption {
    margin: 0;
  }
  .MuiPickersCalendarHeader-label {
    font-size: 14px;
  }
`

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  AHCMinDate = false,
  APITimeFormat = false,
  onChange = () => null,
  disableLastDays = false,
  dateSelect
}) => {
  const [start, setStart] = React.useState<Date | null>(startDate || null)
  const [end, setEnd] = React.useState<Date | null>(endDate || null)
  const [clickFlag, setClickFlag] = React.useState<boolean>(false)
  const store = useStoreContext()
  useEffect(() => {
    setStart(startDate ? startDate : null)
    setEnd(endDate ? endDate : null)
  }, [startDate, endDate])

  useEffect(() => {
    onChange({ start, end })
  }, [clickFlag])

  const setDate = (value: Date | null) => {
    start?.setHours(0, 0, 0, 0)
    if (start === null) {
      setStart(value)
    } else if (
      start.getDate() === value?.getDate() &&
      !moment(new Date()).isSame(value, 'day')
    ) {
      value?.setHours(0, 0, 0, 0)
      if (moment(value).isBefore(start)) {
        setStart(value)
      } else {
        setEnd(value)
      }
    } else {
      if ((value as Date) >= start && !end) {
        if (moment(new Date()).isSame(value, 'day')) {
          value?.setHours(new Date().getHours())
          value?.setMinutes(new Date().getMinutes())
          value?.setSeconds(0)
        } else {
          value?.setHours(23)
          value?.setMinutes(59)
          value?.setSeconds(59)
        }
        setEnd(value)
      } else {
        setStart(value)
        setEnd(null)
      }
    }
  }
  const onDateSelect = (newValue: Date | null) => {
    setClickFlag((f) => !f)
    setDate(newValue)
  }
  const renderPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    const isMaxDate = isSameDay(date, maxDate)

    if (!start || !end || (dateSelect && !start && !end)) {
      return (
        <PickersDay
          disableMargin
          {...pickersDayProps}
          onClick={() => isSameDay(start as Date, date) && onDateSelect(date)}
          sx={{
            border: isMaxDate
              ? `1px solid ${palette.colors.textDark3}`
              : 'none',
            borderRadius: isMaxDate ? '50%' : 'none'
          }}
        />
      )
    }

    const startDateInternal = start as Date
    const endDateInternal = end as Date
    const dayIsBetween = isWithinInterval(date, {
      start: startDateInternal,
      end: endDateInternal
    })
    const isFirstDay = isSameDay(date, startDateInternal)
    const isLastDay = isSameDay(date, endDateInternal)
    const isActiveDay = dayIsBetween || isFirstDay || isLastDay

    return (
      <Box
        key={date.toDateString()}
        sx={{
          background: () =>
            isActiveDay && !isSameDay(startDateInternal, endDateInternal)
              ? palette.colors.dateRangeBackground
              : 'transparent',
          borderRadius: () => {
            if (isFirstDay) return '50% 0 0 50%'
            else if (isLastDay) return '0 50% 50% 0 '
            else return '1px 1px 1px 1px'
          }
        }}
      >
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin
          dayIsBetween={dayIsBetween}
          isFirstDay={isFirstDay}
          isLastDay={isLastDay}
        />
      </Box>
    )
  }
  const disableDates = (date: any) => {
    if (disableLastDays) {
      const currentTime = new Date()
      const differenceInTime = date.getTime() - currentTime.getTime()
      const differenceInDays = differenceInTime / (1000 * 3600 * 24)
      if (differenceInDays < StringConstants.AHC_DATE_RANGE_LIMIT) {
        return true
      }
    }
    return false
  }

  const userTimezone = store.userStore.getUserPreference().timeZone
  const organizationTimezone =
    store.userStore.getOrganizationPreference().timeZone

  const timezoneString = moment()
    .tz(APITimeFormat ? organizationTimezone : userTimezone)
    .format('YYYY-MM-DDTHH:mm:ss')

  const maxDate = new Date(timezoneString)

  const minDate = APITimeFormat
    ? moment()
        .tz(organizationTimezone)
        .subtract(90, 'days')
        .startOf('day')
        .toDate()
    : AHCMinDate
      ? moment().tz(userTimezone).subtract(88, 'days').startOf('day').toDate()
      : undefined

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePickerWrapper>
        <StaticDatePicker
          displayStaticWrapperAs='desktop'
          openTo='day'
          value={start}
          onChange={onDateSelect}
          renderInput={(params) => <TextField {...params} />}
          renderDay={renderPickerDay}
          orientation='portrait'
          views={['day']}
          shouldDisableDate={disableDates}
          maxDate={maxDate}
          minDate={minDate}
          disableHighlightToday={true}
        />
      </DatePickerWrapper>
    </LocalizationProvider>
  )
}

export default DateRangePicker
