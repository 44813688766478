const TimezoneConstants = [
  {
    label: '(GMT-11:00)',
    tzCode: 'Pacific/Niue'
  },
  {
    label: '(GMT-11:00)',
    tzCode: 'Pacific/Pago_Pago'
  },
  {
    label: '(GMT-10:00)',
    tzCode: 'Pacific/Honolulu'
  },
  {
    label: '(GMT-10:00)',
    tzCode: 'Pacific/Rarotonga'
  },
  {
    label: '(GMT-10:00)',
    tzCode: 'Pacific/Tahiti'
  },
  {
    label: '(GMT-09:30)',
    tzCode: 'Pacific/Marquesas'
  },
  {
    label: '(GMT-09:00)',
    tzCode: 'America/Anchorage'
  },
  {
    label: '(GMT-09:00)',
    tzCode: 'Pacific/Gambier'
  },
  {
    label: '(GMT-08:00)',
    tzCode: 'America/Los_Angeles'
  },
  {
    label: '(GMT-08:00)',
    tzCode: 'America/Tijuana'
  },
  {
    label: '(GMT-08:00)',
    tzCode: 'America/Vancouver'
  },
  { label: '(GMT-08:00)', tzCode: 'America/Whitehorse' },
  {
    label: '(GMT-08:00)',
    tzCode: 'Pacific/Pitcairn'
  },
  { label: '(GMT-07:00)', tzCode: 'America/Denver' },
  { label: '(GMT-07:00)', tzCode: 'America/Phoenix' },
  { label: '(GMT-07:00)', tzCode: 'America/Mazatlan' },
  { label: '(GMT-07:00)', tzCode: 'America/Dawson_Creek' },
  { label: '(GMT-07:00)', tzCode: 'America/Edmonton' },
  { label: '(GMT-07:00)', tzCode: 'America/Hermosillo' },
  { label: '(GMT-07:00)', tzCode: 'America/Yellowknife' },
  { label: '(GMT-06:00)', tzCode: 'America/Belize' },
  { label: '(GMT-06:00)', tzCode: 'America/Chicago' },
  { label: '(GMT-06:00)', tzCode: 'America/Mexico_City' },
  { label: '(GMT-06:00)', tzCode: 'America/Regina' },
  { label: '(GMT-06:00)', tzCode: 'America/Tegucigalpa' },
  { label: '(GMT-06:00)', tzCode: 'America/Winnipeg' },
  { label: '(GMT-06:00)', tzCode: 'America/Costa_Rica' },
  { label: '(GMT-06:00)', tzCode: 'America/El_Salvador' },
  { label: '(GMT-06:00)', tzCode: 'Pacific/Galapagos' },
  { label: '(GMT-06:00)', tzCode: 'America/Guatemala' },
  { label: '(GMT-06:00)', tzCode: 'America/Managua' },
  { label: '(GMT-05:00)', tzCode: 'America/Cancun' },
  { label: '(GMT-05:00)', tzCode: 'America/Bogota' },
  { label: '(GMT-05:00)', tzCode: 'Pacific/Easter' },
  { label: '(GMT-05:00)', tzCode: 'America/New_York' },
  { label: '(GMT-05:00)', tzCode: 'America/Iqaluit' },
  { label: '(GMT-05:00)', tzCode: 'America/Toronto' },
  { label: '(GMT-05:00)', tzCode: 'America/Guayaquil' },
  { label: '(GMT-05:00)', tzCode: 'America/Havana' },
  { label: '(GMT-05:00)', tzCode: 'America/Jamaica' },
  { label: '(GMT-05:00)', tzCode: 'America/Lima' },
  { label: '(GMT-05:00)', tzCode: 'America/Nassau' },
  { label: '(GMT-05:00)', tzCode: 'America/Panama' },
  { label: '(GMT-05:00)', tzCode: 'America/Port-au-Prince' },
  { label: '(GMT-05:00)', tzCode: 'America/Rio_Branco' },
  { label: '(GMT-04:00)', tzCode: 'America/Halifax' },
  { label: '(GMT-04:00)', tzCode: 'America/Barbados' },
  { label: '(GMT-04:00)', tzCode: 'Atlantic/Bermuda' },
  { label: '(GMT-04:00)', tzCode: 'America/Boa_Vista' },
  { label: '(GMT-04:00)', tzCode: 'America/Caracas' },
  { label: '(GMT-04:00)', tzCode: 'America/Curacao' },
  { label: '(GMT-04:00)', tzCode: 'America/Grand_Turk' },
  { label: '(GMT-04:00)', tzCode: 'America/Guyana' },
  { label: '(GMT-04:00)', tzCode: 'America/La_Paz' },
  { label: '(GMT-04:00)', tzCode: 'America/Manaus' },
  { label: '(GMT-04:00)', tzCode: 'America/Martinique' },
  { label: '(GMT-04:00)', tzCode: 'America/Port_of_Spain' },
  { label: '(GMT-04:00)', tzCode: 'America/Porto_Velho' },
  { label: '(GMT-04:00)', tzCode: 'America/Puerto_Rico' },
  { label: '(GMT-04:00)', tzCode: 'America/Santo_Domingo' },
  { label: '(GMT-04:00)', tzCode: 'America/Thule' },
  { label: '(GMT-03:30)', tzCode: 'America/St_Johns' },
  { label: '(GMT-03:00)', tzCode: 'America/Araguaina' },
  { label: '(GMT-03:00)', tzCode: 'America/Asuncion' },
  { label: '(GMT-03:00)', tzCode: 'America/Belem' },
  { label: '(GMT-03:00)', tzCode: 'America/Argentina/Buenos_Aires' },
  { label: '(GMT-03:00)', tzCode: 'America/Campo_Grande' },
  { label: '(GMT-03:00)', tzCode: 'America/Cayenne' },
  { label: '(GMT-03:00)', tzCode: 'America/Cuiaba' },
  { label: '(GMT-03:00)', tzCode: 'America/Fortaleza' },
  { label: '(GMT-03:00)', tzCode: 'America/Godthab' },
  { label: '(GMT-03:00)', tzCode: 'America/Maceio' },
  { label: '(GMT-03:00)', tzCode: 'America/Miquelon' },
  { label: '(GMT-03:00)', tzCode: 'America/Montevideo' },
  { label: '(GMT-03:00)', tzCode: 'Antarctica/Palmer' },
  { label: '(GMT-03:00)', tzCode: 'America/Paramaribo' },
  { label: '(GMT-03:00)', tzCode: 'America/Punta_Arenas' },
  { label: '(GMT-03:00)', tzCode: 'America/Recife' },
  { label: '(GMT-03:00)', tzCode: 'Antarctica/Rothera' },
  { label: '(GMT-03:00)', tzCode: 'America/Bahia' },
  { label: '(GMT-03:00)', tzCode: 'America/Santiago' },
  { label: '(GMT-03:00)', tzCode: 'Atlantic/Stanley' },
  { label: '(GMT-02:00)', tzCode: 'America/Noronha' },
  { label: '(GMT-02:00)', tzCode: 'America/Sao_Paulo' },
  { label: '(GMT-02:00)', tzCode: 'Atlantic/South_Georgia' },
  { label: '(GMT-01:00)', tzCode: 'Atlantic/Azores' },
  {
    label: '(GMT-01:00)',
    tzCode: 'Atlantic/Cape_Verde'
  },
  {
    label: '(GMT-01:00)',
    tzCode: 'America/Scoresbysund'
  },
  {
    label: '(GMT+00:00)',
    tzCode: 'Africa/Abidjan'
  },
  {
    label: '(GMT+00:00) ',
    tzCode: 'Africa/Accra'
  },
  {
    label: '(GMT+00:00) ',
    tzCode: 'Africa/Bissau'
  },
  {
    label: '(GMT+00:00) ',
    tzCode: 'Atlantic/Canary'
  },
  {
    label: '(GMT+00:00) ',
    tzCode: 'Africa/Casablanca'
  },
  {
    label: '(GMT+00:00) ',
    tzCode: 'America/Danmarkshavn'
  },
  {
    label: '(GMT+00:00) ',
    tzCode: 'Europe/Dublin'
  },
  {
    label: '(GMT+00:00) ',
    tzCode: 'Africa/El_Aaiun'
  },
  {
    label: '(GMT+00:00) ',
    tzCode: 'Atlantic/Faroe'
  },
  {
    label: '(GMT+00:00)',
    tzCode: 'Etc/GMT'
  },
  {
    label: '(GMT+00:00) ',
    tzCode: 'Europe/Lisbon'
  },
  {
    label: '(GMT+00:00) ',
    tzCode: 'Europe/London'
  },
  {
    label: '(GMT+00:00) ',
    tzCode: 'Africa/Monrovia'
  },
  {
    label: '(GMT+00:00) ',
    tzCode: 'Atlantic/Reykjavik'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Africa/Algiers'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Amsterdam'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Andorra'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Berlin'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Brussels'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Budapest'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Belgrade'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Prague'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Africa/Ceuta'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Copenhagen'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Gibraltar'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Africa/Lagos'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Luxembourg'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Madrid'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Malta'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Monaco'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Africa/Ndjamena'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Oslo'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Paris'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Rome'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Stockholm'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Tirane'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Africa/Tunis'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Vienna'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Warsaw'
  },
  {
    label: '(GMT+01:00) ',
    tzCode: 'Europe/Zurich'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Asia/Amman'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Europe/Athens'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Asia/Beirut'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Europe/Bucharest'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Africa/Cairo'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Europe/Chisinau'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Asia/Damascus'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Asia/Gaza'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Europe/Helsinki'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Asia/Jerusalem'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Africa/Johannesburg'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Africa/Khartoum'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Europe/Kiev'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Africa/Maputo'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Europe/Kaliningrad'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Asia/Nicosia'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Europe/Riga'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Europe/Sofia'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Europe/Tallinn'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Africa/Tripoli'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Europe/Vilnius'
  },
  {
    label: '(GMT+02:00) ',
    tzCode: 'Africa/Windhoek'
  },
  {
    label: '(GMT+03:00) ',
    tzCode: 'Asia/Baghdad'
  },
  {
    label: '(GMT+03:00) ',
    tzCode: 'Europe/Istanbul'
  },
  {
    label: '(GMT+03:00) ',
    tzCode: 'Europe/Minsk'
  },
  {
    label: '(GMT+03:00)',
    tzCode: 'Europe/Moscow'
  },
  {
    label: '(GMT+03:00)',
    tzCode: 'Africa/Nairobi'
  },
  {
    label: '(GMT+03:00)',
    tzCode: 'Asia/Qatar'
  },
  {
    label: '(GMT+03:00)',
    tzCode: 'Asia/Riyadh'
  },
  {
    label: '(GMT+03:00)',
    tzCode: 'Antarctica/Syowa'
  },
  {
    label: '(GMT+03:30)',
    tzCode: 'Asia/Tehran'
  },
  {
    label: '(GMT+04:00)',
    tzCode: 'Asia/Baku'
  },
  {
    label: '(GMT+04:00)',
    tzCode: 'Asia/Dubai'
  },
  {
    label: '(GMT+04:00) ',
    tzCode: 'Indian/Mahe'
  },
  {
    label: '(GMT+04:00) ',
    tzCode: 'Indian/Mauritius'
  },
  {
    label: '(GMT+04:00)',
    tzCode: 'Europe/Samara'
  },
  {
    label: '(GMT+04:00)',
    tzCode: 'Indian/Reunion'
  },
  {
    label: '(GMT+04:00) ',
    tzCode: 'Asia/Tbilisi'
  },
  {
    label: '(GMT+04:00) ',
    tzCode: 'Asia/Yerevan'
  },
  {
    label: '(GMT+04:30) ',
    tzCode: 'Asia/Kabul'
  },
  {
    label: '(GMT+05:00) ',
    tzCode: 'Asia/Aqtau'
  },
  {
    label: '(GMT+05:00) ',
    tzCode: 'Asia/Aqtobe'
  },
  {
    label: '(GMT+05:00) ',
    tzCode: 'Asia/Ashgabat'
  },
  {
    label: '(GMT+05:00) ',
    tzCode: 'Asia/Dushanbe'
  },
  {
    label: '(GMT+05:00) ',
    tzCode: 'Asia/Karachi'
  },
  {
    label: '(GMT+05:00) ',
    tzCode: 'Indian/Kerguelen'
  },
  {
    label: '(GMT+05:00) ',
    tzCode: 'Indian/Maldives'
  },
  {
    label: '(GMT+05:00) ',
    tzCode: 'Antarctica/Mawson'
  },
  {
    label: '(GMT+05:00)',
    tzCode: 'Asia/Yekaterinburg'
  },
  {
    label: '(GMT+05:00)',
    tzCode: 'Asia/Tashkent'
  },
  {
    label: '(GMT+05:30)',
    tzCode: 'Asia/Colombo'
  },
  {
    label: '(GMT+05:30)',
    tzCode: 'Asia/Calcutta'
  },
  {
    label: '(GMT+05:45)',
    tzCode: 'Asia/Kathmandu'
  },
  {
    label: '(GMT+06:00)',
    tzCode: 'Asia/Almaty'
  },
  {
    label: '(GMT+06:00)',
    tzCode: 'Asia/Bishkek'
  },
  {
    label: '(GMT+06:00)',
    tzCode: 'Indian/Chagos'
  },
  {
    label: '(GMT+06:00)',
    tzCode: 'Asia/Dhaka'
  },
  {
    label: '(GMT+06:00)',
    tzCode: 'Asia/Omsk'
  },
  {
    label: '(GMT+06:00)',
    tzCode: 'Asia/Thimphu'
  },
  {
    label: '(GMT+06:00)',
    tzCode: 'Antarctica/Vostok'
  },
  {
    label: '(GMT+06:30)',
    tzCode: 'Indian/Cocos'
  },
  {
    label: '(GMT+06:30)',
    tzCode: 'Asia/Yangon'
  },
  {
    label: '(GMT+07:00)',
    tzCode: 'Asia/Bangkok'
  },
  {
    label: '(GMT+07:00)',
    tzCode: 'Indian/Christmas'
  },
  {
    label: '(GMT+07:00)',
    tzCode: 'Antarctica/Davis'
  },
  {
    label: '(GMT+07:00)',
    tzCode: 'Asia/Saigon'
  },
  {
    label: '(GMT+07:00)',
    tzCode: 'Asia/Hovd'
  },
  {
    label: '(GMT+07:00)',
    tzCode: 'Asia/Jakarta'
  },
  {
    label: '(GMT+07:00)',
    tzCode: 'Asia/Krasnoyarsk'
  },
  {
    label: '(GMT+08:00)',
    tzCode: 'Asia/Brunei'
  },
  {
    label: '(GMT+08:00)',
    tzCode: 'Asia/Shanghai'
  },
  {
    label: '(GMT+08:00)',
    tzCode: 'Asia/Choibalsan'
  },
  {
    label: '(GMT+08:00)',
    tzCode: 'Asia/Hong_Kong'
  },
  {
    label: '(GMT+08:00)',
    tzCode: 'Asia/Kuala_Lumpur'
  },
  {
    label: '(GMT+08:00)',
    tzCode: 'Asia/Macau'
  },
  {
    label: '(GMT+08:00)',
    tzCode: 'Asia/Makassar'
  },
  {
    label: '(GMT+08:00)',
    tzCode: 'Asia/Manila'
  },
  {
    label: '(GMT+08:00)',
    tzCode: 'Asia/Irkutsk'
  },
  {
    label: '(GMT+08:00)',
    tzCode: 'Asia/Singapore'
  },
  {
    label: '(GMT+08:00)',
    tzCode: 'Asia/Taipei'
  },
  {
    label: '(GMT+08:00) ',
    tzCode: 'Asia/Ulaanbaatar'
  },
  {
    label: '(GMT+08:00)',
    tzCode: 'Australia/Perth'
  },
  {
    label: '(GMT+08:30) ',
    tzCode: 'Asia/Pyongyang'
  },
  {
    label: '(GMT+09:00) ',
    tzCode: 'Asia/Dili'
  },
  {
    label: '(GMT+09:00) ',
    tzCode: 'Asia/Jayapura'
  },
  {
    label: '(GMT+09:00) ',
    tzCode: 'Asia/Yakutsk'
  },
  {
    label: '(GMT+09:00) ',
    tzCode: 'Pacific/Palau'
  },
  {
    label: '(GMT+09:00) ',
    tzCode: 'Asia/Seoul'
  },
  {
    label: '(GMT+09:00) ',
    tzCode: 'Asia/Tokyo'
  },
  {
    label: '(GMT+09:30) ',
    tzCode: 'Australia/Darwin'
  },
  {
    label: '(GMT+10:00) ',
    tzCode: 'Antarctica/DumontDUrville'
  },
  {
    label: '(GMT+10:00) ',
    tzCode: 'Australia/Brisbane'
  },
  {
    label: '(GMT+10:00) ',
    tzCode: 'Pacific/Guam'
  },
  {
    label: '(GMT+10:00)',
    tzCode: 'Asia/Vladivostok'
  },
  {
    label: '(GMT+10:00) ',
    tzCode: 'Pacific/Port_Moresby'
  },
  {
    label: '(GMT+10:00) ',
    tzCode: 'Pacific/Chuuk'
  },
  {
    label: '(GMT+10:30) ',
    tzCode: 'Australia/Adelaide'
  },
  {
    label: '(GMT+11:00) ',
    tzCode: 'Antarctica/Casey'
  },
  {
    label: '(GMT+11:00) ',
    tzCode: 'Australia/Hobart'
  },
  {
    label: '(GMT+11:00) ',
    tzCode: 'Australia/Sydney'
  },
  {
    label: '(GMT+11:00) ',
    tzCode: 'Pacific/Efate'
  },
  {
    label: '(GMT+11:00) ',
    tzCode: 'Pacific/Guadalcanal'
  },
  {
    label: '(GMT+11:00) ',
    tzCode: 'Pacific/Kosrae'
  },
  {
    label: '(GMT+11:00) ',
    tzCode: 'Asia/Magadan'
  },
  {
    label: '(GMT+11:00) ',
    tzCode: 'Pacific/Norfolk'
  },
  {
    label: '(GMT+11:00) ',
    tzCode: 'Pacific/Noumea'
  },
  {
    label: '(GMT+11:00) ',
    tzCode: 'Pacific/Pohnpei'
  },
  {
    label: '(GMT+12:00) ',
    tzCode: 'Pacific/Funafuti'
  },
  {
    label: '(GMT+12:00) ',
    tzCode: 'Pacific/Kwajalein'
  },
  {
    label: '(GMT+12:00) ',
    tzCode: 'Pacific/Majuro'
  },
  {
    label: '(GMT+12:00) ',
    tzCode: 'Asia/Kamchatka'
  },
  {
    label: '(GMT+12:00) ',
    tzCode: 'Pacific/Nauru'
  },
  {
    label: '(GMT+12:00) ',
    tzCode: 'Pacific/Tarawa'
  },
  {
    label: '(GMT+12:00) ',
    tzCode: 'Pacific/Wake'
  },
  {
    label: '(GMT+12:00) ',
    tzCode: 'Pacific/Wallis'
  },
  {
    label: '(GMT+13:00) ',
    tzCode: 'Pacific/Auckland'
  },
  {
    label: '(GMT+13:00) ',
    tzCode: 'Pacific/Enderbury'
  },
  {
    label: '(GMT+13:00) ',
    tzCode: 'Pacific/Fakaofo'
  },
  {
    label: '(GMT+13:00) ',
    tzCode: 'Pacific/Fiji'
  },
  {
    label: '(GMT+13:00) ',
    tzCode: 'Pacific/Tongatapu'
  },
  {
    label: '(GMT+14:00) ',
    tzCode: 'Pacific/Apia'
  },
  {
    label: '(GMT+14:00) ',
    tzCode: 'Pacific/Kiritimati'
  }
]
export default TimezoneConstants
