import { PauseOutline, Play, Share } from '@carbon/icons-react'
import React, { useEffect, useState } from 'react'
import {
  getAhcEventLogs,
  getAhcEventLogsFilters,
  getHealthReportDetails,
  updateHealthCheck
} from '../../../api/appHealthCheck/AHC.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceBadgeUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import { useParams, useSearchParams } from 'react-router-dom'

import { Button } from '../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import { FilterTypes } from '../../../enum/Filter.enum'
import SideChip from '../../../components/New/SideChip/SideChip'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import UCLogTable from '../../../components/UptimeChecksPage/UCLogTable'
import UCOverview from '../../../components/UptimeChecksPage/UCOverview'
import UCSharePopup from '../../../components/UptimeChecksPage/UCSharePopup'
import UptimeStringConstants from '../../../constants/UptimeStringConstants'
import { convertToFormat } from '../../../utils/UCUtils'
import { getFiltersForApi } from '../../../utils/FilterUtils'
import moment from 'moment'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useStoreContext } from '../../../store/StoreContext'

const UCOverviewLogTable = () => {
  const store = useStoreContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState<string>(
    searchParams.get('tab') ?? '1'
  )
  const [sharePopupOpen, setSharePopupOpen] = useState<boolean>(false)

  const [logLoading, setLogLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [healthData, setHealthData] = useState<any>()

  const [pause, setPause] = useState<boolean>(false)
  const [dateFilter, setDateFilter] = useState<any>(false)

  const [logsData, setLogsData] = useState<any>()
  const [totalCount, setTotalCount] = useState<number>(0)
  const [filterLoading, setFilterLoading] = useState<boolean>(false)
  const [eventLogFilters, setEventLogFilters] = useState<any>()
  const { id } = useParams()
  const [filterCache, setFilterCache] = useState<{ [key: string]: any[] }>({})
  const userTimeZone = store.userStore.getUserPreference().timeZone
  const loadFilters = (key: string, apiCall: () => void) => {
    if (filterCache[key]) {
      setEventLogFilters(filterCache[key])
    } else {
      apiCall()
    }
  }

  const fetchEventLogFilters = () => {
    setFilterLoading(true)
    getAhcEventLogsFilters()
      .then((response: any) => {
        setEventLogFilters(response.data)
        setFilterCache((prev) => ({
          ...prev,
          fetchEventLogFilters: response.data
        }))
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: err
        })
      })
      .finally(() => {
        setFilterLoading(false)
      })
  }

  const handleFilterLoadUrl = () =>
    loadFilters('fetchEventLogFilters', fetchEventLogFilters)

  useEffect(() => {
    store.filterStore.setFiltersApplied(false)
    if (activeTab === '1') {
      null
    } else {
      fetchHealthOverview()
    }
  }, [activeTab])
  const fetchHealthOverview = () => {
    setLoading(true)
    getHealthReportDetails({ $ahcId: id as string })
      .then((response: any) => {
        setHealthData(response.data)
        setPause(!response.data.monitorAppHealthCheck)
        if (response.data.length !== 0) {
          if (
            response.filters['created-to'] &&
            moment(response.filters['created-to']).isAfter(new Date())
          ) {
            const currentDay = new Date()
            currentDay.setSeconds(0)
            response.filters['created-to'] = currentDay.toISOString()
          }
          setDateFilter(response.filters)
        }
      })
      .finally(() => setLoading(false))
  }

  const handlePauseCheck = (status: boolean) => {
    setLoading(true)
    updateHealthCheck({
      $ahcId: id as string,
      monitorAppHealthCheck: !status
    })
      .then((response: any) => {
        setHealthData({
          ...healthData,
          ['monitorAppHealthCheck']: response.data.monitorAppHealthCheck
        })
        setPause(!response.data.monitorAppHealthCheck)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.AHC.UPDATE_CHECKS.SUCCESS
        })
        if (activeTab === '1') {
          fetchHealthOverview()
        } else if (activeTab === '2') {
          fetchHealthEventLogs()
        }
      })
      .catch((error) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.AHC.UPDATE_CHECKS.ERROR
        })
      })
      .finally(() => setLoading(false))
  }

  const fetchHealthEventLogs = () => {
    const filters = getFiltersForApi(
      FilterTypes.AHC_LOGS,
      getAllQueryParamsAsObjectFromUrl(location.search)
    )

    setLogLoading(true)
    getAhcEventLogs({
      $ahcId: id as string,
      page: parseInt(searchParams.get('page') ?? '1', 10),
      size: 10,
      ...filters
    })
      .then((response: any) => {
        setLogsData(response.data)
        setTotalCount(response.totalCount)
        if (response.data.length !== 0) {
          if (
            response.filters['created-to'] &&
            moment(response.filters['created-to']).isAfter(new Date())
          ) {
            const currentDay = new Date()
            currentDay.setSeconds(0)
            response.filters['created-to'] = currentDay.toISOString()
          }
          setDateFilter(response.filters)
        }
        store.filterStore.setFilters(response.filters)
        store.filterStore.setFilterDisabled(false)
      })
      .catch((error: any) => {
        ToastNotification({
          type: 'error',
          message: error.message
        })
      })
      .finally(() => {
        setLogLoading(false)
      })
  }

  const tabs = [
    {
      label:
        activeTab === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            Overview
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            Overview
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <UCOverview
            healthData={healthData}
            loading={loading}
            fetchHealthOverview={fetchHealthOverview}
          />
        </div>
      )
    },
    {
      label:
        activeTab === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            Log Table
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            Log Table
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <UCLogTable
            eventLogFilters={eventLogFilters}
            filterLoading={filterLoading}
            fetchEventLogFilters={handleFilterLoadUrl}
            logsData={logsData}
            fetchHealthEventLogs={fetchHealthEventLogs}
            logLoading={logLoading}
            totalLogCount={totalCount}
          />
        </div>
      )
    }
  ]

  useEffect(() => {
    store.breadcrumbStore.setBreadcrumbsOptions({
      label: UptimeStringConstants.UPTIME_CHECKS,
      link: replaceUrl(
        routeConstants.AHC,
        store.scopeStore.getScope()['organization-id'] as string,
        store.scopeStore.getScope()['project-id'] as string,
        store.scopeStore.getScope()['environment-id'] as string
      )
    })
  }, [])

  useEffect(() => {
    if (healthData) {
      if (store.breadcrumbStore.getBreadcrumbsOptions().length > 1) {
        store.breadcrumbStore.sliceBreadcrumbOptions(0, 1)
      }
      store.breadcrumbStore.appendBreadCrumbOptions({
        label: healthData?.checkName,
        link:
          replaceUrl(
            routeConstants.AHC_DASHBOARD.replace(':id', id as string),
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?tab=1'
      })
    }
  }, [healthData, activeTab])

  return (
    <div className='flex flex-col w-full pt-[8px]'>
      <div className='relative w-full flex justify-between'>
        <div
          style={{
            width: '100%',
            display: 'flex'
          }}
          className='tab-child'
        >
          <Tabs
            disabled={loading || logLoading || filterLoading}
            activeTab={activeTab}
            tabOptionsStyle={{
              minWidth: 'fit-content',
              width: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px 12px',
              marginRight: '8px'
            }}
            onChange={(tab) => {
              setActiveTab(tab)
              setSearchParams({
                page: '1', // Use undefined to omit the 'page' key
                tab: tab ?? '1' // Keep 'tab' with default value '1' if tab is undefined or null
              })
            }}
            tabOptions={tabs}
            id='tabs-uc-summary'
          />
        </div>
        <div className='absolute w-4/6 flex right-[0px] items-center justify-end gap-[8px]'>
          {activeTab === '1' && (
            <SideChip
              id='date-range-chip'
              label='Last 7 days'
              value={convertToFormat(dateFilter, userTimeZone)}
            />
          )}

          <div className='flex items-center justify-end gap-[8px]'>
            <div className='override-pause-btn'>
              <Button
                id='pause-btn'
                type='outline'
                disabled={
                  loading ||
                  logLoading ||
                  store.userStore.getNoActiveSubscription() ||
                  filterLoading
                }
                startIcon={pause ? <Play /> : <PauseOutline />}
                size='small'
                label={pause ? 'Resume' : 'Pause'}
                onClick={() => {
                  handlePauseCheck(!pause)
                }}
              />
            </div>
            <Button
              id='share-btn'
              type='outline'
              disabled={
                loading ||
                logLoading ||
                store.userStore.getNoActiveSubscription() ||
                filterLoading
              }
              startIcon={<Share />}
              size='small'
              label='Share'
              onClick={() => {
                setSharePopupOpen(true)
              }}
            />
            {/* <Button
              id='report-incident'
              type='filled'
              label='Report an Incident'
              size='small'
              disabled={true}
            /> */}
          </div>
        </div>
      </div>
      <UCSharePopup
        open={sharePopupOpen}
        setOpen={setSharePopupOpen}
        value={
          window.location.host +
          replaceBadgeUrl(routeConstants.AHC_BADGE, healthData?.encryptedId)
        }
      />
    </div>
  )
}

export default UCOverviewLogTable
