import 'moment-timezone'

import { DDSInput, DDSTextArea, DDSTypography } from 'den-design-system'
import React, { useEffect } from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'

import { AccessRoleConstants } from '../../constants/AccessRoleConstants'
import { Button } from '../../components/New/Button/Button'
import { Close } from '@carbon/icons-react'
import DatePicker from '../../components/New/Datepicker/Datepicker'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import IncidentConstants from '../../constants/IncidentConstants'
import RegexConstants from '../../constants/RegexConstants'
import SecureComponent from '../../components/Layout/SecureComponent'
import Select from '../../components/New/Select/Select'
import Spinner from '../../components/common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import _ from 'lodash'
import { convertToUTC } from '../../utils/DateTimeUtils'
import { createIncident } from '../../api/incident/incident.service'
import dayjs from 'dayjs'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import { replaceUrl } from '../../utils/UrlUtils'
import routeConstants from '../../constants/RouteConstants'
import { useState } from 'react'
import { useStoreContext } from '../../store/StoreContext'

const ReportIncidentPage = () => {
  const [shortDescription, setShortDescription] = useState<string>('')
  const [longDescription, setLongDescription] = useState<string>('')
  const [reference, setReference] = useState<string>('')
  const [referenceLinks, setReferenceLinks] = useState<string[]>([])
  const [priorityStatus, setPriorityStatus] = useState<string>('P0')
  const [identifiedOn, setIdentifiedOn] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [isFormValid, setIsFormValid] = useState(false)
  const [addButtonDisabled, setAddButtonDisabled] = useState(false)
  const [createButtonDisabled, setCreateButtonDisabled] = useState(false)
  const { exceptionLogId } = useParams()
  const navigate = useNavigate()

  const store = useStoreContext()

  const projects = [
    {
      label: 'P0',
      value: 'P0'
    },
    {
      label: 'P1',
      value: 'P1'
    },
    {
      label: 'P2',
      value: 'P2'
    },
    {
      label: 'P3',
      value: 'P3'
    }
  ]

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.INCIDENT
    )
  }, [])

  useEffect(() => {
    if (
      priorityStatus !== '' &&
      identifiedOn &&
      shortDescription !== '' &&
      longDescription !== ''
    ) {
      setIsFormValid(true)
      setCreateButtonDisabled(false)
    } else {
      setIsFormValid(false)
      setCreateButtonDisabled(true)
    }
  }, [priorityStatus, identifiedOn, shortDescription, longDescription])

  const handleCreateAnIncident = () => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)

    const formattedDate = moment(new Date(identifiedOn)).format('l')
    const timeZone = store.userStore.getUserPreference().timeZone

    const timezoneOffset = moment.tz(timeZone).format('Z')
    const formattedDateWithTimeZone = `${formattedDate} ${timezoneOffset}`
    const createIncidentDTO = {
      priority: priorityStatus,
      identifiedOn: convertToUTC(formattedDateWithTimeZone),
      briefDescription: shortDescription,
      longDescription: longDescription,
      exceptionLogId: exceptionLogId,
      savedLinks: referenceLinks
    }
    createIncident(createIncidentDTO)
      .then((response: any) => {
        const incidentId = response.data.id

        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.INCIDENT.CREATE_INCIDENT.SUCCESS
        })

        const route = replaceUrl(
          routeConstants.MANAGE_INCIDENTS,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        ).replace(':incidentId', String(incidentId))

        const queryParams = { tab: '1' }
        navigate({
          pathname: route,
          search: `?${createSearchParams(queryParams)}`
        })
      })
      .catch((error: any) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.INCIDENT.CREATE_INCIDENT.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const validateForm = () => {
    const createIncidentDTO = {
      priority: priorityStatus,
      identifiedOn: identifiedOn,
      briefDescription: shortDescription,
      longDescription: longDescription
    }

    const emptyDTO = _.some(_.values(createIncidentDTO), _.isEmpty)
    setIsFormValid(!emptyDTO)
  }

  const handleCancel = () => {
    const route = replaceUrl(
      routeConstants.INCIDENT,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    )

    const queryParams = { page: '1', tab: '1' }
    navigate({
      pathname: route,
      search: `?${createSearchParams(queryParams)}`
    })

    setShortDescription('')
    setLongDescription('')
    setReference('')
    setReferenceLinks([])
    setPriorityStatus('P0')
    setIdentifiedOn(null)
  }

  useEffect(() => {
    store.breadcrumbStore.setMultipleBreadcrumbsOptions([
      {
        label: 'Incident',
        link:
          replaceUrl(
            routeConstants.INCIDENT,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ) + '?page=1&tab=1'
      },
      {
        label: 'Report an Incident',
        link: replaceUrl(
          routeConstants.REPORT_INCIDENT,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        )
      }
    ])
  }, [store.breadcrumbStore.getBreadcrumbsOptions()])
  useEffect(() => {
    validateForm()
  }, [
    priorityStatus,
    identifiedOn,
    shortDescription,
    longDescription,
    referenceLinks
  ])

  return (
    <>
      <DocumentHeader header={HeaderConstants.INCIDENT} />
      {store.uiStore.getGlobalLoader() ? (
        <Spinner />
      ) : (
        <div className='gap-[4px] flex flex-col justify-between h-full'>
          <div className='py-[24px]'>
            <DDSTypography.Title
              variant='semiBold'
              type='h3'
              color={pallete.colors.textDark3}
              className='gap-[32px]'
            >
              {IncidentConstants.REPORT_AN_INCIDENT}
            </DDSTypography.Title>
            <div className='pt-[32px] gap-x-[52px] flex flex-row'>
              <div className='w-[65%]'>
                <div className='flex flex-row gap-[24px] w-[100%] justify-between incident-select '>
                  <Select
                    size='large'
                    id='projects'
                    variant='top'
                    name='projects'
                    label={'Priority'}
                    options={projects}
                    value={priorityStatus}
                    defaultValue={priorityStatus}
                    searchable={false}
                    required
                    disabled={store.uiStore.getGlobalLoader()}
                    placeholder='Choose'
                    onSelect={(value) => {
                      setPriorityStatus(value)
                    }}
                  />

                  <DatePicker
                    id='report-incident-date-picker'
                    name='Identified on'
                    inputWidth={`${store.uiStore.getSidebarExpanded() ? '386px' : '500px'}`}
                    inputHeight='32px'
                    format='MM/dd/YYYY'
                    maxDate={dayjs()}
                    required
                    value={identifiedOn}
                    label='Identified on'
                    shape='roundedRectangle'
                    placeholder='Select Date'
                    onChange={(date: any) => {
                      setIdentifiedOn(date)
                    }}
                  />
                </div>
                <div className='pt-[32px]'>
                  <DDSInput
                    name='Short Description'
                    label='Short Description'
                    height='32px'
                    required
                    shape='roundedRectangle'
                    type='text'
                    maxLength={150}
                    onChange={(e) => {
                      setShortDescription(e.target.value)
                    }}
                    style={{
                      borderRadius: 4,
                      width: '100%'
                    }}
                    value={shortDescription}
                    validateInput={true}
                    variant='plain'
                  />
                </div>
                <div className='pt-[32px] override-textarea-border-radius override-text-area '>
                  <DDSTextArea
                    name='Long Description'
                    label='Long Description'
                    className='override-text-area'
                    required={true}
                    minRow={5}
                    maxLength={500}
                    style={{
                      borderRadius: '6px',
                      width: '100%'
                    }}
                    value={longDescription}
                    onChange={(e) => {
                      setLongDescription(e.target.value)
                    }}
                  />
                </div>
                <div className='!w-[100%] flex pt-[32px]  flex-row align-center gap-[24px]'>
                  <div className='!w-[100%] reference-link '>
                    <DDSInput
                      label='Reference Links'
                      id='input-incident'
                      shape='roundedRectangle'
                      variant='plain'
                      width='100%'
                      height='32px'
                      validationRegex={
                        new RegExp(RegexConstants.REFERENCE_REGEX)
                      }
                      validate={(value: string) => {
                        if (
                          RegexConstants.REFERENCE_REGEX.test(value) &&
                          referenceLinks.includes(value)
                        ) {
                          return {
                            error: true,
                            message: 'Reference link already exists'
                          }
                        } else if (value === '') {
                          return {
                            error: false,
                            message: ''
                          }
                        } else if (
                          !RegexConstants.REFERENCE_REGEX.test(value)
                        ) {
                          return {
                            error: true,
                            message: 'Please enter a valid reference link'
                          }
                        }
                        return {
                          error: false,
                          message: ''
                        }
                      }}
                      type='text'
                      onChange={(e) => {
                        setReference(e.target.value)
                        if (
                          referenceLinks.includes(e.target.value) ||
                          !RegexConstants.REFERENCE_REGEX.test(e.target.value)
                        ) {
                          setAddButtonDisabled(true)
                        } else {
                          setAddButtonDisabled(false)
                        }
                      }}
                      value={reference}
                      name='referenceLinks'
                      validateInput={true}
                      onError={(error: any) => {
                        setAddButtonDisabled(error)
                      }}
                      className='cursor-pointer'
                    />
                  </div>
                  <div className='incident-add-button my-[28px]'>
                    <Button
                      id='add-links'
                      disabled={reference.length === 0 || addButtonDisabled}
                      size='medium'
                      label='Add Link'
                      onClick={() => {
                        if (!referenceLinks.includes(reference)) {
                          referenceLinks.unshift(reference)
                          setReference('')
                          setReferenceLinks([...referenceLinks])
                        } else {
                          setAddButtonDisabled(true)
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='flex flex-col w-full gap-y-[8px]'>
                  {referenceLinks.map((item, index) => (
                    <div
                      className='flex h-[44px] items-center  justify-between p-[12px] border-[1px] rounded-md'
                      key={index}
                    >
                      <div
                        onClick={(e: any) => {
                          e.preventDefault()
                          window.open(item, '_blank', 'noopener,noreferrer')
                        }}
                      >
                        <DDSTypography.Paragraph
                          variant='medium'
                          size='para'
                          link={true}
                          href={item}
                          color={pallete.colors.textDark2}
                          className={`flex text-ellipsis truncate ${store.uiStore.getSidebarExpanded() ? 'max-w-[670px] 2xl:max-w-[950px]' : 'max-w-[790px] 2xl:max-w-[1060px]'}`}
                        >
                          {item}
                        </DDSTypography.Paragraph>
                      </div>

                      <div className='flex w-[20px]'>
                        <Close
                          size={20}
                          color={pallete.colors.textButtonText}
                          onClick={() => {
                            const updatedLinks = [
                              ...referenceLinks.slice(0, index),
                              ...referenceLinks.slice(index + 1)
                            ]

                            setReferenceLinks(updatedLinks)
                            if (updatedLinks.includes(reference)) {
                              setAddButtonDisabled(true)
                            } else {
                              setAddButtonDisabled(false)
                            }
                          }}
                          className='cursor-pointer'
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='w-[35%]'>
                <div
                  style={{
                    backgroundColor: pallete.colors.surface6,
                    padding: 24,
                    borderRadius: 10
                  }}
                >
                  <DDSTypography.Title
                    variant='bold'
                    type='h5'
                    style={{ marginBottom: 12 }}
                    color={pallete.colors.textDark4}
                  >
                    <span>{IncidentConstants.WHATS_GOING_ON}</span>
                  </DDSTypography.Title>
                  <DDSTypography.Paragraph
                    variant='medium'
                    size='para'
                    color={pallete.colors.textDark6}
                  >
                    {IncidentConstants.WHATS_GOING_ON_DESC}
                  </DDSTypography.Paragraph>
                </div>
              </div>
            </div>
          </div>
          <div className='flex items-end justify-end relative'>
            <hr className='w-full h-[1px] flex absolute top-0' />
            <div className='flex items-end justify-end px-[20px] py-[20px] gap-x-[12px]'>
              <Button
                id='cancel-incident'
                type='neutral'
                size='medium'
                label='Cancel'
                onClick={handleCancel}
              />
              <Button
                id='create-incident'
                size='medium'
                label='Create Incident'
                onClick={handleCreateAnIncident}
                disabled={createButtonDisabled}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SecureComponent(
  observer(ReportIncidentPage),
  AccessRoleConstants.MEMBER_LEVEL
)
