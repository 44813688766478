import { Download, Renew } from '@carbon/icons-react'
import React, { useEffect, useState } from 'react'
import { cloneDeep, isEmpty, isNull } from 'lodash'
import { statusToColor, toTitleCase } from '../../../utils/JobUtils'

import { Button } from '../../New/Button/Button'
import { DDSTypography } from 'den-design-system'
import Filter from '../../Filter/Filter'
import { FilterTypes } from '../../../enum/Filter.enum'
import JMIntegrate from './JMIntegrate'
import { Link } from '@mui/material'
import NoData from '../../NoData/NoData'
import NoRecord from '../../common/NoContent/NoRecord'
import NoRecordImage from '../../../assets/images/folder-not-found 1.svg'
import { ProjectType } from '../../../enum/ProjectType.enum'
import Spinner from '../../common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import Table from '../../common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import Tooltip from '../../New/Tooltip/Tooltip'
import { downLoadFileAsCsv } from '../../../utils/FileUtils'
import { downloadJobLogs } from '../../../api/jobs/Jobs.service'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { getFiltersForApi } from '../../../utils/FilterUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import { timeFormats } from '../../../enum/TIME'
import { timeZone } from '../../../utils/DateTimeUtils'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../../store/StoreContext'

interface jobLogProps {
  jobId: string
  jobLogs: any
  jobLogFilters: any
  getFiltersForLogs: () => void
  filterLoading: boolean
  findJobLogs: any
  totalLogCount: number
  currentJob: any
  isLoading: boolean
  filtersApplied: boolean
  findTheConfiguredJob: any
}
const JMLogs: React.FC<jobLogProps> = ({
  jobLogFilters,
  jobId,
  getFiltersForLogs,
  filterLoading,
  jobLogs,
  findJobLogs,
  currentJob,
  totalLogCount,
  filtersApplied,
  findTheConfiguredJob,
  isLoading
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const store = useStoreContext()
  const [selectedJob, setSelectedJob] = useState<any>(currentJob)
  const [totalCount, setTotalCount] = useState<number>(totalLogCount)
  const [downloadLogDisable, setDownloadLogDisable] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY
  const isSalesforceProject = projectType === ProjectType.SALESFORCE_ONLY

  useEffect(() => {
    findTheConfiguredJob()
  }, [])

  useEffect(() => {
    const pageFromQueryParams = searchParams.get('page')
    if (!isNull(pageFromQueryParams)) {
      setCurrentPage(Number(pageFromQueryParams))
    } else {
      const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
      setSearchParams({ ...allQueryParams, page: '1' })
      setCurrentPage(1)
    }
    findJobLogs()
  }, [searchParams])

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  useEffect(() => {
    setSelectedJob(currentJob)
    setTotalCount(totalLogCount)
  }, [currentJob, totalLogCount])

  const handleDownloadJobLogs = () => {
    setDownloadLogDisable(true)

    downloadJobLogs(
      jobId as string,
      getAllQueryParamsAsObjectFromUrl(location.search)
    )
      .then((logs) => {
        if (!selectedJob) {
          throw new Error('Selected job is not available')
        }
        const jobName = selectedJob.name
        const environmentName = store.scopeStore.getSelectedEnvironment()?.name
        const fileName = `${jobName}-${environmentName}-${timeZone(
          store.userStore.getUserPreference().timeZone,
          new Date().toString(),
          timeFormats.dateWithHoursAndMinutes
        )}.csv`
        downLoadFileAsCsv(logs, fileName)
        setDownloadLogDisable(false)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SOMETHING_WENT_WRONG
        })
        setDownloadLogDisable(false)
      })
  }

  const handleRefresh = () => {
    findJobLogs()
  }

  const columns = [
    {
      columnHeaderStyle: { width: '161px' },
      columnDataStyle: { width: '161px' },
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Start Time
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'startTime',
      render: (text: any) => (
        <div className='flex justify-start text-left'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className='whitespace-nowrap'
            color={pallete.colors.textDark4}
          >
            {text
              ? timeZone(
                  store.userStore.getUserPreference().timeZone,
                  text,
                  timeFormats.fullMonthDateYearTime
                )
              : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Start Message
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'startMessage',
      render: (text: any) => (
        <div className='flex justify-center text-center'>
          <Tooltip
            id={`start-tooltip-${text}`}
            label={text}
            className={`${
              typeof text === 'string' && text.length > 20 ? text : '!hidden'
            } max-w-[90%]`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className=' truncate max-w-[200px]'
              color={pallete.colors.textDark4}
              style={{
                display: 'inline-block',
                textAlign: 'center'
              }}
            >
              {text ? text : '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Stop Time
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'stopTime',
      render: (text: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            {text
              ? timeZone(
                  store.userStore.getUserPreference().timeZone,
                  text,
                  timeFormats.fullMonthDateYearTime
                )
              : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '200px' },
      columnDataStyle: { width: '200px' },
      heading: (
        <div className='flex justify-center text-center row items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Stop Message
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'stopMessage',
      render: (text: any) => (
        <div className='flex justify-center text-center max-w-[200px]'>
          <Tooltip
            id={`stop-tooltip-${text}`}
            label={text}
            className={`${
              typeof text === 'string' && text.length > 20 ? text : '!hidden'
            } max-w-[90%]`}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className=' truncate max-w-[200px]'
              color={pallete.colors.textDark4}
              style={{
                display: 'inline-block',
                textAlign: 'center'
              }}
            >
              {text ? text : '-'}
            </DDSTypography.Paragraph>
          </Tooltip>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center  text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Latest Client Version
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'clientVersion',
      render: (text: any) => (
        <div className='flex justify-center text-center'>
          <DDSTypography.Paragraph
            size='para'
            variant='medium'
            className=''
            color={pallete.colors.textDark4}
          >
            {text ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: {},
      columnDataStyle: {},
      heading: (
        <div className='flex row justify-center text-center items-center'>
          <DDSTypography.Paragraph
            size='caption'
            variant='semiBold'
            color={pallete.colors.textDark6}
          >
            Status
          </DDSTypography.Paragraph>
        </div>
      ),
      dataLabel: 'status',
      render: (text: any) =>
        text ? (
          <div
            className='flex justify-center rounded-[4px] text-center px-[12px] py-[4px] items-center'
            style={{ backgroundColor: statusToColor(text.toUpperCase()) }}
          >
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate'
              color={pallete.colors.surface1}
            >
              {toTitleCase(text)}
            </DDSTypography.Paragraph>
          </div>
        ) : (
          <div className='flex justify-center  text-center px-[12px] py-[4px] items-center'>
            <DDSTypography.Paragraph
              size='para'
              variant='medium'
              className='truncate'
              color={pallete.colors.textDark4}
            >
              {'-'}
            </DDSTypography.Paragraph>
          </div>
        )
    }
  ]
  return (
    <>
      {!selectedJob ||
      !selectedJob.name ||
      isLoading ||
      store.uiStore.getGlobalLoader() ? (
        <Spinner />
      ) : isEmpty(store.filterStore.getFilters()) &&
        jobLogs.length === 0 &&
        totalCount === 0 ? (
        <JMIntegrate />
      ) : (
        <div className='flex flex-col gap-[16px] pt-[16px] pb-[20px]'>
          <div className='flex flex-col gap-[8px]'>
            <div className='flex justify-start'>
              <DDSTypography.Title
                type='h4'
                variant='semiBold'
                color={pallete.colors.textDark3}
              >
                {selectedJob.name}
              </DDSTypography.Title>
            </div>

            <div className='flex w-full items-center'>
              <div className='flex justify-end w-full gap-[8px]'>
                <div className='flex justify-between items-center w-full custom-filter'>
                  <Filter
                    options={jobLogFilters}
                    onLoadOption={getFiltersForLogs}
                    dropdownLoader={filterLoading}
                  />
                </div>
                <div>
                  <Tooltip id='refresh-tooltip' label='Refresh' position='top'>
                    <Button
                      id='refresh'
                      type='neutral'
                      disabled={
                        store.uiStore.getGlobalLoader() || filterLoading
                      }
                      startIcon={
                        <Renew size={16} color={pallete.colors.neuButtonText} />
                      }
                      onClick={handleRefresh}
                      size='small'
                    />
                  </Tooltip>
                </div>
                <div>
                  <Button
                    id='jm-download-logs-btn'
                    size='small'
                    startIcon={
                      <Download color={pallete.colors.neuButtonText} />
                    }
                    label='Download'
                    type='neutral'
                    disabled={
                      downloadLogDisable ||
                      store.userStore.getNoActiveSubscription() ||
                      filterLoading
                    }
                    onClick={handleDownloadJobLogs}
                  />
                </div>
              </div>
            </div>
          </div>
          {totalCount === 0 ? (
            <NoRecord
              style={{ height: '70vh' }}
              imageSrc={NoRecordImage}
              text={
                !store.jobStore.getRecordsPresent() ? (
                  <>
                    {isFullStackProject
                      ? 'Configure Job Monitoring.'
                      : 'Start Monitoring APEX Jobs'}
                    <Link
                      href={
                        isFullStackProject
                          ? StringConstants.FULLSTACK_URL
                          : isSalesforceProject
                            ? StringConstants.SALESFORCE_URL
                            : StringConstants.MULESOFT
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ marginLeft: '4px' }}
                    >
                      Learn more
                    </Link>{' '}
                  </>
                ) : store.filterStore.getFiltersApplied() ? (
                  StringConstants.NO_RECORDS_FILTER
                ) : (
                  <>
                    Start monitoring{' '}
                    {isFullStackProject ? 'Scheduled Jobs' : 'Apex Jobs'}.
                    <Link
                      href={StringConstants.VIGIL_SDK_URL}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ marginLeft: '4px' }}
                    >
                      Learn more
                    </Link>{' '}
                  </>
                )
              }
            />
          ) : (
            <Table
              data={jobLogs}
              columns={columns}
              totalCount={totalCount}
              rowsPerPage={10}
              currentPage={currentPage}
              onPageChange={(page) => {
                setCurrentPage(page)
                const queryParams = getAllQueryParamsAsObjectFromUrl(
                  location.search
                )
                setSearchParams({ ...queryParams, page: page.toString() })
              }}
              loading={store.uiStore.getGlobalLoader()}
            />
          )}
        </div>
      )}
    </>
  )
}

export default observer(JMLogs)
