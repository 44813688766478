import { DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import {
  getAPIGroupUserFilters,
  getApiUserById
} from '../../../api/apiMonitoring/ApiMonitoring.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import APIUserGraphUsage from '../APIUserGraphUsage'
import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { Button } from '../../New/Button/Button'
import Filter from '../../Filter/Filter'
import GroupedAPILogUserTable from '../../../pages/NewVersion/APIMonitoring/GroupedAPILogUserTable'
import { Renew } from '@carbon/icons-react'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../common/DDS/Tabs/Tabs'
import Timezone from '../../common/Timezone/Timezone'
import TimezoneConstants from '../../../constants/TimezoneConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import Tooltip from '../../New/Tooltip/Tooltip'
import { isUndefined } from 'lodash'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useStoreContext } from '../../../store/StoreContext'

const GroupedUserViewDetails = () => {
  const store = useStoreContext()
  const [filterCache, setFilterCache] = useState<{ [key: string]: any[] }>({})
  const { id } = useParams()

  const [userData, setUserData] = useState<any>()
  const [filterLoading, setFilterLoading] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [options, setOptions] = useState<any>([])
  const [userLoading, setUserLoading] = useState<boolean>(true)
  const [tabFilters, setTabFilters] = useState<{ [key: string]: any }>({}) // Store filters per tab
  const [currentFilters, setCurrentFilters] = useState<any>({})
  const initialPage = searchParams.get('page') ?? '1'
  const [currentPage, setCurrentPage] = useState<number>(parseInt(initialPage))

  const navigate = useNavigate()

  const [tabIndex, setTabIndex] = React.useState<string>(
    searchParams.get('tab') ?? '1'
  )

  const organizationPreference =
    store.userStore.getOrganizationPreference()?.timeZone
  const matchedTimeZone = TimezoneConstants.find(
    (tz) => tz?.tzCode === organizationPreference
  )

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
  }, [])

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  const loadFilters = (type: string, apiCall: () => void) => {
    if (filterCache[type]) {
      setOptions(filterCache[type])
    } else {
      apiCall()
    }
  }

  const allAPIUserFilter = () => {
    setFilterLoading(true)
    getAPIGroupUserFilters({ $id: id as string })
      .then((response: any) => {
        store.apiDetailsStore.setRefresh(false)
        const filteredOptions = response.data.filter(
          (item: any) =>
            item.heading !== 'Response Status' &&
            item.heading !== 'Users' &&
            item.heading !== 'Client Version'
        )
        setOptions(filteredOptions)
        setFilterCache((prev) => ({ ...prev, url: filteredOptions }))
      })
      .catch((err) => {
        return ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
      .finally(() => {
        setFilterLoading(false)
      })
  }

  const handleFilterLoadUrl = () =>
    loadFilters('allAPIUserFilter', allAPIUserFilter)

  const viewApiUserById = async () => {
    setUserLoading(true)
    store.uiStore.setGlobalLoader(true)
    getApiUserById({ $id: id as string })
      .then((response: any) => {
        setUserData(response)
        store.apiDetailsStore.setRefresh(false)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setUserLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  useEffect(() => {
    viewApiUserById()
  }, [store.scopeStore.getScope()])

  useEffect(() => {
    if (store.apiDetailsStore.getRefresh() === true) {
      viewApiUserById()
    }
  }, [store.apiDetailsStore.getRefresh()])

  useEffect(() => {
    // Get the organization time zone or default to UTC
    const orgTimeZone =
      store.userStore.getOrganizationPreference()?.timeZone || 'UTC'

    // Get the current date and January 1 in the organization's time zone
    const nowInOrgTimeZone = moment.tz(orgTimeZone)
    const startOfYear = nowInOrgTimeZone.clone().startOf('year').toISOString()
    const endOfCurrentDay = nowInOrgTimeZone.clone().endOf('day').toISOString()

    // Parse current URL search parameters
    const searchParams = new URLSearchParams(window.location.search)
    const currentFilters: Record<string, string> = {
      'created-from': searchParams.get('created-from') || startOfYear,
      'created-to': searchParams.get('created-to') || endOfCurrentDay,
      'time-zone': searchParams.get('time-zone') || orgTimeZone
    }

    // Create filter query string
    const filterQueryString = new URLSearchParams(currentFilters).toString()

    if (!isUndefined(userData?.userName)) {
      store.breadcrumbStore.setMultipleBreadcrumbsOptions([
        {
          label: ApiGroupingStringConstants.GROUPED_API,
          link: `${replaceUrl(
            routeConstants.API_MONITORING,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          )}?page=1&contextSwitcherTab=2&${filterQueryString}`
        },
        {
          label: (
            <div
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              className='cursor-not-allowed '
            >
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                }}
                className='pointer-events-none'
              >
                {userData?.userName}
              </div>
            </div>
          ),
          link: `${replaceUrl(
            routeConstants.GROUPED_USER_DETAILS,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ).replace(':id', id as string)}?tab=1&${filterQueryString}`
        },
        {
          label: <div>{tabIndex === '1' ? 'API Logs' : 'API Overview'}</div>,
          link: `${replaceUrl(
            routeConstants.GROUPED_USER_DETAILS,
            store.scopeStore.getScope()['organization-id'] as string,
            store.scopeStore.getScope()['project-id'] as string,
            store.scopeStore.getScope()['environment-id'] as string
          ).replace(':id', id as string)}?tab=1&${filterQueryString}`
        }
      ])
    }

    return () => {
      if (!isUndefined(userData?.userName)) {
        store.breadcrumbStore.reset()
      }
    }
  }, [
    userData?.userName,
    tabIndex,
    id,
    store.breadcrumbStore,
    store.scopeStore,
    store.userStore
  ])

  const tabs = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            API Logs
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            API Logs
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <GroupedAPILogUserTable />
        </div>
      )
    },
    {
      label:
        tabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            API Overview
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            API Overview
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <APIUserGraphUsage
            UserData={userData}
            viewApiUserById={viewApiUserById}
          />
        </div>
      )
    }
  ]

  return (
    <div className='flex flex-col w-full'>
      <div className='flex justify-between items-center pt-[24px]'>
        <div>
          <DDSTooltip
            id='overview-tooltip'
            position='top'
            className={`override-tooltip-arrow ${
              typeof userData?.userName === 'string' &&
              userData?.userName.length > 30
                ? userData?.userName
                : '!hidden'
            }`}
            shape='roundedRectangle'
            label={
              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark4}
              >
                {userData?.userName}
              </DDSTypography.Paragraph>
            }
            style={{
              backgroundColor: pallete.colors.surface1,
              zIndex: 999999
            }}
          >
            <DDSTypography.Title
              type='h3'
              variant='semiBold'
              color={pallete.colors.textDark3}
              className='truncate max-w-[600px] override-tooltip-arrow'
            >
              {userData?.userName}
            </DDSTypography.Title>
          </DDSTooltip>
        </div>
        <div className='flex justify-end items-center gap-[12px]'>
          <div>
            <Timezone
              tooltipText='Time zone is based on organization settings'
              text={
                <div>
                  {matchedTimeZone?.tzCode} {matchedTimeZone?.label}
                </div>
              }
              textColor={pallete.colors.neuButtonText}
            />
          </div>
          <div>
            <Tooltip id='refresh-tooltip' label='Refresh' position='top'>
              <Button
                id='outline-button'
                type='neutral'
                onClick={() => store.apiDetailsStore.setRefresh(true)}
                startIcon={<Renew color={pallete.colors.neuButtonText} />}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className='pt-[24px] w-full justify-between relative'>
        <div>
          <Tabs
            tabOptionsStyle={{
              minWidth: 'fit-content',
              width: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px 12px',
              marginRight: '8px'
            }}
            tabOptions={tabs}
            id='tabs-grouped-api'
            onChange={(index: any) => {
              const newTabIndex = index.toString()

              if (tabIndex !== newTabIndex) {
                // Save current tab's filters
                setTabFilters((prevFilters) => ({
                  ...prevFilters,
                  [tabIndex]: currentFilters
                }))

                // Restore filters for the new tab or reset to default
                const newTabFilters = tabFilters[newTabIndex] || {}
                setCurrentFilters(newTabFilters)

                // Update state and URL
                setTabIndex(newTabIndex)
                setSearchParams({
                  ...getAllQueryParamsAsObjectFromUrl(location.search),
                  ...newTabFilters, // Apply filters for the new tab
                  tab: newTabIndex
                })
              }
            }}
            activeTab={tabIndex}
          />
          {tabIndex === '1' && (
            <div className='flex custom-filter flex-row gap-x-[8px] absolute top-[24px] right-0'>
              <Filter
                onLoadOption={handleFilterLoadUrl}
                dropdownLoader={filterLoading}
                options={options}
                APITimeFormat={true}
                isScrollable={true}
                disabled={
                  userLoading ||
                  store.uiStore.getGlobalLoader() ||
                  filterLoading
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default observer(GroupedUserViewDetails)
