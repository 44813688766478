import React, { useState } from 'react'

import CheckEmail from '../../components/common/Password/Screen/CheckEmail'
import DocumentHeader from '../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../constants/HeaderConstants'
import ResetYourPassword from '../../components/common/Password/Screen/ResetYourPassword'
import { ToastMessageConstants } from '../../constants/ToastMessageConstants'
import ToastNotification from '../../components/common/DDS/Toast/Toast'
import UnsecureComponent from '../../components/Layout/UnsecureComponent'
import { forgotPassword } from '../../api/Auth.service'

const ForgotPassword = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const handleNext = (
    email: string,
    captcha: string | null,
    captchaRef?: any
  ) => {
    setLoading(true)
    setEmail(email)

    forgotPassword({ email, captcha })
      .then(() => {
        setLoading(false)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.AUTH.FORGOT_PASSWORD.SUCCESS
        })
        if (currentStep < Steps.length) {
          setCurrentStep((currentStep) => currentStep + 1)
        }
      })
      .catch((err) => {
        setLoading(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.AUTH.FORGOT_PASSWORD.ERROR
        })
      })
  }

  const handleBack = () => {
    setCurrentStep(currentStep - 1)
  }

  const Steps = [
    {
      step: 1,
      component: (
        <>
          <ResetYourPassword
            handleNext={handleNext}
            loading={loading}
            emailBack={email}
          />
        </>
      )
    },
    {
      step: 2,
      component: <CheckEmail email={email} handleBack={handleBack} />
    }
  ]
  return (
    <div>
      <DocumentHeader header={HeaderConstants.FORGOT_PASSWORD} />

      {currentStep < Steps.length && Steps[currentStep].component}
    </div>
  )
}

export default UnsecureComponent(ForgotPassword)
