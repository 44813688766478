import { DDSTooltip, DDSTypography } from 'den-design-system'

import React from 'react'
import TimeZoneImg from '../../../assets/icons/globe.png'
import pallete from '../../../global/newPallete'

interface TimezoneProps {
  text: React.ReactNode
  textColor: string

  tooltipText: string
}

const Timezone: React.FC<TimezoneProps> = ({
  text,
  textColor,
  tooltipText
}) => {
  return (
    <DDSTooltip
      id='tooltip-timezone'
      position='top'
      shape='roundedRectangle'
      className='override-tooltip-arrow'
      style={{
        backgroundColor: pallete.colors.surface1,
        zIndex: 1000 // Adjusted for a reasonable stacking context
      }}
      label={
        <DDSTypography.Paragraph
          size='para'
          variant='regular'
          color={pallete.colors.textDark4}
        >
          {tooltipText}
        </DDSTypography.Paragraph>
      }
    >
      <div
        className='inline-flex items-center py-[6px] px-[12px] rounded-md'
        style={{ backgroundColor: pallete.colors.neuButtonHover }}
      >
        <img
          src={TimeZoneImg}
          alt='Timezone Icon'
          className='mr-[8px] w-[16px] h-[16px]' // Added dimensions for better control
        />
        <span
          style={{
            color: textColor,
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px'
          }}
        >
          {' '}
          {text}
        </span>
      </div>
    </DDSTooltip>
  )
}

export default Timezone
