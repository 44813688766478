import { DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import { Renew, Time } from '@carbon/icons-react'
import {
  fetchHeaderGroupedData,
  getAPILogFilters
} from '../../../api/apiMonitoring/ApiMonitoring.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../utils/UrlUtils'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import APIURLGraphUsage from '../../../components/APIMonitoring/APIURLGraphUsage'
import ApiGroupingStringConstants from '../../../constants/ApiGroupingStringConstants'
import { Button } from '../../../components/New/Button/Button'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import Filter from '../../../components/Filter/Filter'
import GroupedAPILogTable from './GroupedAPILogTable'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import Spinner from '../../../components/common/Spinner/Spinner'
import StatusCodeGraph from '../../../components/common/StatusCodeGraph/StatusCodeGraph'
import StringConstants from '../../../constants/StringConstants'
import Tabs from '../../../components/common/DDS/Tabs/Tabs'
import Timezone from '../../../components/common/Timezone/Timezone'
import TimezoneConstants from '../../../constants/TimezoneConstants'
import { ToastMessageConstants } from '../../../constants/ToastMessageConstants'
import ToastNotification from '../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../components/New/Tooltip/Tooltip'
import { color } from '../../../utils/ApiStatusColorCode'
import { gaPageConstants } from '../../../constants/GAConstants'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../global/newPallete'
import routeConstants from '../../../constants/RouteConstants'
import { useAnalyticsEventTracker } from '../../../utils/GAUtils'
import { useStoreContext } from '../../../store/StoreContext'

const ViewGroupedAPIDetails: React.FC = () => {
  const [headerData, setHeaderData] = useState<any>()

  const [totalCount, setTotalCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [tabFilters, setTabFilters] = useState<Record<string, any>>({})
  const [filterCache, setFilterCache] = useState<{ [key: string]: any[] }>({})
  const [searchParams, setSearchParams] = useSearchParams()
  const [triggerFilter, setTriggerFilter] = useState<boolean>(false)
  const [filterLoading, setFilterLoading] = useState<boolean>(false)
  const [options, setOptions] = useState<any>([])
  const navigate = useNavigate()
  const { id } = useParams()
  const [tabIndex, setTabIndex] = React.useState<string>(
    searchParams.get('tab') ?? '1'
  )
  const store = useStoreContext()

  const organizationPreference =
    store.userStore.getOrganizationPreference()?.timeZone
  const matchedTimeZone = TimezoneConstants.find(
    (tz) => tz?.tzCode === organizationPreference
  )

  useEffect(() => {
    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.API_MONITORING
    )
  }, [
    store.scopeStore.getEnvironmentId(),
    store.scopeStore.getSelectedProject(),
    store.scopeStore.getSelectedEnvironment()
  ])

  const loadFilters = (key: string, apiCall: () => void) => {
    if (filterCache[key]) {
      setOptions(filterCache[key])
    } else {
      apiCall()
    }
  }

  const fetchAPILogFilters = () => {
    setFilterLoading(true)
    store.filterStore.setFilterDisabled(true)
    getAPILogFilters({ $id: id as string })
      .then((response: any) => {
        const filteredOptions = response.data.filter(
          (item: any) =>
            item.heading !== 'URL Template' && item.heading !== 'HTTP Method'
        )
        setOptions(filteredOptions)
        setFilterCache((prev) => ({
          ...prev,
          fetchAPILogFilters: filteredOptions
        }))
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.API_MONITORING.API_MONITORING_FILTERS.ERROR
        })
      })
      .finally(() => {
        setFilterLoading(false)
      })
  }

  const handleFilterLoadUrl = () =>
    loadFilters('fetchAPILogFilters', fetchAPILogFilters)

  const viewHeaderGroupedApiData = async () => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    fetchHeaderGroupedData({ $id: id as string })
      .then((response: any) => {
        setHeaderData(response)
        store.apiDetailsStore.setRefresh(false)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.API_MONITORING.FETCH_DETAILS.ERROR
        })
      })
      .finally(() => {
        setLoading(false)
        store.uiStore.setGlobalLoader(false)
      })
  }

  const routeGroupedDetails = (id: number) => {
    const baseRoute = replaceUrl(
      routeConstants.VIEW_ALL_API,
      store.scopeStore.getScope()['organization-id'] as string,
      store.scopeStore.getScope()['project-id'] as string,
      store.scopeStore.getScope()['environment-id'] as string
    ).replace(':id', String(id))

    const route =
      `${baseRoute}?page=1&tab=1` +
      (tabIndex === '1' ? '&urlViewTab=1' : '&urlViewTab=2')

    navigate(route)
  }

  useEffect(() => {
    store.filterStore.setFiltersApplied(false)
  }, [store])

  useEffect(() => {
    if (store.filterStore.getFilterDisabled()) {
      store.filterStore.setFilterDisabled(false)
    }
  }, [store.filterStore.getFilterDisabled()])

  useEffect(() => {
    fetchAPILogFilters()
    viewHeaderGroupedApiData()
  }, [])

  useEffect(() => {
    if (!isUndefined(headerData?.urlTemplate)) {
      const breadcrumbOptions = [
        {
          label: ApiGroupingStringConstants.GROUPED_API,
          link:
            replaceUrl(
              routeConstants.API_MONITORING,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ) + '?tab=1'
        },
        {
          label: (
            <div
              onClick={(e) => [e.stopPropagation()]}
              className='cursor-not-allowed'
            >
              <div
                onClick={(e) => [e.stopPropagation()]}
                className='pointer-events-none'
              >
                {headerData?.urlTemplate}
              </div>
            </div>
          ),
          link:
            replaceUrl(
              routeConstants.VIEW_GROUPED_API,
              store.scopeStore.getScope()['organization-id'] as string,
              store.scopeStore.getScope()['project-id'] as string,
              store.scopeStore.getScope()['environment-id'] as string
            ).replace(':id', id as string) + '?page=1&tab=1'
        }
      ]

      // Add conditional breadcrumb for API usage tracking
      if (foundOrganization?.subscription?.trackApiUsage === true) {
        breadcrumbOptions.push({
          label: (
            <span style={{ cursor: 'pointer' }}>
              {tabIndex === '1' ? 'API Logs' : 'API Overview'}
            </span>
          ),
          link: `${routeConstants.VIEW_GROUPED_API.replace(':id', id as string)}?tab=1`
        })
      }

      store.breadcrumbStore.setMultipleBreadcrumbsOptions(breadcrumbOptions)

      return () => {
        store.breadcrumbStore.reset()
      }
    }
  }, [headerData?.urlTemplate, tabIndex, id])

  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  const tabs = [
    {
      label:
        tabIndex === '1' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            API Logs
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            API Logs
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <GroupedAPILogTable
            setTriggerFilter={setTriggerFilter}
            routeGroupedDetails={routeGroupedDetails}
            viewHeaderGroupedApiData={viewHeaderGroupedApiData}
          />
        </div>
      )
    },
    {
      label:
        tabIndex === '2' ? (
          <DDSTypography.Title
            type='h5'
            variant='semiBold'
            color={pallete.colors.primary2}
          >
            API Overview
          </DDSTypography.Title>
        ) : (
          <DDSTypography.Title
            type='h5'
            variant='medium'
            color={pallete.colors.textDark5}
          >
            API Overview
          </DDSTypography.Title>
        ),
      children: (
        <div>
          <APIURLGraphUsage
            setTriggerFilter={setTriggerFilter}
            headerData={headerData}
            setTabIndex={setTabIndex}
            viewHeaderGroupedApiData={viewHeaderGroupedApiData}
          />
        </div>
      )
    }
  ]

  return (
    <>
      <DocumentHeader header={HeaderConstants.API_MONITORING} />
      {loading || !headerData ? (
        <Spinner />
      ) : (
        <div className='h-full w-full'>
          <div className='w-full py-[24px]'>
            <div className='relative'>
              <div className='flex items-center justify-between'>
                <div>
                  <div className='flex justify-start items-start'>
                    <DDSTooltip
                      id='overview-tooltip'
                      position='top'
                      shape='roundedRectangle'
                      label={
                        <DDSTypography.Paragraph
                          size='para'
                          variant='regular'
                          color={pallete.colors.textDark4}
                        >
                          {headerData?.urlTemplate}
                        </DDSTypography.Paragraph>
                      }
                      className={`override-tooltip-arrow ${
                        typeof headerData?.urlTemplate === 'string' &&
                        headerData?.urlTemplate.length > 40
                          ? headerData.urlTemplate
                          : '!hidden'
                      }`}
                      style={{
                        backgroundColor: pallete.colors.surface1,
                        zIndex: 999999
                      }}
                    >
                      <DDSTypography.Title
                        type='h3'
                        variant='semiBold'
                        color={pallete.colors.textDark3}
                        className='truncate w-[600px]'
                      >
                        {headerData?.urlTemplate ||
                        headerData?.urlTemplate === 0
                          ? headerData.urlTemplate
                          : '-'}
                      </DDSTypography.Title>
                    </DDSTooltip>
                  </div>

                  <div className='flex justify-start gap-[4px] items-baseline override-version'>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='medium'
                      className='whitespace-nowrap'
                      color={pallete.colors.textDark6}
                    >
                      {ApiGroupingStringConstants.HTTP_METHOD}:
                    </DDSTypography.Paragraph>
                    <DDSTypography.Paragraph
                      size='para'
                      variant='semiBold'
                      className='whitespace-nowrap'
                      color={pallete.colors.textDark3}
                    >
                      {headerData?.httpMethod || headerData?.httpMethod === 0
                        ? headerData.httpMethod
                        : '-'}
                    </DDSTypography.Paragraph>
                  </div>
                </div>
                <div className='flex items-center justify-end'>
                  <div className='pr-[12px]'>
                    <Timezone
                      tooltipText='Time zone is based on organization settings'
                      text={
                        <div>
                          {matchedTimeZone?.tzCode}
                          {''}
                          {''} {matchedTimeZone?.label}
                        </div>
                      }
                      textColor={pallete.colors.neuButtonText}
                    />
                  </div>
                  <div className='mr-[8px]'>
                    <DDSTypography.Paragraph
                      size='caption'
                      variant='medium'
                      color={pallete.colors.textDark6}
                    >
                      {ApiGroupingStringConstants.STATUS_CODE_GRAPH}:
                    </DDSTypography.Paragraph>
                  </div>
                  <div className='flex flex-row gap-x-[8px] items-center'>
                    {!loading ? (
                      <StatusCodeGraph
                        tableHeight={''}
                        width='144px'
                        totalCount={totalCount}
                        data={headerData?.statusCodeGraph[headerData?.id]}
                        color={color}
                      />
                    ) : null}
                    <div className='flex justify-end'>
                      <Tooltip
                        id='refresh-tooltip'
                        label='Refresh'
                        position='top'
                      >
                        <Button
                          id='outline-button'
                          type='neutral'
                          onClick={() => store.apiDetailsStore.setRefresh(true)}
                          startIcon={
                            <Renew color={pallete.colors.neuButtonText} />
                          }
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-[24px] w-full justify-between relative'>
              {foundOrganization?.subscription?.trackApiUsage === true ? (
                <div>
                  <Tabs
                    tabOptionsStyle={{
                      minWidth: 'fit-content',
                      width: 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '8px 12px',
                      marginRight: '8px'
                    }}
                    tabOptions={tabs}
                    id='tabs-grouped-api'
                    onChange={(index: any) => {
                      const currentTab = tabIndex
                      const newTab = index.toString()

                      // Save current tab's filters
                      const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                        location.search
                      )
                      setTabFilters((prevFilters) => ({
                        ...prevFilters,
                        [currentTab]: allQueryParams // Save filters for current tab
                      }))

                      // Load new tab's filters or reset if not available
                      const newTabFilters = tabFilters[newTab] || {
                        page: '1',
                        tab: newTab
                      }
                      setSearchParams(newTabFilters)

                      setTabIndex(newTab)
                    }}
                    disabled={
                      loading ||
                      store.uiStore.getGlobalLoader() ||
                      filterLoading
                    }
                    activeTab={tabIndex}
                  />
                  {tabIndex === '1' && (
                    <div className='flex items-center custom-filter justify-end absolute right-0 top-[32px] space-x-[12px]'>
                      <Filter
                        onLoadOption={handleFilterLoadUrl}
                        dropdownLoader={filterLoading}
                        options={options}
                        APITimeFormat={true}
                        isScrollable={true}
                        disabled={
                          loading ||
                          store.uiStore.getGlobalLoader() ||
                          filterLoading
                        }
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <GroupedAPILogTable
                    routeGroupedDetails={routeGroupedDetails}
                    viewHeaderGroupedApiData={viewHeaderGroupedApiData}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default observer(ViewGroupedAPIDetails)
