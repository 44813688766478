import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '../../../New/Button/Button'
import { DDSTypography } from 'den-design-system'
import Input from '../../../New/Input/Input'
import MiscHeader from '../../MiscellaneousScreen/Page/MiscHeader/MiscHeader'
import RegexConstants from '../../../../constants/RegexConstants'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../DDS/Toast/Toast'
import { Typography } from '../../DDS/Typography/Typography'
import UnsecureComponent from '../../../Layout/UnsecureComponent'
import pallete from '../../../../global/newPallete'
import { resetPassword } from '../../../../api/Auth.service'
import routeConstants from '../../../../constants/RouteConstants'
import vigilLogo from '../../../../assets/icons/Vigil Logo Greyed out.svg'

const CreateNewPassword = () => {
  const [password, setPassword] = React.useState<string>('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [validationErrorResult, setValidationErrorResult] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const { token } = useParams()

  const validatePassword = (value: string) => {
    const isValid = RegexConstants.PASSWORD.test(value)
    setIsPasswordValid(isValid)
    return isValid
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setPassword(value)
    validatePassword(value)
    setPasswordsMatch(value === confirmPassword)
  }

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value
    setConfirmPassword(value)
    setPasswordsMatch(value === password)
  }

  const handleNext = () => {
    setLoading(true)

    setValidationErrorResult([])
    resetPassword({ password, $token: token as string })
      .then(() => {
        setLoading(false)
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.AUTH.RESET_PASSWORD.SUCCESS
        })
        navigate(routeConstants.SIGN_IN)
      })
      .catch((err) => {
        setLoading(false)
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.AUTH.RESET_PASSWORD.ERROR
        })
      })

    setLoading(false)
  }
  return (
    <div className='flex flex-col min-h-screen'>
      <MiscHeader />
      <div className='flex-grow flex flex-col items-center justify-center bg-white p-[32px]'>
        <div className='flex flex-col justify-center items-center gap-[24px] text-center'>
          <p
            style={{
              color: pallete.colors.textDark3,
              fontWeight: 700,
              fontSize: '32px',
              lineHeight: '40px'
            }}
          >
            Reset your password
          </p>
          <DDSTypography.Title
            type='h3'
            variant='medium'
            color={pallete.colors.textDark4}
          >
            Choose a password that hasn’t been used before.
            <DDSTypography.Title
              type='h3'
              variant='medium'
              color={pallete.colors.textDark4}
            >
              Must be at least 8 characters
            </DDSTypography.Title>
          </DDSTypography.Title>
          <Input
            width='40vw'
            type='password'
            placeholder='Enter new password'
            labelPosition='top'
            label='Password'
            required={true}
            validateInput={true}
            size='large'
            id='password-id'
            name='passwordInput'
            onChange={handlePasswordChange}
            validationRegex={RegexConstants.PASSWORD}
            value={password}
            validate={(value: string) => {
              if (value === '') {
                return {
                  error: true,
                  message: 'Password field cannot be empty.'
                }
              } else if (!RegexConstants.PASSWORD.test(value)) {
                return {
                  error: true,
                  message:
                    'Password must be at least 8 characters, include uppercase, lowercase, a number, and a special character.'
                }
              }
              return { error: false, message: '' }
            }}
          />
          <Input
            width='40vw'
            type='password'
            placeholder='Confirm new password'
            labelPosition='top'
            label='Confirm Password'
            required={true}
            size='large'
            id='newPassword-id'
            validateInput={true}
            value={confirmPassword}
            name='newPasswordInput'
            onChange={handleConfirmPasswordChange}
            validate={(value: string) => {
              if (value !== password) {
                return {
                  error: true,
                  message: 'Passwords do not match the new password'
                }
              }
              return { error: false, message: '' }
            }}
          />
          <Button
            label='Reset Password'
            width='40vw'
            type='filled'
            size='small'
            onClick={() => handleNext()}
            id='home-screen-button'
            disabled={!isPasswordValid || !passwordsMatch}
          />
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '32px',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      >
        <Typography.Paragraph
          size='caption'
          variant='medium'
          color='textDark10'
          style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
        >
          © 2024
          <span>
            <img src={vigilLogo} alt='Vigil Icon' />
          </span>
        </Typography.Paragraph>
      </div>
    </div>
  )
}

export default UnsecureComponent(CreateNewPassword)
