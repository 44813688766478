export enum ScopeTypes {
  Environment = 'environment',
  Project = 'project',
  Organization = 'organization'
}
export default {
  //Auth
  SIGNIN: {
    USECASE: 'SIGNIN',
    URL: '/api/v1/auth/login'
  },
  SANDBOX_LEAD_EMAIL_CAPTURE: {
    USECASE: 'SANDBOX_LEAD_EMAIL_CAPTURE',
    URL: '/api/v1/user/capture-lead-email'
  },
  FORGOT_PASSWORD: {
    USECASE: 'FORGOT_PASSWORD',
    URL: '/api/v1/auth/forgot-password'
  },
  RESET_PASSWORD: {
    USECASE: 'RESET_PASSWORD',
    URL: '/api/v1/auth/reset-password/$token'
  },
  RESEND_RESET_PASSWORD: {
    USECASE: 'RESEND_RESET_PASSWORD',
    URL: '/api/v1/auth/resend-reset-password-link'
  },
  REDIRECT_URI_SALESFORCE_SSO: {
    USECASE: 'REDIRECT_URI_SALESFORCE_SSO',
    URL: '/oauth2/callback/sso'
  },

  //User
  REGISTER_USER: {
    USECASE: 'REGISTER_USER',
    URL: '/api/v1/user'
  },
  ON_BOARD_USER_VIA_SSO: {
    USECASE: 'ON_BOARD_USER_VIA_SSO',
    URL: '/api/v1/user/salesforce-sso'
  },
  DELETE_USER: {
    USECASE: 'DELETE_USER',
    URL: '/api/v1/user'
  },
  UPDATE_USER: {
    USECASE: 'UPDATE_USER',
    URL: '/api/v1/user'
  },
  VERIFY_EMAIL: {
    USECASE: 'VERIFY_EMAIL',
    URL: '/api/v1/user/verify-email/$token'
  },
  RESEND_VERIFICATION: {
    USECASE: 'RESEND_VERIFICATION',
    URL: '/api/v1/user/verification/resend'
  },
  CHANGE_PASSWORD: {
    USECASE: 'CHANGE_PASSWORD',
    URL: '/api/v1/user/change-password'
  },
  GET_USER_DETAILS: {
    USECASE: 'GET_USER_DETAILS',
    URL: '/api/v1/user/details'
  },
  GET_USER_PREFERENCE: {
    USECASE: 'GET_USER_PREFERENCE',
    URL: '/api/v1/user/preference'
  },
  UPDATE_USER_PREFERENCE: {
    USECASE: 'UPDATE_USER_PREFERENCE',
    URL: '/api/v1/user/preference'
  },
  GET_USER_BY_EMAIL: {
    USECASE: 'GET_USER_BY_EMAIL',
    URL: '/api/v1/user/$email'
  },
  DELETE_USER_AVATAR: {
    USECASE: 'DELETE_USER_AVATAR',
    URL: '/api/v1/user/avatar'
  },

  //User Invites
  CREATE_USER_INVITE: {
    USECASE: 'CREATE_USER_INVITE',
    URL: '/api/v1/user/invite',
    SCOPE: ScopeTypes.Project
  },
  CREATE_USER_PROJECT_INVITE: {
    USECASE: 'CREATE_USER_PROJECT_INVITE',
    URL: '/api/v1/user/invite',
    SCOPE: ScopeTypes.Project
  },
  GET_USER_INVITES: {
    USECASE: 'GET_USER_INVITES',
    URL: '/api/v1/user/invite',
    SCOPE: ScopeTypes.Organization
  },
  GET_USER_INVITE: {
    USECASE: 'GET_USER_INVITE',
    URL: '/api/v1/user/invite/$id',
    SCOPE: ScopeTypes.Project
  },
  ACCEPT_USER_INVITE: {
    USECASE: 'ACCEPT_USER_INVITE',
    URL: '/api/v1/user/invite/accept/$token'
  },
  REVOKE_USER_INVITE: {
    USECASE: 'REVOKE_USER_INVITE',
    URL: '/api/v1/user/invite/revoke/$token',
    SCOPE: ScopeTypes.Project
  },

  REJECT_USER_INVITE: {
    USECASE: 'REJECT_USER_INVITE',
    URL: '/api/v1/user/invite/reject/$token'
  },
  VALIDATE_USER_INVITE: {
    USECASE: 'VALIDATE_USER_INVITE',
    URL: '/api/v1/user/invite/validate/$token'
  },
  VALIDATE_PARTNER: {
    USECASE: 'VALIDATE_PARTNER',
    URL: '/api/v1/partner/invite/validate/$token'
  },
  UPDATE_PARTNER_INVITE: {
    USECASE: 'UPDATE_PARTNER_INVITE',
    URL: '/api/v1/partner/update/$token'
  },
  RESEND_USER_INVITE: {
    USECASE: 'RESEND_USER_INVITE',
    URL: '/api/v1/user/invite/resend/$id',
    SCOPE: ScopeTypes.Project
  },
  RESEND_USER_PROJECT_INVITE: {
    USECASE: 'RESEND_USER_INVITE',
    URL: '/api/v1/user/invite/resend/$id',
    SCOPE: ScopeTypes.Project
  },
  GET_ALL_MY_USER_INVITES: {
    USECASE: 'GET_ALL_MY_USER_INVITES',
    URL: '/api/v1/user/invite/my'
  },
  GET_USER_INVITES_FILTERS: {
    USECASE: 'GET_USER_INVITES_FILTERS',
    URL: '/api/v1/user/invite/filters',
    SCOPE: ScopeTypes.Organization
  },
  CREATE_PARTNER_ORGANIZATION: {
    USECASE: 'CREATE_PARTNER_ORGANIZATION',
    URL: '/api/v1/partner/organization'
  },

  //Organization
  CREATE_ORGANIZATION: {
    USECASE: 'CREATE_ORGANIZATION',
    URL: '/api/v1/organization'
  },
  FIND_ORGANIZATION: {
    USECASE: 'FIND_ORGANIZATION',
    URL: '/api/v1/organization',
    SCOPE: ScopeTypes.Organization
  },

  SOFT_DELETE_ORGANIZATION: {
    USECASE: 'SOFT_DELETE_ORGANIZATION',
    URL: '/api/v1/organization',
    SCOPE: ScopeTypes.Organization
  },
  UPDATE_ORGANIZATION: {
    USECASE: 'UPDATE_ORGANIZATION',
    URL: '/api/v1/organization',
    SCOPE: ScopeTypes.Organization
  },
  FIND_ORGANIZATIONS: {
    USECASE: 'FIND_ORGANIZATIONS',
    URL: '/api/v1/organization/all'
  },
  RESTORE_ORGANIZATION: {
    USECASE: 'RESTORE_ORGANIZATION',
    URL: '/api/v1/organization/restore',
    SCOPE: ScopeTypes.Organization
  },
  CHECK_ORGANIZATION_NAME: {
    USECASE: 'CHECK_ORGANIZATION_NAME',
    URL: '/api/v1/organization/check-name',
    SCOPE: ScopeTypes.Organization
  },
  THINGS_TO_DO_PROGRESS: {
    USECASE: 'THINGS_TO_DO_PROGRESS',
    URL: '/api/v1/organization/project/things-to-do',
    SCOPE: ScopeTypes.Project
  },
  TRANSFER_OWNERSHIP: {
    USECASE: 'TRANSFER_OWNERSHIP',
    URL: '/api/v1/organization/transfer-owner',
    SCOPE: ScopeTypes.Organization
  },
  GET_ORGANIZATION_USAGE_REPORT: {
    USECASE: 'GET_ORGANIZATION_USAGE_REPORT',
    URL: '/api/v1/organization/usage',
    SCOPE: ScopeTypes.Organization
  },

  //Organization Member
  UPDATE_ORGANIZATION_MEMBER: {
    USECASE: 'UPDATE_ORGANIZATION_MEMBER',
    URL: '/api/v1/organization/member',
    SCOPE: ScopeTypes.Organization
  },

  FIND_ORGANIZATION_MEMBER: {
    USECASE: 'FIND_ORGANIZATION_MEMBER',
    URL: '/api/v1/organization/member',
    SCOPE: ScopeTypes.Organization
  },

  DELETE_ORGANIZATION_MEMBER: {
    USECASE: 'DELETE_ORGANIZATION_MEMBER',
    URL: '/api/v1/organization/member',
    SCOPE: ScopeTypes.Organization
  },
  FIND_ORGANIZATION_MEMBERS: {
    USECASE: 'FIND_ORGANIZATION_MEMBERS',
    URL: '/api/v1/organization/member/all',
    SCOPE: ScopeTypes.Organization
  },
  ORGANIZATION_MEMBER_LOOK_UP: {
    USECASE: 'ORGANIZATION_MEMBER_LOOK_UP',
    URL: '/api/v1/organization/member/look-up',
    SCOPE: ScopeTypes.Organization
  },
  UNASSIGNED_ORGANIZATION_MEMBER_LOOK_UP_FOR_PROJECT: {
    USECASE: 'UNASSIGNED_ORGANIZATION_MEMBER_LOOK_UP_FOR_PROJECT',
    URL: '/api/v1/organization/member/unassigned/look-up',
    SCOPE: ScopeTypes.Organization
  },

  //User Avatar
  UPLOAD_USER_AVATAR: {
    USECASE: 'UPLOAD_USER_AVATAR',
    URL: '/api/v1/user/avatar'
  },
  //Organization Avatar
  UPLOAD_ORGANIZATION_AVATAR: {
    USECASE: 'UPLOAD_ORGANIZATION_AVATAR',
    URL: '/api/v1/organization/avatar',
    SCOPE: ScopeTypes.Organization
  },
  DELETE_ORGANIZATION_AVATAR: {
    USECASE: 'DELETE_ORGANIZATION_AVATAR',
    URL: '/api/v1/organization/avatar',
    SCOPE: ScopeTypes.Organization
  },

  //Project
  CREATE_PROJECT: {
    USECASE: 'CREATE_PROJECT',
    URL: '/api/v1/organization/project',
    SCOPE: ScopeTypes.Organization
  },
  FIND_PROJECT: {
    USECASE: 'FIND_PROJECT',
    URL: '/api/v1/organization/project',
    SCOPE: ScopeTypes.Project
  },
  FIND_ALL_MEMBERS_OF_PROJECT: {
    USECASE: 'FIND_ALL_MEMBERS_OF_PROJECT',
    URL: '/api/v1/organization/project/member/all-members',
    SCOPE: ScopeTypes.Project
  },
  UPDATE_PROJECT: {
    USECASE: 'UPDATE_PROJECT',
    URL: '/api/v1/organization/project',
    SCOPE: ScopeTypes.Project
  },
  CONNECT_PROJECT_TO_SLACK: {
    USECASE: 'CONNECT_PROJECT_TO_SLACK',
    URL: '/api/v1/slack/oauth/register',
    SCOPE: ScopeTypes.Project
  },
  DISCONNECT_PROJECT_TO_SLACK: {
    USECASE: 'DISCONNECT_PROJECT_TO_SLACK',
    URL: '/api/v1/slack/oauth/disconnect',
    SCOPE: ScopeTypes.Project
  },
  DELETE_PROJECT: {
    USECASE: 'DELETE_PROJECT',
    URL: '/api/v1/organization/project',
    SCOPE: ScopeTypes.Project
  },
  FIND_PROJECTS: {
    USECASE: 'FIND_PROJECTS',
    URL: '/api/v1/organization/project/all',
    SCOPE: ScopeTypes.Organization
  },
  GET_PROJECT_AND_ENVIRONMENT: {
    USECASE: 'GET_PROJECT_AND_ENVIRONMENT',
    URL: '/api/v1/organization/project/all/environments',
    SCOPE: ScopeTypes.Organization
  },
  SOFT_DELETE_PROJECT: {
    USECASE: 'SOFT_DELETE_PROJECT',
    URL: '/api/v1/organization/project/archive',
    SCOPE: ScopeTypes.Project
  },
  RESTORE_PROJECT: {
    USECASE: 'RESTORE_PROJECT',
    URL: '/api/v1/organization/project/restore',
    SCOPE: ScopeTypes.Organization
  },
  CHECK_PROJECT_NAME_AVAILABILITY: {
    USECASE: 'CHECK_PROJECT_NAME_AVAILABILITY',
    URL: '/api/v1/organization/project/check-name',
    SCOPE: ScopeTypes.Organization
  },

  FIND_ALL_PROJECTS_AND_ENVIRONMENTS_OF_USER: {
    USECASE: 'FIND_ALL_PROJECTS_AND_ENVIRONMENTS_OF_USER',
    URL: '/api/v1/organization/project/all/environments',
    SCOPE: ScopeTypes.Organization
  },

  GET_PROJECT_FILTER: {
    USECASE: 'GET_PROJECT_FILTER',
    URL: '/api/v1/organization/project/filters',
    SCOPE: ScopeTypes.Organization
  },

  GET_ROLE_FILTER: {
    USECASE: 'GET_ROLE_FILTER',
    URL: '/api/v1/organization/member/filters',
    SCOPE: ScopeTypes.Organization
  },

  //Project Member
  FIND_PROJECT_MEMBER: {
    USECASE: 'FIND_PROJECT_MEMBER',
    URL: '/api/v1/organization/project/member',
    SCOPE: ScopeTypes.Project
  },

  DELETE_PROJECT_MEMBER: {
    USECASE: 'DELETE_PROJECT_MEMBER',
    URL: '/api/v1/organization/project/member',
    SCOPE: ScopeTypes.Project
  },

  FIND_PROJECT_MEMBERS: {
    USECASE: 'FIND_PROJECT_MEMBERS',
    URL: '/api/v1/organization/project/member/all',
    SCOPE: ScopeTypes.Project
  },

  GET_PROJECT_MEMBER_FILTERS: {
    USECASE: 'GET_PROJECT_MEMBER_FILTERS',
    URL: '/api/v1/organization/project/member/filters',
    SCOPE: ScopeTypes.Project
  },

  REMOVE_PROJECT_MEMBER: {
    USECASE: 'REMOVE_PROJECT_MEMBER',
    URL: '/api/v1/organization/project/member',
    SCOPE: ScopeTypes.Project
  },

  UPDATE_PROJECT_MEMBER: {
    USECASE: 'UPDATE_PROJECT_MEMBER',
    URL: '/api/v1/organization/project/member',
    SCOPE: ScopeTypes.Organization
  },
  ADD_PROJECT_MEMBER: {
    USECASE: 'ADD_PROJECT_MEMBER',
    URL: '/api/v1/organization/project/member',
    SCOPE: ScopeTypes.Project
  },
  LOOK_UP_PROJECT_MEMBERS: {
    USECASE: 'LOOK_UP_PROJECT_MEMBERS',
    URL: '/api/v1/organization/project/member/look-up',
    SCOPE: ScopeTypes.Project
  },

  //Environment
  CREATE_ENVIRONMENT: {
    USECASE: 'CREATE_ENVIRONMENT',
    URL: '/api/v1/organization/project/environment',
    SCOPE: ScopeTypes.Organization // project id will be sent by service function
  },

  FIND_ENVIRONMENT: {
    USECASE: 'FIND_ENVIRONMENT',
    URL: '/api/v1/organization/project/environment',
    SCOPE: ScopeTypes.Environment
  },

  DELETE_ENVIRONMENT: {
    USECASE: 'DELETE_ENVIRONMENT',
    URL: '/api/v1/organization/project/environment',
    SCOPE: ScopeTypes.Project
  },

  UPDATE_ENVIRONMENT: {
    USECASE: 'UPDATE_ENVIRONMENT',
    URL: '/api/v1/organization/project/environment',
    SCOPE: ScopeTypes.Environment
  },

  FIND_ALL_ENVIRONMENTS: {
    USECASE: 'FIND_ALL_ENVIRONMENTS',
    URL: '/api/v1/organization/project/environment/all',
    SCOPE: ScopeTypes.Project
  },

  RESTORE_ENVIRONMENT: {
    USECASE: 'RESTORE_ENVIRONMENT',
    URL: '/api/v1/organization/project/environment/archive'
  },
  SOFT_DELETE_ENVIRONMENT: {
    USECASE: 'SOFT_DELETE_ENVIRONMENT',
    URL: '/api/v1/organization/project/environment/archive'
  },

  GET_ENVIRONMENT_FILTER: {
    USECASE: 'GET_ENVIRONMENT_FILTER',
    URL: '/api/v1/organization/project/environment/filters',
    SCOPE: ScopeTypes.Environment
  },

  ENVIRONMENT_NAME_AVAILABILITY: {
    USECASE: 'ENVIRONMENT_NAME_AVAILABILITY',
    URL: '/api/v1/organization/project/environment/check-name',
    SCOPE: ScopeTypes.Project
  },

  //Jobs
  CONFIGURE_JOB: {
    USECASE: 'CONFIGURE_JOB',
    URL: '/api/v1/job',
    SCOPE: ScopeTypes.Environment
  },

  FIND_CONFIGURED_JOBS: {
    USECASE: 'FIND_CONFIGURED_JOBS',
    URL: '/api/v1/job',
    SCOPE: ScopeTypes.Environment
  },

  UPDATE_CONFIGURED_JOB: {
    USECASE: 'UPDATE_CONFIGURED_JOB',
    URL: '/api/v1/job/$jobId',
    SCOPE: ScopeTypes.Environment
  },

  FIND_CONFIGURED_JOB: {
    USECASE: 'FIND_CONFIGURED_JOB',
    URL: '/api/v1/job/$jobId',
    SCOPE: ScopeTypes.Environment
  },

  FIND_JOB_LOG: {
    USECASE: 'FIND_JOB_LOG',
    URL: '/api/v1/job/logs/$jobLogId'
  },

  GET_ALL_JOB_LOGS: {
    USECASE: 'GET_ALL_JOB_LOGS',
    URL: '/api/v1/job/$jobId/logs',
    SCOPE: ScopeTypes.Environment
  },

  ARCHIVE_JOB: {
    USECASE: 'ARCHIVE_JOB',
    URL: '/api/v1/job/archive/$jobId'
  },

  RESTORE_JOB: {
    USECASE: 'RESTORE_JOB',
    URL: '/api/v1/job/restore/$jobId'
  },

  DELETE_JOB: {
    USECASE: 'DELETE_JOB',
    URL: '/api/v1/job/$jobId',
    SCOPE: ScopeTypes.Environment
  },

  RESTORE_JOB_LOG: {
    USECASE: 'RESTORE_JOB_LOG',
    URL: '/api/v1/job/logs/restore/$jobLogId'
  },

  ARCHIVE_JOB_LOG: {
    USECASE: 'ARCHIVE_JOB_LOG',
    URL: '/api/v1/job/logs/archive/$jobLogId'
  },

  DELETE_JOB_LOG: {
    USECASE: 'DELETE_JOB_LOG',
    URL: '/api/v1/job/$jobId/logs/$jobLogId',
    SCOPE: ScopeTypes.Environment
  },

  CHECK_JOB_NAME_AVAILABILITY: {
    USECASE: 'CHECK_JOB_NAME_AVAILABILITY',
    URL: '/api/v1/job/check-name',
    SCOPE: ScopeTypes.Environment
  },

  GET_JOB_SEARCH_CONTEXTS: {
    USECASE: 'GET_JOB_SEARCH_CONTEXTS',
    URL: '/api/v1/job/search-contexts',
    SCOPE: ScopeTypes.Environment
  },

  IMPORT_JOBS_FROM_ENVIRONMENT: {
    USECASE: 'IMPORT_JOBS_FROM_ENVIRONMENT',
    URL: '/api/v1/job/copy-jobs',
    SCOPE: ScopeTypes.Environment
  },

  //Monitor Job Events
  GET_JOB_REPORTS: {
    USECASE: 'GET_JOB_REPORTS',
    URL: '/api/v1/job/monitor/report'
  },

  GET_JOB_FILTERS: {
    USECASE: 'GET_JOB_FILTERS',
    URL: '/api/v1/job/filters',
    SCOPE: ScopeTypes.Environment
  },

  GET_JOB_LOG_FILTERS: {
    USECASE: 'GET_JOB_LOG_FILTERS',
    URL: '/api/v1/job/logs/filters',
    SCOPE: ScopeTypes.Environment
  },

  DOWNLOAD_JOB_LOGS: {
    USECASE: 'DOWNLOAD_JOB_LOGS',
    URL: '/api/v1/job/$jobId/logs/download',
    SCOPE: ScopeTypes.Environment
  },

  //API Monitoring
  ADD_API_MONITORING_RECORD: {
    USECASE: 'ADD_API_MONITORING_RECORD',
    URL: '/api/v1/api-monitoring',
    SCOPE: ScopeTypes.Environment
  },

  FIND_API_MONITORING_RECORDS: {
    USECASE: 'FIND_API_MONITORING_RECORDS',
    URL: '/api/v1/api-monitoring',
    SCOPE: ScopeTypes.Environment
  },
  FIND_API_MONITORING_RECORDS_FOR_GROUP_BY_USER: {
    USECASE: 'FIND_API_MONITORING_RECORDS_FOR_GROUP_BY_USER',
    URL: '/api/v1/api-monitoring/api-user/api-group',
    SCOPE: ScopeTypes.Environment
  },
  FETCH_API_GROUPED_RECORD: {
    USECASE: 'FETCH_API_GROUPED_RECORD',
    URL: '/api/v1/api-monitoring/api-group',
    SCOPE: ScopeTypes.Environment
  },
  GET_API_USERS_BY_GROUP_URL_GRAPH: {
    USECASE: 'GET_API_USERS_BY_GROUP_URL_GRAPH',
    URL: '/api/v1/api-monitoring/api-user/by-group/$apiGroupId',
    SCOPE: ScopeTypes.Environment
  },

  GET_GRAPH_DATA_BY_API_GROUP_ID: {
    USECASE: 'GET_GRAPH_DATA_BY_API_GROUP_ID',
    URL: '/api/v1/api-monitoring/api-group/graph/$id',
    SCOPE: ScopeTypes.Environment
  },

  GET_GRAPH_DATA_BY_API_USER_ID: {
    USECASE: 'GET_GRAPH_DATA_BY_API_USER_ID',
    URL: '/api/v1/api-monitoring/api-user/graph/$id',
    SCOPE: ScopeTypes.Environment
  },
  FETCH_API_GROUPED_RECORD_WITH_ID: {
    USECASE: 'FETCH_API_GROUPED_RECORD_WITH_ID',
    URL: '/api/v1/api-monitoring/api-group/$id',
    SCOPE: ScopeTypes.Environment
  },
  FIND_API_MONITORING_RECORD: {
    USECASE: 'FIND_API_MONITORING_RECORD',
    URL: '/api/v1/api-monitoring/$id',
    SCOPE: ScopeTypes.Environment
  },
  GET_API_FILTERS: {
    USECASE: 'GET_API_FILTERS',
    URL: '/api/v1/api-monitoring/all-api/filters',
    SCOPE: ScopeTypes.Environment
  },
  GET_API_FILTER_FOR_GROUPS_BY_USER: {
    USECASE: 'GET_API_FILTER_FOR_GROUPS_BY_USER',
    URL: '/api/v1/api-monitoring/all-api/filters/$groupId/$userId',
    SCOPE: ScopeTypes.Environment
  },

  GET_API_GROUPED_USER_VIEW: {
    USECASE: 'GET_API_GROUPED_USER_VIEW',
    URL: '/api/v1/api-monitoring/api-user',
    SCOPE: ScopeTypes.Environment
  },

  GET_API_GROUPS_BY_API_USER_ID: {
    USECASE: 'GET_API_GROUPS_BY_API_USER_ID',
    URL: '/api/v1/api-monitoring/api-group/api-user/$id',
    SCOPE: ScopeTypes.Environment
  },

  GET_API_USER_BY_ID: {
    USECASE: 'GET_API_USER_BY_ID',
    URL: '/api/v1/api-monitoring/api-user/$id',
    SCOPE: ScopeTypes.Environment
  },

  GET_API_GROUPED_FILTER_BY_ID: {
    USECASE: 'GET_API_GROUPED_FILTER_BY_ID',
    URL: '/api/v1/api-monitoring/api-user/api-group',
    SCOPE: ScopeTypes.Environment
  },

  GROUP_API_FILTERS: {
    USECASE: 'GROUP_API_FILTERS',
    URL: '/api/v1/api-monitoring/group-api/filters',
    SCOPE: ScopeTypes.Environment
  },

  GROUP_API_LOG_FILTERS: {
    USECASE: 'GROUP_API_LOG_FILTERS',
    URL: '/api/v1/api-monitoring/api-group-logs/filters/$id',
    SCOPE: ScopeTypes.Environment
  },

  GROUP_API_USER_FILTERS: {
    USECASE: 'GROUP_API_USER_FILTERS',
    URL: '/api/v1/api-monitoring/api-user-logs/filters/$id',
    SCOPE: ScopeTypes.Environment
  },

  DELETE_API_MONITORING_RECORD: {
    USECASE: 'DELETE_API_MONITORING_RECORD',
    URL: '/api/v1/api-monitoring/$id',
    SCOPE: ScopeTypes.Environment
  },

  SOFT_DELETE_API_MONITORING_RECORD: {
    USECASE: 'SOFT_DELETE_API_MONITORING_RECORD',
    URL: '/api/v1/api-monitoring/soft-delete/$id'
  },

  RESTORE_API_MONITORING_RECORD: {
    USECASE: 'RESTORE_API_MONITORING_RECORD',
    URL: '/api/v1/api-monitoring/restore/$id'
  },
  DOWNLOAD_API_GROUPED_URL_VIEW: {
    USECASE: 'DOWNLOAD_API_GROUPED_URL_VIEW',
    URL: '/api/v1/api-monitoring/grouped/download',
    SCOPE: ScopeTypes.Environment
  },

  DOWNLOAD_API_GROUPED_USER_VIEW: {
    USECASE: 'DOWNLOAD_API_GROUPED_USER_VIEW',
    URL: '/api/v1/api-monitoring/api-user/download',
    SCOPE: ScopeTypes.Environment
  },
  DOWNLOAD_ALL_API_URL_VIEW: {
    USECASE: 'DOWNLOAD_ALL_API_URL_VIEW',
    URL: '/api/v1/api-monitoring/download',
    SCOPE: ScopeTypes.Environment
  },

  //App Health Check
  GET_ALL_HEALTH_CHECKS: {
    USECASE: 'GET_ALL_HEALTH_CHECKS',
    URL: '/api/v1/app-health-check',
    SCOPE: ScopeTypes.Environment
  },

  ADD_HEALTH_CHECK: {
    USECASE: 'ADD_HEALTH_CHECK',
    URL: '/api/v1/app-health-check',
    SCOPE: ScopeTypes.Environment
  },

  GET_API_HEALTH: {
    USECASE: 'GET_API_HEALTH',
    URL: '/api/v1/app-health-check/$ahcId',
    SCOPE: ScopeTypes.Environment
  },

  GET_API_HEALTH_BADGE: {
    USECASE: 'GET_API_HEALTH_BADGE',
    URL: '/api/v1/app-health-check/badge/$encryptedId'
  },

  DELETE_HEALTH_CHECK: {
    USECASE: 'DELETE_HEALTH_CHECK',
    URL: '/api/v1/app-health-check/$ahcId',
    SCOPE: ScopeTypes.Environment
  },

  UPDATE_HEALTH_CHECK: {
    USECASE: 'UPDATE_HEALTH_CHECK',
    URL: '/api/v1/app-health-check/$ahcId',
    SCOPE: ScopeTypes.Environment
  },

  ARCHIVE_HEALTH_CHECK: {
    USECASE: 'ARCHIVE_HEALTH_CHECK',
    URL: '/api/v1/app-health-check/archive/$ahcId'
  },

  RESTORE_HEALTH_CHECK: {
    USECASE: 'RESTORE_HEALTH_CHECK',
    URL: '/api/v1/app-health-check/restore/$ahcId'
  },

  GET_HEALTH_CHECK_REPORTS: {
    USECASE: 'GET_HEALTH_CHECK_REPORTS',
    URL: '/api/v1/app-health-check/report',
    SCOPE: ScopeTypes.Environment
  },
  GET_HEALTH_CHECK_REPORT_DETAILS: {
    USECASE: 'GET_HEALTH_CHECK_REPORT_DETAILS',
    URL: '/api/v1/app-health-check/report/$ahcId',
    SCOPE: ScopeTypes.Environment
  },
  GET_HEALTH_SUMMARY: {
    USECASE: 'GET_HEALTH_SUMMARY',
    URL: '/api/v1/app-health-check/summary',
    SCOPE: ScopeTypes.Environment
  },
  CHECK_HEALTH_CHECK_NAME_AVAILABILITY: {
    USECASE: 'CHECK_HEALTH_CHECK_NAME_AVAILABILITY',
    URL: '/api/v1/app-health-check/check-name',
    SCOPE: ScopeTypes.Environment
  },
  GET_HEALTH_FILTERS: {
    USECASE: 'GET_HEALTH_FILTERS',
    URL: '/api/v1/app-health-check/filters',
    SCOPE: ScopeTypes.Environment
  },
  GET_HEALTH_SEARCH_CONTEXTS: {
    USECASE: 'GET_HEALTH_SEARCH_CONTEXTS',
    URL: '/api/v1/app-health-check/search-contexts',
    SCOPE: ScopeTypes.Environment
  },

  //AHC Logs
  GET_AHC_LOG_FILTERS: {
    USECASE: 'GET_AHC_LOG_FILTERS',
    URL: '/api/v1/app-health-check/logs/filters',
    SCOPE: ScopeTypes.Environment
  },
  GET_ALL_AHC_LOGS: {
    USECASE: 'GET_ALL_AHC_LOGS',
    URL: '/api/v1/app-health-check/logs'
  },
  DELETE_ALL_AHC_LOGS: {
    USECASE: 'DELETE_ALL_AHC_LOGS',
    URL: '/api/v1/app-health-check/logs'
  },

  GET_AHC_LOG: {
    USECASE: 'GET_AHC_LOG',
    URL: '/api/v1/app-health-check/$ahcId/logs',
    SCOPE: ScopeTypes.Environment
  },

  DELETE_AHC_LOG: {
    USECASE: 'DELETE_AHC_LOG',
    URL: '/api/v1/app-health-check/logs/$id'
  },
  GET_AHC_EVENT_LOGS: {
    USECASE: 'GET_AHC_EVENT_LOGS',
    URL: '/api/v1/app-health-check/$ahcId/eventLogs',
    SCOPE: ScopeTypes.Environment
  },
  GET_AHC_LOGS_FOR_EVENT: {
    USECASE: 'GET_AHC_LOGS_FOR_EVENT',
    URL: '/api/v1/app-health-check/$ahcId/hourLogs/$eventId',
    SCOPE: ScopeTypes.Environment
  },
  GET_AHC_EVENT_LOGS_FILTERS: {
    USECASE: 'GET_AHC_EVENT_LOGS_FILTERS',
    URL: '/api/v1/app-health-check/eventLogs/filters',
    SCOPE: ScopeTypes.Environment
  },
  GET_ALL_URL_AHC_LOGS: {
    USECASE: 'GET_ALL_URL_AHC_LOGS',
    URL: '/api/v1/app-health-check/logs/url/$urlId'
  },
  DELETE_ALL_URL_AHC_LOGS: {
    USECASE: 'DELETE_ALL_URL_AHC_LOGS',
    URL: '/api/v1/app-health-check/logs/url/$urlId'
  },
  ARCHIVE_AHC_LOG: {
    USECASE: 'ARCHIVE_AHC_LOG',
    URL: '/api/v1/app-health-check/logs/archive/$ahcLogId'
  },
  RESTORE_AHC_LOG: {
    USECASE: 'RESTORE_AHC_LOG',
    URL: '/api/v1/app-health-check/logs/restore/$ahcLogId'
  },

  //Exception
  GET_ALL_EXCEPTIONS: {
    USECASE: 'GET_ALL_EXCEPTIONS',
    URL: '/api/v1/exception-management',
    SCOPE: ScopeTypes.Environment
  },
  GET_EXCEPTION_FILTERS: {
    USECASE: 'GET_EXCEPTION_FILTERS',
    URL: '/api/v1/exception-management/filters',
    SCOPE: ScopeTypes.Environment
  },
  GET_GROUPED_EXCEPTIONS_FILTERS: {
    USECASE: 'GET_GROUPED_EXCEPTIONS_FILTERS',
    URL: '/api/v1/exception-management/grouped-filters',
    SCOPE: ScopeTypes.Environment
  },
  GET_AN_EXCEPTION: {
    USECASE: 'GET_AN_EXCEPTION',
    URL: '/api/v1/exception-management/$exceptionLogId',
    SCOPE: ScopeTypes.Environment
  },
  GET_AN_EXCEPTION_BY_TOKEN: {
    USECASE: 'GET_AN_EXCEPTION_BY_TOKEN',
    URL: '/api/v1/exception-management/share/$token'
  },
  UPDATE_AN_EXCEPTION: {
    USECASE: 'UPDATE_AN_EXCEPTION',
    URL: '/api/v1/exception-management/$exceptionLogId',
    SCOPE: ScopeTypes.Environment
  },
  UPDATE_EXCEPTION_GROUP: {
    USECASE: 'UPDATE_EXCEPTION_GROUP',
    URL: '/api/v1/exception-management/exceptionGroup/groups',
    SCOPE: ScopeTypes.Environment
  },
  GET_ALL_ACTIVITIES: {
    USECASE: 'GET_ALL_ACTIVITIES',
    URL: '/api/v1/exception-management/$exceptionLogId/activity/all',
    SCOPE: ScopeTypes.Environment
  },
  ADD_A_COMMENT: {
    USECASE: 'ADD_A_COMMENT',
    URL: '/api/v1/exception-management/$exceptionLogId/activity/comment',
    SCOPE: ScopeTypes.Environment
  },
  UPDATE_A_COMMENT: {
    USECASE: 'UPDATE_A_COMMENT',
    URL: '/api/v1/exception-management/$exceptionLogId/activity/comment/$exceptionCommentId',
    SCOPE: ScopeTypes.Environment
  },
  DELETE_A_COMMENT: {
    USECASE: 'DELETE_A_COMMENT',
    URL: '/api/v1/exception-management/$exceptionLogId/activity/comment/$exceptionCommentId',
    SCOPE: ScopeTypes.Environment
  },
  DELETE_AN_EXCEPTION: {
    USECASE: 'UPDATE_AN_EXCEPTION',
    URL: '/api/v1/exception-management/$exceptionLogId',
    SCOPE: ScopeTypes.Environment
  },
  VIEW_ALL_GROUPED_EXCEPTIONS: {
    USECASE: 'VIEW_ALL_GROUPED_EXCEPTIONS',
    URL: '/api/v1/exception-management/exceptionGroup/groups',
    SCOPE: ScopeTypes.Environment
  },
  VIEW_SINGLE_GROUPED_EXCEPTION: {
    USECASE: 'VIEW_SINGLE_GROUPED_EXCEPTION',
    URL: '/api/v1/exception-management/exceptionGroup/$exceptionGroupId',
    SCOPE: ScopeTypes.Environment
  },
  DOWNLOAD_GROUPED_EXCEPTIONS: {
    USECASE: 'DOWNLOAD_GROUPED_EXCEPTIONS',
    URL: '/api/v1/exception-management/grouped/download',
    SCOPE: ScopeTypes.Environment
  },
  DOWNLOAD_ALL_EXCEPTIONS: {
    USECASE: 'DOWNLOAD_ALL_EXCEPTIONS',
    URL: '/api/v1/exception-management/download',
    SCOPE: ScopeTypes.Environment
  },
  GET_EXCEPTION_SEARCH_CONTEXTS: {
    USECASE: 'GET_EXCEPTION_SEARCH_CONTEXTS',
    URL: '/api/v1/exception-management/search-contexts',
    SCOPE: ScopeTypes.Environment
  },

  GET_STATISTICS_OF_EXCEPTION_GROUP: {
    USECASE: 'GET_STATISTICS_OF_EXCEPTION_GROUP',
    URL: '/api/v1/exception-management/statistics',
    SCOPE: ScopeTypes.Environment
  },

  GET_STATISTICS_OF_EXCEPTION_GROUP_BY_TOKEN: {
    USECASE: 'GET_STATISTICS_OF_EXCEPTION_GROUP_BY_TOKEN',
    URL: '/api/v1/exception-management/statistics/share/$token'
  },

  GET_STATISTICS_OF_EXCEPTION_GROUP_BY_GROUP_ID: {
    USECASE: 'GET_STATISTICS_OF_EXCEPTION_GROUP_BY_GROUP_ID',
    URL: '/api/v1/exception-management/statistics/$exceptionGroupId',
    SCOPE: ScopeTypes.Environment
  },
  //Exception Escalations
  GET_ALL_EXCEPTION_ESCALATION_SETS: {
    USECASE: 'GET_ALL_EXCEPTION_ESCALATION_SETS',
    URL: '/api/v1/exception-management/escalation-sets',
    SCOPE: ScopeTypes.Environment
  },
  GET_AN_EXCEPTION_ESCALATION_SET: {
    USECASE: 'GET_AN_EXCEPTION_ESCALATION_SET',
    URL: '/api/v1/exception-management/escalation-sets/$escalationSetId',
    SCOPE: ScopeTypes.Environment
  },
  UPDATE_AN_EXCEPTION_ESCALATION_SET: {
    USECASE: 'UPDATE_AN_EXCEPTION_ESCALATION_SET',
    URL: '/api/v1/exception-management/escalation-sets/$escalationSetId',
    SCOPE: ScopeTypes.Environment
  },
  DELETE_AN_EXCEPTION_ESCALATION_SET: {
    USECASE: 'DELETE_AN_EXCEPTION_ESCALATION_SET',
    URL: '/api/v1/exception-management/escalation-sets/$escalationSetId',
    SCOPE: ScopeTypes.Environment
  },
  CREATE_EXCEPTION_ESCALATION_SET: {
    USECASE: 'CREATE_EXCEPTION_ESCALATION_SET',
    URL: '/api/v1/exception-management/escalation-sets',
    SCOPE: ScopeTypes.Environment
  },
  CREATE_AN_EXCEPTION_ESCALATION_RULE: {
    USECASE: 'CREATE_AN_EXCEPTION_ESCALATION_RULE',
    URL: '/api/v1/exception-management/escalation-rules/$escalationSetId',
    SCOPE: ScopeTypes.Environment
  },
  UPDATE_AN_EXCEPTION_ESCALATION_RULE: {
    USECASE: 'UPDATE_AN_EXCEPTION_ESCALATION_RULE',
    URL: '/api/v1/exception-management/escalation-rules/$escalationRuleId',
    SCOPE: ScopeTypes.Environment
  },
  DELETE_MANY_EXCEPTION_ESCALATION_RULES: {
    USECASE: 'DELETE_MANY_EXCEPTION_ESCALATION_RULES',
    URL: '/api/v1/exception-management/escalation-rules',
    SCOPE: ScopeTypes.Environment
  },

  //Notifications
  GET_ALL_NOTIFICATIONS: {
    USECASE: 'GET_ALL_NOTIFICATIONS',
    URL: '/api/v1/notification'
  },
  GET_NOTIFICATION_FILTERS: {
    USECASE: 'GET_NOTIFICATION_FILTERS',
    URL: '/api/v1/notification/filters'
  },
  MARK_NOTIFICATION_AS_READ: {
    USECASE: 'MARK_NOTIFICATION_AS_READ',
    URL: '/api/v1/notification/read/$id'
  },
  MARK_ALL_NOTIFICATIONS_AS_READ: {
    USECASE: 'MARK_ALL_NOTIFICATIONS_AS_READ',
    URL: '/api/v1/notification/read/all'
  },
  //Subscription
  SEND_EMAIL_TO_SUPPORT: {
    USECASE: 'SEND_EMAIL_TO_SUPPORT',
    URL: '/api/v1/subscription/email',
    SCOPE: ScopeTypes.Organization
  },

  //Exception Overview
  GET_EXCEPTION_OVERVIEW: {
    USECASE: 'GET_EXCEPTION_OVERVIEW',
    URL: '/api/v1/exception-management/overview',
    SCOPE: ScopeTypes.Environment
  },

  //Job Overview
  GET_JOB_OVERVIEW: {
    USECASE: 'GET_JOB_OVERVIEW',
    URL: '/api/v1/job/overview',
    SCOPE: ScopeTypes.Environment
  },

  //API Overview
  GET_API_OVERVIEW: {
    USECASE: 'GET_API_OVERVIEW',
    URL: '/api/v1/api-monitoring/overview',
    SCOPE: ScopeTypes.Environment
  },

  //AHC Overview
  GET_AHC_OVERVIEW: {
    USECASE: 'GET_AHC_OVERVIEW',
    URL: '/api/v1/app-health-check/overview',
    SCOPE: ScopeTypes.Environment
  },

  //AHC Summary
  GET_PROJECT_SUMMARY: {
    USECASE: 'GET_PROJECT_SUMMARY',
    URL: '/api/v1/organization/project/summary',
    SCOPE: ScopeTypes.Project
  },

  // Incident
  CREATE_AN_INCIDENT: {
    USECASE: 'CREATE_AN_INCIDENT',
    URL: '/api/v1/incident-management/incident',
    SCOPE: ScopeTypes.Environment
  },
  GET_INCIDENT_FILTERS: {
    USECASE: 'GET_INCIDENT_FILTERS',
    URL: '/api/v1/incident-management/filters',
    SCOPE: ScopeTypes.Environment
  },
  FIND_AN_INCIDENT: {
    USECASE: 'FIND_AN_INCIDENT',
    URL: '/api/v1/incident-management/incident/$incidentId',
    SCOPE: ScopeTypes.Environment
  },
  FIND_AN_INCIDENT_BY_TOKEN: {
    USECASE: 'FIND_AN_INCIDENT_BY_TOKEN',
    URL: '/api/v1/incident-management/incident/share/$token'
  },
  FIND_ALL_INCIDENTS: {
    USECASE: 'FIND_ALL_INCIDENTS',
    URL: '/api/v1/incident-management/incident',
    SCOPE: ScopeTypes.Environment
  },
  UPDATE_AN_INCIDENT: {
    USECASE: 'UPDATE_AN_INCIDENT',
    URL: '/api/v1/incident-management/incident/$incidentId',
    SCOPE: ScopeTypes.Environment
  },
  DELETE_AN_INCIDENT: {
    USECASE: 'DELETE_AN_INCIDENT',
    URL: '/api/v1/incident-management/incident/$incidentId',
    SCOPE: ScopeTypes.Environment
  },
  ADD_COMMENT_TO_INCIDENT: {
    USECASE: 'ADD_COMMENT_TO_INCIDENT',
    URL: '/api/v1/incident-management/incident/$incidentId/comment',
    SCOPE: ScopeTypes.Environment
  },
  GET_ALL_COMMENTS_OF_INCIDENT: {
    USECASE: 'GET_ALL_COMMENTS_OF_INCIDENT',
    URL: '/api/v1/incident-management/incident/$incidentId/activity',
    SCOPE: ScopeTypes.Environment
  },
  GET_ALL_COMMENTS_OF_INCIDENT_WITHOUT_AUTHORIZATION: {
    USECASE: 'GET_ALL_COMMENTS_OF_INCIDENT_WITHOUT_AUTHORIZATION',
    URL: '/api/v1/exception-management/$exceptionLogId/incident/share/$incidentId/comment'
  },
  UPDATE_COMMENT_OF_INCIDENT: {
    USECASE: 'UPDATE_COMMENT_OF_INCIDENT',
    URL: '/api/v1/incident-management/incident/$incidentId/activity/$activityId',
    SCOPE: ScopeTypes.Environment
  },
  DELETE_COMMENT_OF_INCIDENT: {
    USECASE: 'DELETE_COMMENT_OF_INCIDENT',
    URL: '/api/v1/incident-management/incident/$incidentId/activity/$activityId',
    SCOPE: ScopeTypes.Environment
  },
  GET_EXCEPTIONS_OF_AN_EXCEPTION_GROUP: {
    USECASE: 'GET_EXCEPTIONS_OF_AN_EXCEPTION_GROUP',
    URL: '/api/v1/exception-management/exceptionLogs/$exceptionGroupId',
    SCOPE: ScopeTypes.Environment
  },
  UPDATE_SELECTED_EXCEPTIONS: {
    USECASE: 'UPDATE_SELECTED_EXCEPTIONS',
    URL: '/api/v1/exception-management/selectedExceptions',
    SCOPE: ScopeTypes.Environment
  },
  DELETE_SELECTED_EXCEPTIONS: {
    USECASE: 'DELETE_SELECTED_EXCEPTIONS',
    URL: '/api/v1/exception-management/selectedExceptions',
    SCOPE: ScopeTypes.Environment
  },
  //SF Audit
  REDIRECT_URI_SALESFORCE_CONNECT: {
    USECASE: 'REDIRECT_URI_SALESFORCE_CONNECT',
    URL: '/oauth2/callback/connect'
  },

  GET_SALESFORCE_AUDIT_DATA: {
    USECASE: 'GET_SALESFORCE_AUDIT_DATA',
    URL: '/api/v1/salesforce-audit/data',
    SCOPE: ScopeTypes.Environment
  },

  CONNECT_TO_SALESFORCE: {
    USECASE: 'CONNECT_TO_SALESFORCE',
    URL: '/api/v1/salesforce-audit',
    SCOPE: ScopeTypes.Environment
  },

  CHECK_INSTANCE_AVAILABILITY: {
    USECASE: 'CHECK_INSTANCE_AVAILABILITY',
    URL: '/api/v1/salesforce-audit/check-instance',
    SCOPE: ScopeTypes.Environment
  }
} as {
  [key: string]: {
    USECASE: string
    URL: string
    SCOPE?: ScopeTypes
  }
}
