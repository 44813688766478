import { DDSTooltip, DDSTypography } from 'den-design-system'

import { ChevronRight } from '@carbon/icons-react'
import React from 'react'
import Tooltip from '../New/Tooltip/Tooltip'
import pallete from '../../global/newPallete'

interface UCCardData {
  id: string
  heading: string
  content: string | React.ReactNode
  onClick?: any
  summary?: boolean
  disableChevron?: boolean
  selectedCardId?: any
  selected?: boolean
}

const UCCard: React.FC<UCCardData> = ({
  id,
  heading,
  content,
  onClick,
  summary,
  disableChevron,
  selectedCardId,
  selected
}) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className={`relative flex rounded-md border bg-white px-[20px] gap-[8px] py-[16px] ${
        onClick ? 'cursor-pointer' : 'cursor-default'
      }`}
      style={{
        borderColor:
          selectedCardId === id
            ? pallete.colors.primary2
            : pallete.colors.stroke2
      }}
    >
      <div className='flex flex-col gap-[8px]'>
        <div className='flex gap-[4px] items-center'>
          <div>
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark5}
            >
              {heading}
            </DDSTypography.Paragraph>
          </div>

          {summary && !disableChevron && (
            <div className='flex flex-col '>
              <ChevronRight
                className='cursor-pointer'
                size={16}
                color={pallete.colors.primary2}
              />
            </div>
          )}
        </div>

        <div>
          <Tooltip
            id={`${content}-${id}`}
            label={content}
            position='top'
            className={`${
              typeof content === 'string' && content.length > 10
                ? ''
                : '!hidden'
            }`}
          >
            <div onClick={onClick} className={`${onClick && 'cursor-pointer'}`}>
              <DDSTypography.Title
                type='h5'
                variant='semiBold'
                className='truncate max-w-[230px]'
                color={pallete.colors.textDark3}
              >
                {content}
              </DDSTypography.Title>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default UCCard
