import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  deleteOrganizationAvatar,
  findOrganization,
  updateOrganization,
  uploadOrganizationAvatar
} from '../../../../../api/organization/Organization.service'

import Alert from '../../../../../components/common/Alert/Alert'
import { Button } from '../../../../../components/New/Button/Button'
import { DDSTypography } from 'den-design-system'
import { FileUploader } from 'react-drag-drop-files'
import Input from '../../../../../components/New/Input/Input'
import Joi from 'joi'
import { OrganizationTypeDisplayNames } from '../../../../../enum/OrganizationType.enum'
import { OrganizationUserRoles } from '../../../../../enum/OrganizationUserRoles.enum'
import RegexConstants from '../../../../../constants/RegexConstants'
import Select from '../../../../../components/New/Select/Select'
import SettingConstants from '../../../../../constants/SettingConstants'
import Spinner from '../../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../../constants/StringConstants'
import TimezoneConstants from '../../../../../constants/TimeZoneConstantsV2'
import { ToastMessageConstants } from '../../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../../components/common/DDS/Toast/Toast'
import TransferOwnerShip from '../../AccountSetting/Components/TransferOwnerShip/TransferOwnerShip'
import UrlConstants from '../../../../../constants/UrlConstants'
import ValidationConstants from '../../../../../constants/ValidationConstants'
import { WarningAlt } from '@carbon/icons-react'
import { checkNameAvailability } from '../../../../../api/common/Common.service'
import { isUndefined } from 'lodash'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../../global/newPallete'
import { replaceUrl } from '../../../../../utils/UrlUtils'
import routeConstants from '../../../../../constants/RouteConstants'
import { updateUserPreference } from '../../../../../api/user/User.service'
import { useStoreContext } from '../../../../../store/StoreContext'

const GeneralOrganizationSetting: React.FC<{
  data: any
}> = ({ data }) => {
  const [organizationName, setOrganizationName] = useState<string>('')
  const fileTypes = ['JPG', 'PNG', 'JPEG']
  const [selectedTimeZone, setSelectedTimeZone] = useState<string | undefined>()
  const [imageError, setImageError] = useState(false)
  const [organizationType, setOrganizationType] = useState<string>('')
  const store = useStoreContext()
  const [deleteAvatarLoader, setDeleteAvatarLoader] = useState<boolean>(false)
  const [avatar, setAvatar] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [uploadAvatarLoader, setUploadAvatarLoader] = useState<boolean>(false)
  const [organizationErrorText, setOrganizationErrorText] = useState<string>('')
  const [isInvalidOrganizationName, setIsInvalidOrganizationName] =
    useState(false)
  const referenceForNameInput = useRef<any>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const DEFAULT_TIMEZONE = 'America/Chicago'

  const [isUpdated, setIsUpdated] = useState(false)
  const isDisabled = isUpdated

  useEffect(() => {
    setOrganizationName(data.name)
    setOrganizationType(data.type)
    setAvatar(data.avatar)
    setLoading(false)
    store.uiStore.setGlobalLoader(false)
    setIsInvalidOrganizationName(false)
    setOrganizationErrorText('')
  }, [data, store.uiStore])

  useEffect(() => {
    store.breadcrumbStore.setMultipleBreadcrumbsOptions([
      {
        label: (
          <div
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            className='cursor-not-allowed '
          >
            <div
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              className='pointer-events-none'
            >
              Manage Organization
            </div>
          </div>
        ),
        link: `${replaceUrl(
          routeConstants.MANAGE_ORGANIZATION,
          store.scopeStore.getScope()['organization-id'] as string
        )}?tab=1`
      },
      {
        label: 'General',
        link: `${replaceUrl(
          routeConstants.MANAGE_ORGANIZATION,
          store.scopeStore.getScope()['organization-id'] as string
        )}?tab=1`
      }
    ])
  }, [store.breadcrumbStore, store.scopeStore])

  const nameSchema = Joi.object({
    organizationName: Joi.string()
      .pattern(RegexConstants.NAME_REGEX)
      .required()
      .messages({
        'string.pattern.base': ValidationConstants.GENERIC.INVALID_NAME_REGEX
      })
  })

  const updateOrganizationName = () => {
    if (loading || store.uiStore.getGlobalLoader()) return

    setLoading(true)
    store.uiStore.setGlobalLoader(true)

    const organization = store.userStore
      .getOrganizations()
      .find((org) => org.id === store.scopeStore.getOrganizationId())

    if (organization?.name === organizationName) {
      setOrganizationErrorText('')
      setIsInvalidOrganizationName(false)
      setLoading(false)
      store.uiStore.setGlobalLoader(false)
      return
    }

    const validationResult = nameSchema.validate(
      { organizationName: organizationName.trim() },
      { abortEarly: false }
    )

    if (isUndefined(validationResult.error)) {
      setOrganizationErrorText('')
      referenceForNameInput.current?.startLoading()

      checkNameAvailability(
        UrlConstants.CHECK_ORGANIZATION_NAME.USECASE,
        organizationName.trim(),
        { type: organizationType }
      )
        .then(() => {
          updateOrganization({ name: organizationName })
            .then((response: any) => {
              setOrganizationName(response.data.name)
              const updatedOrganizations = store.userStore
                .getOrganizations()
                .map((org: any) =>
                  org.id === response.data.id
                    ? { ...org, name: response.data.name }
                    : org
                )
              store.userStore.setOrganizations(updatedOrganizations)
              store.userStore.setSelectedOrganization({
                ...store.userStore.getSelectedOrganization(),
                name: response.data.name
              })
              ToastNotification({
                type: 'success',
                message:
                  ToastMessageConstants.SETTINGS.UPDATE_ORGANIZATION.SUCCESS
              })
            })
            .catch(() => {
              ToastNotification({
                type: 'error',
                message:
                  ToastMessageConstants.SETTINGS.UPDATE_ORGANIZATION.ERROR
              })
              setOrganizationName(
                store.userStore.getSelectedOrganization().name
              )
            })
            .finally(() => {
              setLoading(false)
              store.uiStore.setGlobalLoader(false)
              referenceForNameInput.current?.stopLoading()
            })
        })
        .catch((error) => {
          referenceForNameInput.current?.stopLoading()
          setLoading(false)
          store.uiStore.setGlobalLoader(false)
          if (error === StringConstants.NAME_ALREADY_TAKEN) {
            setIsInvalidOrganizationName(true)
            setOrganizationErrorText(
              ValidationConstants.ORGANIZATION.ORGANIZATION_NAME_ERROR
            )
          } else {
            ToastNotification({
              type: 'error',
              message: ToastMessageConstants.SOMETHING_WENT_WRONG
            })
          }
        })
    } else {
      setOrganizationErrorText(
        validationResult.error.details[0].type === 'string.pattern.base'
          ? ValidationConstants.ORGANIZATION.ORGANIZATION_NAME_INVALID
          : ValidationConstants.ORGANIZATION.ORGANIZATION_NAME_EMPTY
      )
      setLoading(false)
      store.uiStore.setGlobalLoader(false)
    }
  }

  const handleSubmit = (file: File) => {
    if (
      store.userStore.getUserRole() !== OrganizationUserRoles.OWNER ||
      loading ||
      store.uiStore.getGlobalLoader()
    )
      return

    const formData = new FormData()
    formData.append('file', file)
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    setUploadAvatarLoader(true)

    uploadOrganizationAvatar(formData)
      .then((orgData: any) => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.SETTINGS.UPLOAD_AVATAR.SUCCESS
        })
        store.userStore.setSelectedOrganization({
          ...store.userStore.getSelectedOrganization(),
          avatar: orgData.data.avatar
        })
        store.userStore.setAvatarById(orgData.data.id, orgData.data.avatar)
        setAvatar(orgData.data.avatar)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: err
        })
        setAvatar(avatar)
      })
      .finally(() => {
        setUploadAvatarLoader(false)
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const disableRemoveButton = store.userStore
    .getSelectedOrganization()
    .avatar.includes('/common')

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      handleSubmit(file)
    }
  }

  const handleDeleteAvatar = () => {
    if (
      store.userStore.getUserRole() !== OrganizationUserRoles.OWNER ||
      loading ||
      store.uiStore.getGlobalLoader()
    )
      return

    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    setDeleteAvatarLoader(true)

    const organizationId = store.userStore.getSelectedOrganization().id
    deleteOrganizationAvatar(organizationId)
      .then((orgData: any) => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.SETTINGS.UPLOAD_USER_AVATAR.SUCCESS
        })
        store.userStore.setSelectedOrganization(orgData.data)
        store.userStore.setAvatarById(orgData.data.id, orgData.data.avatar)
        setAvatar(null)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: err
        })
      })
      .finally(() => {
        setDeleteAvatarLoader(false)
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })
  }

  const TimezoneDropdownOptions = TimezoneConstants.map((timezoneConstant) => ({
    label:
      timezoneConstant.name.replaceAll('_', ' ') +
      timezoneConstant.label.substring(0, 11),
    value: timezoneConstant.name
  }))

  const fetchOrganizationPreference = () => {
    setLoading(true)
    findOrganization()
      .then((organizationData: any) => {
        const storedTimeZone =
          organizationData?.data?.timeZone || DEFAULT_TIMEZONE
        store.userStore.setOrganizationPreference({ timeZone: storedTimeZone })
        setSelectedTimeZone(storedTimeZone) // Set fetched timezone or default
        setIsUpdated(storedTimeZone !== DEFAULT_TIMEZONE) // Mark as updated only if it's a non-default value
      })
      .catch((error) => {
        ToastNotification({
          type: 'error',
          message: error || 'Unable to fetch organization data'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleChange = (timeZoneSelection: string | undefined) => {
    const selectedOption = TimezoneDropdownOptions.find(
      (item) => item.value === timeZoneSelection
    )

    if (selectedOption) {
      setSelectedTimeZone(selectedOption.value) // Update the selected timezone in state
      setLoading(true)
      store.uiStore.setGlobalLoader(true)

      updateOrganization({
        timeZone: selectedOption.value
      })
        .then((organizationData: any) => {
          const updatedTimeZone =
            organizationData?.data?.timeZone || DEFAULT_TIMEZONE
          store.userStore.setOrganizationPreference({
            timeZone: updatedTimeZone
          })
          setSelectedTimeZone(updatedTimeZone)
          setIsUpdated(updatedTimeZone !== DEFAULT_TIMEZONE) // Disable only if updated to a non-default value
          ToastNotification({
            type: 'success',
            message: ToastMessageConstants.PREFERENCE.UPDATE_PREFERENCE.SUCCESS
          })
        })
        .catch((err) => {
          // Handle error and revert to the previously saved timezone
          const currentPreference =
            store.userStore.getOrganizationPreference()?.timeZone ||
            DEFAULT_TIMEZONE
          setSelectedTimeZone(currentPreference)
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.PREFERENCE.UPDATE_PREFERENCE.ERROR
          })
          setIsUpdated(currentPreference !== DEFAULT_TIMEZONE) // Ensure accurate state
        })
        .finally(() => {
          setLoading(false)
          store.uiStore.setGlobalLoader(false)
        })
    }
  }

  useEffect(() => {
    const storedTimeZone =
      store.userStore.getOrganizationPreference()?.timeZone || DEFAULT_TIMEZONE
    setSelectedTimeZone(storedTimeZone)
    setIsUpdated(storedTimeZone !== DEFAULT_TIMEZONE) // Set initial state
  }, [store.userStore.getOrganizationPreference()])

  useEffect(() => {
    fetchOrganizationPreference()
  }, [])

  const filterOption = (input: any, option: any) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  return (
    <>
      {loading || store.uiStore.getGlobalLoader() ? (
        <Spinner />
      ) : (
        <div className='w-full h-full py-[24px]'>
          <div className='flex flex-col gap-[24px]'>
            <DDSTypography.Title
              type='h3'
              variant='semiBold'
              color={pallete.colors.textDark3}
            >
              {SettingConstants.GENERAL}
            </DDSTypography.Title>

            <div className='flex items-start self-stretch gap-[32px]'>
              <div className='w-1/2'>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                  className='pb-[4px] relative'
                >
                  {SettingConstants.ORGANIZATION_NAME}
                  <span
                    className='absolute top-0  ml-[2px]'
                    style={{ color: pallete.colors.warningRed2 }}
                  >
                    *
                  </span>
                </DDSTypography.Paragraph>
                <div className='flex flex-col relative'>
                  <Input
                    width='100%'
                    onChange={(e) => {
                      setOrganizationName(e.target.value)
                    }}
                    value={organizationName}
                    maxLength={30}
                    onBlur={updateOrganizationName}
                    disabled={
                      store.userStore.getNoActiveSubscription() ||
                      loading ||
                      store.userStore.getUserRole() !==
                        OrganizationUserRoles.OWNER
                    }
                    labelPosition='top'
                    label={
                      <DDSTypography.Paragraph
                        size='para'
                        variant='regular'
                        color={pallete.colors.textDark6}
                      >
                        {
                          SettingConstants.CUSTOMIZE_EACH_PROJECT_WITH_A_UNIQUE_NAME
                        }
                      </DDSTypography.Paragraph>
                    }
                    validateInput={true}
                    validate={(value: string) => {
                      if (value.trim() === '') {
                        return {
                          error: true,
                          message: 'Organization name cannot be empty'
                        }
                      } else if (!RegexConstants.NAME_VALIDATION.test(value)) {
                        return {
                          error: true,
                          message: 'Please enter a valid organization name'
                        }
                      }
                      return {
                        error: false,
                        message: ''
                      }
                    }}
                    id='username-input'
                    name='usernameInput'
                  />
                  {isInvalidOrganizationName && (
                    <div>
                      <DDSTypography.Paragraph
                        size='caption'
                        variant='regular'
                        className='absolute -bottom-5'
                        color={pallete.colors.warningRed2}
                      >
                        {organizationErrorText}
                      </DDSTypography.Paragraph>
                    </div>
                  )}
                </div>
              </div>
              <div className='w-1/2'>
                <DDSTypography.Paragraph
                  size='para'
                  variant='semiBold'
                  color={pallete.colors.textDark3}
                  className='pb-[4px]'
                >
                  {SettingConstants.ORGANIZATION_TYPE}
                </DDSTypography.Paragraph>
                <Input
                  width='100%'
                  onChange={() => {
                    null
                  }}
                  disabled={true}
                  value={
                    organizationType
                      ? OrganizationTypeDisplayNames[
                          organizationType as keyof typeof OrganizationTypeDisplayNames
                        ]
                      : '-'
                  }
                  labelPosition='top'
                  label={
                    <DDSTypography.Paragraph
                      size='para'
                      variant='regular'
                      color={pallete.colors.textDark6}
                    >
                      Salesforce + Mulesoft / Full-stack / Both
                    </DDSTypography.Paragraph>
                  }
                  id='input'
                  name='Input'
                />
              </div>
            </div>
          </div>

          <div className='flex flex-col pt-[32px]'>
            <DDSTypography.Paragraph
              size='para'
              variant='semiBold'
              color={pallete.colors.textDark3}
            >
              Organization Avatar
              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark6}
                className='pb-[12px] pt-[4px]'
              >
                Assign an avatar for your organization
              </DDSTypography.Paragraph>
            </DDSTypography.Paragraph>
            <div>
              <div className='flex items-center gap-[16px]'>
                <div className='relative'>
                  {uploadAvatarLoader || deleteAvatarLoader ? (
                    <Spinner noMarginTop />
                  ) : (
                    <div className='flex flex-col gap-[8px]'>
                      <div className='relative h-[100px] w-[100px] rounded-full border bg-gray-200 flex justify-center items-center overflow-hidden'>
                        <FileUploader
                          handleChange={handleSubmit}
                          name='file'
                          types={fileTypes}
                          maxSize={1}
                          onTypeError={(error: any) =>
                            ToastNotification({
                              type: 'error',
                              message:
                                ToastMessageConstants.SETTINGS
                                  .UPLOAD_USER_AVATAR.ERROR
                            })
                          }
                          disabled={
                            store.userStore.getNoActiveSubscription() ||
                            store.userStore.getUserRole() !==
                              OrganizationUserRoles.OWNER ||
                            loading
                          }
                          onSizeError={(error: any) =>
                            ToastNotification({
                              type: 'error',
                              message:
                                ToastMessageConstants.SETTINGS
                                  .UPLOAD_USER_AVATAR.ERROR2
                            })
                          }
                          hoverTitle=' '
                        >
                          <div className='flex w-full h-full cursor-pointer'>
                            <img
                              src={avatar}
                              alt='Avatar'
                              className='rounded-full object-fill h-[100px] w-[100px]'
                              style={{
                                border: '1px solid',
                                borderColor: pallete.colors.stroke2
                              }}
                            />
                          </div>
                        </FileUploader>
                      </div>
                      <div>
                        <DDSTypography.Paragraph
                          size='caption'
                          variant='bold'
                          color={pallete.colors.textDark6}
                        >
                          Note
                        </DDSTypography.Paragraph>
                        <ul
                          className='list-disc pl-[8px]'
                          style={{ color: pallete.colors.textDark6 }}
                        >
                          <li>
                            <DDSTypography.Paragraph
                              size='para'
                              variant='regular'
                              color={pallete.colors.textDark6}
                            >
                              JPG, JPEG, and PNG are allowed
                            </DDSTypography.Paragraph>
                          </li>
                          <li>
                            <DDSTypography.Paragraph
                              size='para'
                              variant='regular'
                              color={pallete.colors.textDark6}
                            >
                              Maximum upload file size - 1MB
                            </DDSTypography.Paragraph>
                          </li>
                        </ul>
                      </div>

                      <div className='pt-[8px] flex flex-row gap-[12px]'>
                        <Button
                          id='upload-image'
                          type='outline'
                          size='small'
                          label='Upload Image'
                          disabled={
                            store.userStore.getNoActiveSubscription() ||
                            store.userStore.getUserRole() !==
                              OrganizationUserRoles.OWNER ||
                            loading
                          }
                          onClick={() => fileInputRef.current?.click()}
                        />
                        <Button
                          id='remove-image'
                          type='danger'
                          size='small'
                          disabled={
                            store.userStore.getNoActiveSubscription() ||
                            store.userStore.getUserRole() !==
                              OrganizationUserRoles.OWNER ||
                            loading ||
                            disableRemoveButton
                          }
                          label={
                            <DDSTypography.Paragraph
                              size='para'
                              variant='semiBold'
                              color={pallete.colors.warningRed1}
                            >
                              {SettingConstants.REMOVE}
                            </DDSTypography.Paragraph>
                          }
                          onClick={handleDeleteAvatar}
                        />
                      </div>
                    </div>
                  )}
                  {/* Hidden input to trigger file upload */}
                  <input
                    type='file'
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept='image/jpeg, image/png, image/jpg'
                  />
                </div>
              </div>
            </div>
            <div className='pt-[32px]'>
              <DDSTypography.Paragraph
                size='para'
                variant='semiBold'
                color={pallete.colors.textDark3}
                className='pb-[4px]'
              >
                {SettingConstants.TIME_ZONE}
              </DDSTypography.Paragraph>

              <DDSTypography.Paragraph
                size='para'
                variant='regular'
                color={pallete.colors.textDark6}
                className='mb-[12px]'
              >
                View accurate exception tracking, API monitoring, and data
                visualization across your organization
              </DDSTypography.Paragraph>
              <div className='flex items-start override-padding-timezone gap-[32px] override-filter-option'>
                <Select
                  filterOption={filterOption}
                  id='member-role'
                  variant='top'
                  size='extraLarge'
                  name='Member Role'
                  value={selectedTimeZone}
                  options={TimezoneDropdownOptions}
                  searchable={true}
                  placeholder='Set Timezone'
                  onChange={handleChange}
                  disabled={true}
                />
              </div>

              {/* Alert Component */}
              <div className='flex pt-[12px]'>
                <Alert
                  text={
                    <>
                      <div className='flex'>
                        <div
                          style={{
                            color: pallete.colors.alert,
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '20px'
                          }}
                        >
                          The time zone can’t be changed once set.{' '}
                          {isDisabled && 'To modify it, visit'}
                        </div>
                        {isDisabled && (
                          <a
                            href=''
                            className='underline cursor-pointer'
                            style={{
                              color: pallete.colors.textDark2,
                              fontSize: '14px',
                              fontWeight: 500,
                              lineHeight: '20px'
                            }}
                            onClick={(e: any) => {
                              window.location.href = `mailto:${StringConstants.SUPPORT_EMAIL}`
                              e.preventDefault()
                            }}
                          >
                            {''} &quot;Need help&quot;
                          </a>
                        )}
                      </div>
                    </>
                  }
                  textColor={pallete.colors.alert}
                  icon={<WarningAlt size={12} color={pallete.colors.alert} />}
                />
              </div>
            </div>
            {store.userStore.getUserRole() === OrganizationUserRoles.OWNER && (
              <div>
                <div className='py-[32px]'>
                  <hr style={{ color: pallete.colors.stroke2 }} />
                </div>

                <div className='flex flex-col'>
                  <DDSTypography.Title
                    type='h3'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                    className='pb-[12px]'
                  >
                    {SettingConstants.OWNERSHIP}
                  </DDSTypography.Title>
                  <TransferOwnerShip />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default observer(GeneralOrganizationSetting)
