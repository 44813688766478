import React, { useState } from 'react'

import Button from '../Button/Button'
import DatePicker from './DatePicker/DatePicker'
import DateRangePicker from './DateRangePicker/DateRangePicker'
import { Divider } from '@mui/material'
import { TextContentConstants } from '../../../constants/TextContentConstants'
import TimeInputStepper from './TimeInputStepper/TimeInputStepper'
import { TypographyDDS } from '../Typography/TypographyDDS'
import _ from 'lodash'
import { getDateString } from '../../../utils/DateTimeUtils'
import moment from 'moment'
import palette from '../../../global/pallete'
import styled from '@emotion/styled'
import { timeFormats } from '../../../enum/TIME'
import { useStoreContext } from '../../../store/StoreContext'

interface DateRangeSelectorProps {
  onApplyDates?: (dateRange: { start: Date | null; end: Date | null }) => void
  onApplySingleDate?: (date: Date | null) => null
  fromToLabel?: boolean
  timePicker?: boolean
  start?: Date | null
  end?: Date | null
  variant?: 'single' | 'multi'
  disableLastDays?: boolean
  validateDate?: boolean
  apiTimeZone?: boolean
  AHCMinDate?: boolean
  APITimeFormat?: boolean
}

const Container = styled.div`
  display: flex;
  gap: 12px;
  height: 400px;
  cursor: auto;
  border-top: 1px solid ${palette.colors.borderColor};
  margin-top: 24px;
`
const LeftContainer = styled.div<{ timePicker?: boolean }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  min-width: ${({ timePicker }) => (timePicker ? '118px' : '81px')};
`
const RightContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-width: 320px;
`

const LastNDaysWrapper = styled.div`
  // margin: 0px 53px 24px 24px;
`
const FromToWrapper = styled.div`
  // margin-left: 24px;
`
const LastNDaysContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 25px;
`

const TimeInputWrapper = styled.div`
  margin-top: 15px;
`

const DateViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const DateTimeWrapper = styled.div`
  cursor: pointer;
`
const TextWrapper = styled.span<{ active?: boolean }>`
  ${({ active }) => active && `color: black`};
  &:hover {
    cursor: pointer;
    color: black;
  }
`
const ColorText = styled.div`
  display: inline;
`
const DividerWrapper = styled.div``
const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  onApplyDates,
  onApplySingleDate,
  fromToLabel = true,
  timePicker = true,
  start = null,
  end = null,
  variant = 'multi',
  disableLastDays = false,
  AHCMinDate = false,
  validateDate,

  APITimeFormat = false
}) => {
  const lastNDaysList = disableLastDays ? [7, 10, 15, 30] : [0, 7, 10, 15, 30]
  const [step, setStep] = useState(-1)
  const [startDate, setStart] = useState<Date | null>(start)
  const [endDate, setEnd] = useState<Date | null>(end)
  const [nDays, setNDays] = useState<number>()
  const [dateError, setDateError] = useState<boolean>(false)
  const [stepState, setStepState] = useState<boolean>(false)
  const [intialRender, setIntialRender] = useState<boolean>(true)
  const [dateSelect, setDateSelect] = useState<boolean>(false)
  const store = useStoreContext()
  const stepDateMap = [
    { setDate: setStart, date: startDate },
    { setDate: setEnd, date: endDate }
  ]

  const onSingleDateSelect = (date: Date | null) => {
    setStart(date)
  }

  const onApplyTime = () => {
    if (step === 1) {
      setStep(-1)

      setStepState(true)
    } else {
      setStep(step + 1)
    }
  }

  const onLastNDays = (n: number) => {
    const userTimezone = store.userStore.getUserPreference().timeZone
    const organizationTimezone =
      store.userStore.getOrganizationPreference().timeZone

    setNDays(n)

    if (n === 0) {
      setStart(
        new Date(
          moment()
            .tz(APITimeFormat ? organizationTimezone : userTimezone)
            .set({ hours: 0, minutes: 0, seconds: 0 })
            .format(timeFormats.dateWithHoursAndMinutesAndSeconds)
        )
      )
    } else {
      setStart(
        new Date(
          moment()
            .tz(APITimeFormat ? organizationTimezone : userTimezone)
            .subtract(n - 1, 'days')
            .set({ hours: 0, minutes: 0, seconds: 0 })
            .format(timeFormats.dateWithHoursAndMinutesAndSeconds)
        )
      )
    }

    setEnd(
      new Date(
        moment()
          .tz(APITimeFormat ? organizationTimezone : userTimezone)
          .format(timeFormats.dateWithHoursAndMinutesAndSeconds)
      )
    )

    if (timePicker) setStep(0)
  }

  //This function is only to validate the report in AHC module
  const validateDates = (datePicker: any) => {
    const differenceInTime =
      datePicker.end?.getTime() - datePicker.start?.getTime()
    const differenceInDays = differenceInTime / (1000 * 3600 * 24)

    if (differenceInDays > 45 || Math.ceil(differenceInDays) < 1) {
      setDateError(true)
    } else {
      setDateError(false)
      return datePicker
    }
  }
  const onDatesSelect = (range: { start: Date | null; end: Date | null }) => {
    if (range.start || range.end) {
      setStart(range.start)
      setEnd(range.end)

      setDateError(false)
    }
    if (validateDate) {
      if (!validateDates(range)) {
        setDateError(true)
      }
    }
    if (
      range.start &&
      range.end &&
      timePicker &&
      !stepState &&
      (!intialRender || start == null)
    ) {
      setStep(0)
      setDateSelect(true)
    } else {
      setIntialRender(false)
    }
  }
  const onTimeChange = ({
    hours,
    minutes
  }: {
    hours: number
    minutes: number
  }) => {
    const newDate = stepDateMap[step].date
    newDate?.setHours(hours)
    newDate?.setMinutes(minutes)
    stepDateMap[step].setDate(newDate)
  }
  const renderDate = (label: string, date: Date | null) => {
    return (
      <>
        <TypographyDDS.Paragraph size='caption' variant='medium'>
          {label}
        </TypographyDDS.Paragraph>

        <TypographyDDS.Paragraph
          size='caption'
          variant='semiBold'
          color={date ? 'primary' : 'textDark2'}
        >
          {getDateString(date, timePicker)}
        </TypographyDDS.Paragraph>
      </>
    )
  }
  if (variant === 'single')
    return (
      <>
        <DatePicker onChange={onSingleDateSelect} date={startDate} />
        <span
          style={{
            paddingRight: '12px',
            width: '100%'
          }}
        >
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={() => onApplySingleDate?.(startDate)}
          >
            Apply Date
          </Button>
        </span>
      </>
    )
  return (
    <Container>
      <LeftContainer timePicker={timePicker}>
        <LastNDaysContainer>
          {lastNDaysList.map((n) => (
            <LastNDaysWrapper key={n} onClick={() => onLastNDays(n)}>
              <TypographyDDS.Paragraph size='caption' variant='medium'>
                <TextWrapper active={n === nDays}>
                  {n === 0 ? 'Today' : n === 7 ? 'Last Week' : `Last ${n} days`}
                </TextWrapper>
              </TypographyDDS.Paragraph>
            </LastNDaysWrapper>
          ))}
        </LastNDaysContainer>
        <FromToWrapper>
          {fromToLabel && (
            <DateTimeWrapper
              onClick={() => {
                setStep(-1)
              }}
            >
              {renderDate('From', startDate)}
              {renderDate('To', endDate)}
            </DateTimeWrapper>
          )}
        </FromToWrapper>
      </LeftContainer>
      <DividerWrapper>
        <Divider
          orientation='vertical'
          sx={{
            height: '94%',
            borderColor: palette.colors.borderColor,
            marginTop: '25px'
          }}
        />
      </DividerWrapper>
      <RightContainer>
        {step === -1 ? (
          <>
            <DateRangePicker
              APITimeFormat={APITimeFormat}
              startDate={startDate}
              AHCMinDate={AHCMinDate}
              endDate={endDate}
              onChange={onDatesSelect}
              disableLastDays={disableLastDays}
              dateSelect={dateSelect}
            />
            <span
              style={{
                paddingRight: '20px',
                width: '100%'
              }}
            >
              <Button
                variant='contained'
                color='primary'
                fullWidth
                onClick={() =>
                  onApplyDates?.({ start: startDate, end: endDate })
                }
                disabled={endDate == null || dateError}
              >
                Apply Dates
              </Button>
            </span>
          </>
        ) : (
          <>
            <DateViewContainer>
              <TypographyDDS.Title type='h5' variant='semiBold'>
                {step === 0 ? (
                  <span>
                    Select <b style={{ color: palette.colors.primary }}>From</b>{' '}
                    Time{' '}
                  </span>
                ) : (
                  <span>
                    Select <b style={{ color: palette.colors.primary }}>To</b>{' '}
                    Time
                  </span>
                )}
              </TypographyDDS.Title>

              <TimeInputWrapper>
                <TimeInputStepper
                  time={{
                    hours: stepDateMap[0].date?.getHours() as number,
                    minutes:
                      step === 0
                        ? (stepDateMap[0].date?.getMinutes() as number)
                        : (stepDateMap[0].date?.getMinutes() as number) + 1
                  }}
                  key={step}
                  onChange={onTimeChange}
                  date={{
                    from: startDate as Date,
                    to: endDate as Date
                  }}
                />
              </TimeInputWrapper>
            </DateViewContainer>
            <span
              style={{
                paddingRight: '20px',
                width: '100%'
              }}
            >
              <Button
                variant='contained'
                color='primary'
                fullWidth
                onClick={onApplyTime}
              >
                Apply Time
              </Button>
            </span>
          </>
        )}
        {dateError && (
          <ColorText>
            <TypographyDDS.Paragraph
              size='caption'
              color='error'
              variant='medium'
            >
              {TextContentConstants.AHC_DATE_RANGE_ERROR}
            </TypographyDDS.Paragraph>
          </ColorText>
        )}
      </RightContainer>
    </Container>
  )
}

export default DateRangeSelector
