import { includes, map, pickBy } from 'lodash'

import FilterConstants from '../constants/FilterConstants'
import { FilterTypes } from '../enum/Filter.enum'
import moment from 'moment'

const dateKeys: string[] = [
  'created-from',
  'created-till',
  'start-date',
  'end-date',

  'started-till',
  'started-from',
  'stopped-from',
  'stopped-till',
  'created-to',
  'updated-from',
  'updated-to',
  'current-date'
]

export const getFiltersForApi = (
  apiType: FilterTypes,
  filters: Record<string, any>
) => {
  map(filters, (value, key) => {
    if (dateKeys.includes(key) && value) {
      const unixValue = value / 1000

      if (!isNaN(unixValue)) {
        const date = moment.unix(unixValue).format()
        const timeZone = filters['time-zone']

        if (timeZone) {
          const timeZoneDate = moment.unix(unixValue).tz(timeZone).format()
          filters[key] = date?.replace(
            date?.substring(19),
            timeZoneDate?.substring(19)
          )
        }
      }
    }
  })

  const requiredFilters = pickBy(filters, (value: any, key: any) => {
    if (Object.hasOwn(filters, key)) {
      return includes(FilterConstants[apiType], key)
    } else return false
  })
  return requiredFilters
}
