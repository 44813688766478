import { isNull, isUndefined } from 'lodash'

import { OrganizationUserRoles } from '../enum/OrganizationUserRoles.enum'
import { replaceUrl } from './UrlUtils'
import routeConstants from '../constants/RouteConstants'

export const redirectFunction = (
  notificationDetails: any,
  uiStore: any,
  scopeStore: any
) => {
  if (notificationDetails.referenceModule !== 'USER_INVITE') {
    uiStore.setNotificationSideNavbar(false)
  }
  if (
    notificationDetails.eventName !== 'INVITED' &&
    notificationDetails.eventName !== 'REINVITED'
  ) {
    scopeStore.setOrganizationId(notificationDetails.organizationId)
    if (!isNull(notificationDetails.parentId)) {
      scopeStore.setProjectId(notificationDetails.parentId.projectId)
      if (!isUndefined(notificationDetails.parentId.environmentId))
        scopeStore.setEnvironmentId(notificationDetails.parentId.environmentId)
      else scopeStore.setEnvironmentId('')
    } else {
      scopeStore.setProjectId('')
      scopeStore.setEnvironmentId('')
    }
  }
  if (notificationDetails.referenceModule === 'USER_INVITE') {
    if (
      notificationDetails.eventName !== 'INVITED' &&
      notificationDetails.eventName !== 'REINVITED'
    )
      return replaceUrl(
        routeConstants.MEMBER_SETTINGS,
        notificationDetails.organizationId,
        '',
        ''
      )
    else {
      uiStore.setNotificationSideNavbar(true)
      return routeConstants.INVITES
    }
  } else if (notificationDetails.referenceModule === 'ERROR_MONITORING') {
    return (
      replaceUrl(
        routeConstants.SINGLE_ERROR_MONITORING,
        notificationDetails.organizationId,
        notificationDetails.parentId.projectId,
        notificationDetails.parentId.environmentId
      ) +
      '/' +
      notificationDetails.referenceId
    )
  } else if (notificationDetails.referenceModule === 'JOB_LOG') {
    return replaceUrl(
      routeConstants.JOB_MONITORING,
      notificationDetails.organizationId,
      notificationDetails.parentId.projectId,
      notificationDetails.parentId.environmentId
    )
  } else if (notificationDetails.referenceModule === 'PROJECT') {
    if (notificationDetails.eventName !== 'ADDED')
      return (
        replaceUrl(
          routeConstants.PROJECT_SETTING,
          notificationDetails.organizationId,
          notificationDetails.parentId?.projectId,
          ''
        ) +
        '/' +
        notificationDetails.referenceId
      )
    else
      return (
        replaceUrl(
          routeConstants.PROJECT_SETTING,
          notificationDetails.organizationId,
          notificationDetails.parentId?.projectId,
          ''
        ) +
        '/' +
        notificationDetails.parentId.projectId +
        '/members'
      )
  } else if (notificationDetails.referenceModule === 'ENVIRONMENT') {
    return (
      replaceUrl(
        routeConstants.PROJECT_SETTING,
        notificationDetails.organizationId,
        '',
        ''
      ) +
      '/' +
      notificationDetails.parentId.projectId +
      routeConstants.ENVIRONMENTS +
      '/' +
      notificationDetails.referenceId
    )
  } else if (notificationDetails.referenceModule === 'APP_HEALTH_CHECK') {
    const url = replaceUrl(
      routeConstants.AHC_DASHBOARD,
      notificationDetails.organizationId,
      notificationDetails.parentId.projectId,
      notificationDetails.parentId.environmentId
    )
    return url.replace(':id', notificationDetails.referenceId)
  } else if (notificationDetails.referenceModule === 'API_MONITORING') {
    return (
      replaceUrl(
        routeConstants.API_MONITORING,
        notificationDetails.organizationId,
        notificationDetails.parentId.projectId,
        notificationDetails.parentId.environmentId
      ) +
      '/' +
      notificationDetails.referenceId
    )
  } else if (notificationDetails.referenceModule === 'ORGANIZATION') {
    if (notificationDetails.content.includes(OrganizationUserRoles.BILLING)) {
      return (
        replaceUrl(
          routeConstants.MANAGE_ORGANIZATION,
          notificationDetails.organizationId,
          '',
          ''
        ) + '?tab=3'
      )
    }
    return replaceUrl(
      routeConstants.MEMBER_SETTINGS,
      notificationDetails.organizationId,
      '',
      ''
    )
  } else if (notificationDetails.referenceModule === 'JOB_MONITORING') {
    return replaceUrl(
      routeConstants.JOB_MONITORING,
      notificationDetails.organizationId,
      notificationDetails.parentId.projectId,
      notificationDetails.parentId.environmentId
    )
  } else {
    return routeConstants.REDIRECT
  }
}
