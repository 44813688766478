import {
  BiChevronDown as DownArrow,
  BiChevronLeft as LeftArrow,
  BiChevronUp as UpArrow
} from 'react-icons/bi'
import { Radio, Tooltip } from '@mui/material'
import React, { KeyboardEvent, useEffect } from 'react'
import {
  cloneDeep,
  every,
  isArray,
  isEmpty,
  isNull,
  isObject,
  omit
} from 'lodash'
import { createStyles, makeStyles } from '@material-ui/core'
import { endOfDay, isSameDay } from 'date-fns'
import { gaEventConstants, gaPageConstants } from '../../constants/GAConstants'
import { timeZone, timeZoneOrg } from '../../utils/DateTimeUtils'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Button from '../common/Button/Button'
import CheckboxGroup from '../common/Checkboxes/Checkbox'
import DateRangeSelector from '../common/DateRangeSelector/DateRangeSelector'
import DividerLine from '../common/DividerLine/DividerLine'
import FilterChip from '../common/FilterChip/FilterChip'
import HttpConstants from '../../constants/HttpConstants'
import Menu from '@mui/material/Menu'
import SearchField from '../common/InputField/SearchField'
import Spinner from '../common/Spinner/Spinner'
import StringConstants from '../../constants/StringConstants'
import { TypographyDDS } from '../common/Typography/TypographyDDS'
import { addQueryParamsToUrl } from '../../utils/UrlGenerator'
import { getAllQueryParamsAsObjectFromUrl } from '../../utils/UrlUtils'
import { isJSON } from '../../utils/CommonUtils/StringUtils'
import moment from 'moment-timezone'
import { observer } from 'mobx-react-lite'
import palette from '../../global/pallete'
import styled from '@emotion/styled'
import { timeFormats } from '../../enum/TIME'
import { useAnalyticsEventTracker } from '../../utils/GAUtils'
import { useStoreContext } from '../../store/StoreContext'

interface SecondaryContainerProps {
  width?: string
  type?: string
}

interface FilterItemProps {
  itemSelected: boolean
}

interface ParaProps {
  padding?: string
  lineHeight?: number
  fontSize?: string
  boldOnHover?: boolean
}
interface FilterProps {
  primaryText?: string
  options?: any
  onLoadOption?: () => void
  dropdownLoader?: any
  secondaryContainerWidth?: string
  dropdownLabel?: string
  initialDateRangeValue?:
    | { start: number; end: number }
    | { start: undefined; end: undefined }
  disableLastDays?: boolean
  disableDeleteChip?: boolean
  hideFilterChips?: boolean
  disabled?: boolean
  validateDate?: boolean
  isScrollable?: boolean
  clearFilterDisabled?: boolean
  hideFilterChipAPI?: boolean
  APITimeFormat?: boolean
  AHCMinDate?: boolean
  isScrollableAPI?: boolean
}
interface ContainerProps {
  height?: string
  width?: string
  borderRadius?: string
  disabled?: boolean
}
interface Filter {
  filterName: string
  filterVariant: 'text' | 'chips'
  filterValue: any
  filterAttribute?: any
  filterDisplay?: string | string[]
}
const IconStyles = { color: `${palette.colors.textLight}`, minWidth: '10px' }

const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.colors.primary};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  color: ${palette.colors.textLight};
  padding: 12px 8px;
  float: right;

  ${(props) => props.disabled && 'pointer-events: none;  opacity:0.4;'}
`
const FilterContainer = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height};
  width: ${(props) => (props.width ? props.width : '112px')};
  background-color: ${palette.colors.primary};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '8px'};
  color: ${palette.colors.textLight};
  padding: 12px 8px;
  float: right;
  ${(props) => props.disabled && 'pointer-events: none;  opacity:0.4;'};
`

const ArrowIconWrapper = styled.div`
  display: flex;
  align-items: center;
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 4px;
`

const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${palette.colors.white};
  border: 1px solid ${palette.colors.borderColor};
  top: calc(100% + 5px);
  right: 0px;
  border-radius: 8px;
  padding: 20px;
  z-index: 3;
  overflow: scroll;
`

const FilterItem = styled.div`
  position: relative;
  width: 100%;
  padding: 10px 5px;
  border-radius: 8px;
  border-bottom: 1px solid ${palette.colors.borderColor};
`

const SecondaryContainer = styled.div<SecondaryContainerProps>`
  border: 1px solid ${palette.colors.borderColor};
  min-width: 150px;
  width: ${(props) => props.width};
  padding: 20px;
  ${({ type }) => type === 'DATE_RANGE' && 'padding-right: 0px;'}
  z-index: 3;
  border-radius: 8px;
  right: calc(100% + 30px);
  top: 0px;
  color: black;
  background-color: ${palette.colors.white};
`
const FilterDisplay = styled.div`
  display: flex;
  align-items: center;
`

const StyledParagraph = styled(TypographyDDS.Paragraph)<{
  boldOnHover?: boolean
}>`
  &:hover {
    color: ${(props) => (props.boldOnHover ? palette.colors.textDark : '')};
    font-weight: ${(props) => (props.boldOnHover ? 'semiBold' : 'normal')};
  }
`
const StyledTitle = styled(TypographyDDS.Title)<{
  boldOnHover?: boolean
}>`
  &:hover {
    color: ${(props) => (props.boldOnHover ? palette.colors.textDark : '')};
    font-weight: ${(props) => (props.boldOnHover ? 'semiBold' : 'normal')};
  }
  padding-bottom: 4px;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const RadioLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
interface LeftProps {
  disabled: boolean
}
const Left = styled.div<LeftProps>`
  justify-content: flex-start;
  ${(props) => props.disabled && 'pointer-events: none;  opacity:0.4;'};
`
const Right = styled.div`
  justify-content: flex-end;
`
const OptionsTextWrapper = styled.span`
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Filter: React.FC<FilterProps> = ({
  primaryText = 'Add Filters',
  options,
  onLoadOption,
  dropdownLoader,
  dropdownLabel = 'Filters',
  secondaryContainerWidth,
  initialDateRangeValue,
  hideFilterChips = false,
  disableLastDays = false,
  disableDeleteChip = false,
  disabled = false,
  validateDate,
  isScrollable = false,
  isScrollableAPI = false,
  clearFilterDisabled = false,
  hideFilterChipAPI = false,
  APITimeFormat = false,
  AHCMinDate = false
}) => {
  //menu handler
  const [filterAnchor, setFilterAnchor] = React.useState<null | HTMLElement>(
    null
  )
  const openMenu = Boolean(filterAnchor)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onLoadOption) {
      onLoadOption()
    }
    setFilterAnchor(event.currentTarget) // Set the filter anchor
  }
  const closeFilters = (e: any) => {
    setFilterAnchor(null)
  }

  //Secondary Box Menu Handler
  const [secondaryAnchor, setSecondaryAnchor] =
    React.useState<null | HTMLElement>(null)
  const openSecondaryBox = Boolean(secondaryAnchor)
  const handleClickForSecondaryBox = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (secondaryAnchor === event.currentTarget) {
      setSecondaryAnchor(null)
    } else {
      setSecondaryAnchor(event.currentTarget)
    }
  }
  const closeSecondaryBox = () => {
    setSecondaryAnchor(null)
  }

  // handle options
  const [menuOptions, setMenuOptions] = React.useState<any>({})

  //filter handle functions
  const [searchData, setSearchData] = React.useState('')

  const [fieldToBeSearched, setFieldToBeSearched] = React.useState('')

  const useStyles = makeStyles(() =>
    createStyles({
      list: {
        padding: '0',
        borderRadius: 8
      }
    })
  )
  const classes = useStyles()
  const gaEventTracker = useAnalyticsEventTracker(gaPageConstants.FILTERS)
  const [queryParams, setQueryParams] = React.useState<any>({})
  const clearedFilters: Filter[] = []
  const [FilterChips, setFilterChip] = React.useState<Filter[]>([])
  const [FilterRecord, setFilterRecord] = React.useState<any>([])
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const store = useStoreContext()

  React.useEffect(() => {
    const search = getAllQueryParamsAsObjectFromUrl(location.search)
    store.filterStore.setFilterDisabled(true)
    setSearchParams(
      { page: StringConstants.DEFAULT_PAGE.toString(), ...search },
      { replace: true }
    )
  }, [store.scopeStore.getScope()['environment-id']])

  const startDateConstants = [
    'created-from',
    'started-from',
    'stopped-from',
    'updated-from'
  ]
  const endDateConstants = [
    'created-to',
    'started-till',
    'stopped-till',
    'updated-to'
  ]
  const clearAllHandler = () => {
    setFilterAnchor(null)
    setFilterChip(clearedFilters)
    setFilterRecord([])
    setQueryParams({})

    if (store.filterStore.getFiltersApplied()) {
      const url = addQueryParamsToUrl(
        '',
        {
          page: StringConstants.DEFAULT_PAGE.toString(),
          ...Object.fromEntries(
            Array.from(searchParams.entries()).filter(([key]) =>
              key.includes('sort-')
            )
          ),
          ...(searchParams.get('subPage')
            ? { subPage: StringConstants.DEFAULT_PAGE.toString() }
            : {}),
          ...(searchParams.get('exception-type')
            ? {
                'exception-type': searchParams.get('exception-type') as string
              }
            : {}),
          ...(searchParams.get('search-string') && {
            'search-string': searchParams.get('search-string') as string,
            'search-type': searchParams.get('search-type') || 'any'
          })
        },
        HttpConstants.GET_METHOD
      )

      navigate(url)
    }
    store.filterStore.setFiltersApplied(false)
  }

  const getDateRangeFromQuery = (startAttribute: string) => {
    // TODO  Populate the daterange from store instead of query directly
    const index = startDateConstants.findIndex(
      (query) => query === startAttribute
    )
    if (index === -1) return {}
    const filter = store.filterStore.getFilters()
    const start = Number(queryParams[startDateConstants[index]])

    const end = Number(queryParams[endDateConstants[index]])

    return {
      start,
      end
    }
  }

  const checkIfDatesAreApplied = () => {
    let applyFilters = false
    let index = null
    for (let i = 0; i < startDateConstants.length; i++) {
      if (Object.keys(queryParams).includes(startDateConstants[i])) {
        index = i
        break
      }
    }
    if (!isNull(index)) {
      const startDateKey = startDateConstants[index]
      const endDateKey = endDateConstants[index]
      if (
        moment(store.filterStore.getFilters()[startDateKey]).format() !==
          moment.unix(queryParams[startDateKey] / 1000).format() ||
        moment(store.filterStore.getFilters()[endDateKey]).format() !==
          moment.unix(queryParams[endDateKey] / 1000).format()
      ) {
        applyFilters = true
      }
    }

    return applyFilters
  }

  const getDisplayText = (values: any, filterItem: any) => {
    const displayTexts = []
    for (let i = 0; i < filterItem.values.length; i++) {
      if (values.includes(filterItem.values[i].value)) {
        displayTexts.push(filterItem.values[i].displayText)
      }
    }
    return displayTexts
  }

  useEffect(() => {
    if (options?.length) {
      sessionStorage.setItem('options', JSON.stringify(options))
    }
  }, [options])

  const queryObjectForFilter = (queryParams: any) => {
    const option = sessionStorage.getItem('options')
    const sessionOptions = isJSON(option || '') ? JSON.parse(option!) : []
    const filterOptions = options?.length
      ? options
      : sessionOptions?.length
        ? sessionOptions
        : []
    const queryFilter: Filter[] = []
    let key = queryParams ? Object.keys(queryParams) : []
    for (let i = 0; i < key.length; i++) {
      for (let j = 0; j < filterOptions.length; j++) {
        let checkboxObjectBuilded = false
        for (let k = 0; k < filterOptions[j].values.length; k++) {
          if (filterOptions[j].filterAttribute == key[i]) {
            if (
              isArray(queryParams[key[i]]) &&
              queryParams[key[i]].includes(filterOptions[j].values[k].value) &&
              filterOptions[j].type === 'CHECKBOX'
            ) {
              for (let m = 0; m < queryParams[key[i]].length; m++) {
                if (
                  queryParams[key[i]][m] === filterOptions[j].values[k].value &&
                  !checkboxObjectBuilded
                ) {
                  queryFilter.push({
                    filterName: filterOptions[j].heading,
                    filterVariant: 'chips',
                    filterValue: queryParams[key[i]],
                    filterDisplay: getDisplayText(
                      queryParams[key[i]],
                      filterOptions[j]
                    ),
                    filterAttribute: filterOptions[j].filterAttribute
                  })
                  checkboxObjectBuilded = true
                }
              }
            } else if (
              filterOptions[j].values[k].value == queryParams[key[i]]
            ) {
              queryFilter.push({
                filterName: filterOptions[j].heading,
                filterVariant:
                  filterOptions.variant === 'CHECKBOX' ? 'chips' : 'text',
                filterValue: filterOptions[j].values[k].value,
                filterDisplay: filterOptions[j].values[k].displayText,
                filterAttribute: filterOptions[j].filterAttribute
              })
            }
          }
        }

        if (
          filterOptions[j].type === 'DATE_RANGE' &&
          Object.values(filterOptions[j].filterAttribute).includes(key[i])
        ) {
          if (startDateConstants.includes(key[i])) {
            key = key.filter(
              (record: any) => record !== filterOptions[j].filterAttribute.end
            )
          }
          if (!hideFilterChipAPI) {
            queryFilter.push({
              filterName: filterOptions[j].heading,
              filterVariant: 'text',
              filterValue:
                moment(
                  queryParams[filterOptions[j].filterAttribute.start]
                ).format(timeFormats.monthDateYear) +
                '-' +
                moment(
                  queryParams[filterOptions[j].filterAttribute.end]
                ).format(timeFormats.monthDateYear),
              filterDisplay: APITimeFormat
                ? timeZone(
                    store.userStore.getOrganizationPreference().timeZone,
                    queryParams[filterOptions[j].filterAttribute.start],
                    timeFormats.monthDateYear
                  ) +
                  '-' +
                  timeZone(
                    store.userStore.getOrganizationPreference().timeZone,
                    queryParams[filterOptions[j].filterAttribute.end],
                    timeFormats.monthDateYear
                  )
                : timeZone(
                    store.userStore.getUserPreference().timeZone,
                    queryParams[filterOptions[j].filterAttribute.start],
                    timeFormats.monthDateYear
                  ) +
                  '-' +
                  timeZone(
                    store.userStore.getUserPreference().timeZone,
                    queryParams[filterOptions[j].filterAttribute.end],
                    timeFormats.monthDateYear
                  ),
              filterAttribute: filterOptions[j].filterAttribute
            })
          }
        }
      }
    }

    return queryFilter
  }

  React.useEffect(() => {
    const currentQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    const paramsForFilters = store.filterStore.getFilters()
    setFilterChip(queryObjectForFilter(paramsForFilters))
    setFilterRecord(queryObjectForFilter(paramsForFilters))

    if (
      Object.keys(currentQueryParams).filter(
        (key) =>
          ![
            'page',
            'size',
            'subPage',
            'tab',
            'view',
            'contextSwitcherTab',
            'previous-run-status',
            'previous-check-status',
            'search-string',
            'search-type',
            'searchTerm',
            'time-zone'
          ].includes(key)
      ).length > 0
    ) {
      store.filterStore.setFiltersApplied(true)
    }
    setSearchData('')
    setQueryParams({ ...currentQueryParams })
  }, [JSON.stringify(store.filterStore.getFilters()), options, filterAnchor])

  const applyFilterHandler = () => {
    const params: any = {}
    new URLSearchParams(window.location.search).forEach((value, key) => {
      if (params[key]) {
        if (Array.isArray(params[key])) {
          params[key].push(value)
        } else {
          params[key] = [params[key], value]
        }
      } else {
        params[key] = value
      }
    })

    setFilterAnchor(null)

    setSecondaryAnchor(null)

    const areQueryParamsEqual = every(
      params,
      (value, key) =>
        String(value) === String(queryParams[key]) &&
        Object.keys(params).length === Object.keys(queryParams).length
    )
    if (!areQueryParamsEqual) {
      store.filterStore.setFiltersApplied(true)
      setQueryParams({ ...params, ...queryParams })

      const url = addQueryParamsToUrl(
        '',
        {
          ...queryParams,
          page: StringConstants.DEFAULT_PAGE.toString(),
          'time-zone': APITimeFormat
            ? store.userStore.getOrganizationPreference()?.timeZone
            : store.userStore.getUserPreference().timeZone,

          ...(searchParams.get('subPage')
            ? { subPage: StringConstants.DEFAULT_PAGE.toString() }
            : {})
        },
        HttpConstants.GET_METHOD
      )

      store.filterStore.setFilterDisabled(true)
      navigate(url)
    }
  }

  const handleDeleteMainChip = (name: string) => {
    if (FilterRecord.length === 1) {
      store.filterStore.setFiltersApplied(false)
    }
    for (let i = 0; i < FilterRecord.length; i++) {
      if (FilterRecord[i].filterName == name) {
        const queryRecord: Filter = cloneDeep(FilterRecord[i])
        let updatedParams: Record<string, string | number> = {}
        if (isObject(queryRecord.filterAttribute)) {
          updatedParams = omit(
            queryParams,
            Object.values(queryRecord.filterAttribute)
          )
        } else updatedParams = omit(queryParams, [queryRecord.filterAttribute])

        updatedParams['page'] = StringConstants.DEFAULT_PAGE.toString()

        const urlForDelete = addQueryParamsToUrl(
          '',
          {
            page: StringConstants.DEFAULT_PAGE.toString(),
            ...(searchParams.get('page')
              ? {
                  ...updatedParams,
                  subPage: StringConstants.DEFAULT_PAGE.toString()
                }
              : {}),
            ...(searchParams.get('exception-type')
              ? {
                  ...updatedParams,
                  'exception-type': searchParams.get('exception-type') as string
                }
              : {})
          },
          HttpConstants.GET_METHOD
        )
        setQueryParams(updatedParams)
        setFilterChip(
          FilterChips.filter((filter: any) => filter.filterName !== name)
        )
        setFilterRecord(
          FilterRecord.filter((filter: any) => filter.filterName !== name)
        )
        store.filterStore.setFilterDisabled(true)
        navigate(urlForDelete)
      }
    }
  }

  const handleDeleteSubChip = (filterAttribute: any, displayValue: any) => {
    store.filterStore.setFilterDisabled(true)
    let value = displayValue
    for (let i = 0; i < options.length; i++) {
      if (options[i].filterAttribute === filterAttribute) {
        for (let j = 0; j < options[i].values.length; j++) {
          if (options[i].values[j].displayText === displayValue) {
            value = options[i].values[j].value
          }
        }
      }
    }
    for (let i = 0; i < FilterRecord.length; i++) {
      if (
        FilterRecord[i].filterAttribute === filterAttribute &&
        FilterRecord[i].filterValue.includes(value)
      ) {
        const copyOfQueryParams = cloneDeep(queryParams)
        const valuesForFilterAttributeAfterDelete: any = []
        copyOfQueryParams[filterAttribute].map((values: any) => {
          if (values !== value) {
            valuesForFilterAttributeAfterDelete.push(values)
          }
        })
        copyOfQueryParams[filterAttribute] = valuesForFilterAttributeAfterDelete
        copyOfQueryParams['page'] = StringConstants.DEFAULT_PAGE.toString()

        const url = addQueryParamsToUrl(
          '',
          copyOfQueryParams,
          HttpConstants.GET_METHOD
        )
        navigate(url)
      }
    }
  }

  const getSelected = (value: any, heading: string) => {
    let selected = false
    for (let i = 0; i < FilterRecord.length; i++) {
      if (
        FilterRecord[i].filterName === heading &&
        ((Array.isArray(FilterRecord[i].filterValue) &&
          FilterRecord[i].filterValue.includes(value.toString())) ||
          FilterRecord[i].filterValue.toString() == value.toString())
      ) {
        selected = true
      }
    }

    return selected
  }

  const renderFilter = (item: any) => {
    const handleChange = (
      dateRange?: any,
      event?: React.ChangeEvent<HTMLInputElement>
    ) => {
      const filter: Filter = {
        filterName: '',
        filterVariant: 'text',
        filterValue: []
      }
      if (item.type === 'DATE_RANGE' && item.variant === 'DATE_ONLY') {
        if (
          isSameDay(
            dateRange?.end,
            new Date(
              moment
                .tz(
                  APITimeFormat
                    ? store.userStore.getOrganizationPreference().timeZone
                    : store.userStore.getUserPreference().timeZone
                )
                .format(timeFormats.dateWithHoursAndMinutesAndSeconds)
            )
          )
        ) {
          dateRange.end = new Date(
            moment
              .tz(
                APITimeFormat
                  ? store.userStore.getOrganizationPreference().timeZone
                  : store.userStore.getUserPreference().timeZone
              )
              .format(timeFormats.dateWithHoursAndMinutesAndSeconds)
          )
        } else {
          dateRange.end = endOfDay(dateRange.end)
        }
      }

      let selectedChips = cloneDeep(FilterRecord)
      let isHeadingPresent = false
      if (selectedChips.length > 0) {
        for (let i = 0; i < selectedChips.length; i++) {
          if (selectedChips[i].filterName === item.heading) {
            isHeadingPresent = true
            if (item.type === 'DATE_RANGE') {
              filter.filterName = item.heading
              filter.filterVariant = 'text'
              filter.filterValue =
                moment(dateRange['start']).format('MMMM Do, YYYY') +
                '-' +
                moment(dateRange['end']).format('MMMM Do, YYYY')
              filter.filterAttribute = item.filterAttribute
              filter.filterDisplay = filter.filterValue
              if (item.variant === 'DATE_ONLY') {
                setQueryParams({
                  ...queryParams,
                  ...{
                    [item.filterAttribute.start]: moment(
                      dateRange.start
                    ).valueOf(),
                    [item.filterAttribute.end]: moment(dateRange.end).valueOf()
                  },
                  'time-zone': APITimeFormat
                    ? store.userStore.getOrganizationPreference()?.timeZone
                    : store.userStore.getUserPreference().timeZone
                })
              } else {
                setQueryParams({
                  ...queryParams,
                  ...{
                    [item.filterAttribute.start]: dateRange.start.getTime(),
                    [item.filterAttribute.end]: dateRange.end.getTime()
                  }
                })
              }
              selectedChips[i] = filter
            } else if (item.type === 'SELECT') {
              if (item.variant === 'SINGLE') {
                filter.filterName = item.heading
                filter.filterVariant = 'text'
                filter.filterValue = event?.target.value.toString() as string
                for (let i = 0; i < item.values.length; i++) {
                  if (item.values[i].value.toString() === event?.target.value) {
                    filter.filterDisplay = item.values[i].displayText
                  }
                }
                filter.filterAttribute = item.filterAttribute
                setQueryParams({
                  ...queryParams,
                  ...{
                    [item.filterAttribute]: event?.target.value.toString()
                  }
                })
                selectedChips[i] = filter
              }
            } else if (item.type === 'CHECKBOX') {
              if (item.variant === 'MULTIPLE') {
                if (
                  !selectedChips[i].filterValue.includes(event?.target.value)
                ) {
                  if (isArray(selectedChips[i].filterValue)) {
                    selectedChips[i].filterValue.push(event?.target.value)
                  } else {
                    const createdArray = [
                      selectedChips[i].filterValue,
                      event?.target.value
                    ]

                    selectedChips[i].filterValue = createdArray
                  }
                  setQueryParams({
                    ...queryParams,
                    ...{
                      [item.filterAttribute]: selectedChips[i].filterValue
                    }
                  })
                }
              }
            }
          }
        }
      }
      if (!isHeadingPresent) {
        if (item.type === 'DATE_RANGE') {
          filter.filterName = item.heading
          filter.filterVariant = 'text'

          filter.filterValue =
            moment(dateRange['start']).format('MMMM Do, YYYY') +
            '-' +
            moment(dateRange['end']).format('MMMM Do, YYYY')

          filter.filterDisplay = filter.filterValue
          filter.filterAttribute = item.filterAttribute
          setQueryParams({
            ...queryParams,
            ...{
              [item.filterAttribute.start]: dateRange.start.getTime(),
              [item.filterAttribute.end]: dateRange.end.getTime()
            }
          })

          selectedChips.push(filter)
        } else if (item.type === 'SELECT') {
          if (item.variant == 'SINGLE') {
            filter.filterName = item.heading
            filter.filterVariant = 'text'
            filter.filterValue = event?.target.value as string
            for (let i = 0; i < item.values.length; i++) {
              if (item.values[i].value.toString() === event?.target.value) {
                filter.filterDisplay = item.values[i].displayText
              }
            }
            filter.filterAttribute = item.filterAttribute

            if (
              item.filterAttribute === 'sort-start-time' ||
              item.filterAttribute === 'sort-stop-time'
            ) {
              const delSortingParam =
                item.filterAttribute === 'sort-start-time'
                  ? 'sort-stop-time'
                  : 'sort-start-time'

              if (queryParams[delSortingParam]) {
                delete queryParams[delSortingParam]
              }
            }

            setQueryParams({
              ...queryParams,
              ...{
                [item.filterAttribute]: [filter.filterValue]
              }
            })
            selectedChips.push(filter)
          }
        } else if (item.type === 'CHECKBOX') {
          if (item.variant === 'MULTIPLE') {
            filter.filterName = item.heading
            filter.filterVariant = 'chips'
            filter.filterValue = [event?.target.value as string]
            for (let i = 0; i < item.values.length; i++) {
              if (item.values[i].value == event?.target.value) {
                filter.filterDisplay = item.values[i].displayText
              }
            }
            filter.filterAttribute = item.filterAttribute

            setQueryParams({
              ...queryParams,
              ...{
                [item.filterAttribute]: [event?.target.value]
              }
            })
            selectedChips.push(filter)
          }
        }
      }
      const startIndex = selectedChips.findIndex(
        (item: any) => item.filterAttribute === 'sort-start-time'
      )
      const stopIndex = selectedChips.findIndex(
        (item: any) => item.filterAttribute === 'sort-stop-time'
      )
      selectedChips = selectedChips.filter((item: any, index: any) => {
        return index !== (startIndex < stopIndex ? startIndex : stopIndex)
      })

      setFilterRecord(selectedChips)
    }

    const handleDeselectingCheckbox = (
      event: React.ChangeEvent<HTMLInputElement>,
      recordValue: any
    ) => {
      let selectedChips = cloneDeep(FilterRecord)

      for (let i = 0; i < selectedChips.length; i++) {
        if (
          selectedChips[i].filterName === item.heading &&
          selectedChips[i].filterValue.includes(recordValue)
        ) {
          if (isArray(selectedChips[i].filterValue)) {
            selectedChips[i].filterValue = selectedChips[i].filterValue.filter(
              (value: any) => value !== recordValue
            )

            if (selectedChips[i].filterValue.length === 0) {
              selectedChips = selectedChips.filter(
                (record: any) => record !== selectedChips[i]
              )
            }
          } else {
            selectedChips = selectedChips.filter(
              (record: any) => record !== selectedChips[i]
            )
          }

          const selectedQueryParams = cloneDeep(queryParams)
          if (isArray(selectedQueryParams[item.filterAttribute])) {
            selectedQueryParams[item.filterAttribute] = selectedQueryParams[
              item.filterAttribute
            ].filter((value: any) => value !== recordValue)
          } else {
            selectedQueryParams[item.filterAttribute] = []
          }
          setQueryParams(selectedQueryParams)

          break
        }
      }

      setFilterRecord(selectedChips)
    }

    switch (item.type) {
      case 'SELECT': {
        return item.values
          .filter((search: any) => {
            if (
              item.searchable &&
              search.displayText
                .toLowerCase()
                .includes(searchData.toLowerCase()) &&
              fieldToBeSearched === item.heading
            ) {
              return search
            } else if (
              !item.searchable ||
              searchData === '' ||
              fieldToBeSearched !== item.heading
            ) {
              return search
            }
          })
          .map((record: any, index: number) => (
            <StyledParagraph
              size='para'
              variant='medium'
              boldOnHover={true}
              key={index}
            >
              <RadioLabel htmlFor={item.heading + record.displayText}>
                <Radio
                  sx={{
                    padding: '4px',
                    '& .MuiSvgIcon-root': {
                      fontSize: 20
                    }
                  }}
                  id={item.heading + record.displayText}
                  onChange={(e) => handleChange('', e)}
                  checked={getSelected(record.value, item.heading)}
                  value={record.value}
                  color='primary'
                  size='small'
                />
                {record.displayText}
              </RadioLabel>
            </StyledParagraph>
          ))
      }

      case 'CHECKBOX': {
        const result = item.values.filter((search: any) => {
          if (!search.displayText) return false

          if (
            item.searchable &&
            search.displayText
              .toLowerCase()
              .includes(searchData.toLowerCase()) &&
            fieldToBeSearched === item.heading
          ) {
            return true
          }

          if (
            !item.searchable ||
            searchData === '' ||
            fieldToBeSearched !== item.heading
          ) {
            return true
          }

          return false
        })

        if (!isEmpty(result)) {
          return result.map((record: any, index: number) => (
            <StyledParagraph
              size='para'
              variant='semiBold'
              boldOnHover={true}
              key={index}
            >
              <FilterDisplay>
                <label
                  htmlFor={item.heading + record.displayText}
                  style={{ display: 'flex', cursor: 'pointer' }}
                >
                  <CheckboxGroup
                    id={item.heading + record.displayText}
                    handleChange={(e: any) => {
                      if (e?.target.checked === true) {
                        handleChange('', e)
                      } else {
                        handleDeselectingCheckbox(e, record.value)
                      }
                    }}
                    checked={getSelected(record.value, item.heading)}
                    value={record.value}
                  />
                  <FilterDisplay>
                    <Tooltip
                      id={`${record.displayText}`}
                      title={
                        record.displayText.length > 30 ? record.displayText : ''
                      }
                      placement='top'
                    >
                      <OptionsTextWrapper>
                        {record.displayText}
                      </OptionsTextWrapper>
                    </Tooltip>
                  </FilterDisplay>
                </label>
              </FilterDisplay>
            </StyledParagraph>
          ))
        } else
          return (
            <>
              <DividerLine margin='10px' />
              {`No matching ${item.heading} found`}
            </>
          )
      }
      case 'DATE_RANGE': {
        let range = getDateRangeFromQuery(item.filterAttribute.start)
        if (
          (!range.start || !range.end) &&
          initialDateRangeValue?.start &&
          initialDateRangeValue.end
        ) {
          range = initialDateRangeValue
        }

        let startDate = null,
          endDate = null
        if (range.start && range.end) {
          startDate = new Date(range.start)
          endDate = new Date(range.end)
        }

        return (
          <>
            <DateRangeSelector
              onApplyDates={(dateRange) => {
                handleChange(dateRange)

                setSecondaryAnchor(null)
                // applyFilterHandler()
              }}
              APITimeFormat={APITimeFormat}
              AHCMinDate={AHCMinDate}
              start={startDate}
              end={endDate}
              key={item.heading}
              validateDate={validateDate}
              timePicker={item.variant !== 'DATE_ONLY'}
              disableLastDays={disableLastDays}
            />
          </>
        )
      }
    }
  }

  return (
    <>
      <Row
        style={{
          marginRight: isScrollable ? '8px' : '8px',
          maxWidth: isScrollable
            ? '60vh'
            : isScrollableAPI
              ? '50vh'
              : undefined,
          height: isScrollable ? '34px' : isScrollableAPI ? '34px' : undefined,
          overflowY: isScrollable
            ? 'auto'
            : isScrollableAPI
              ? 'auto'
              : undefined,
          overflowX: isScrollable ? 'auto' : 'auto',
          whiteSpace: isScrollable
            ? 'nowrap'
            : isScrollableAPI
              ? 'nowrap'
              : undefined
        }}
      >
        <Left
          disabled={store.filterStore.getFilterDisabled()}
          style={{
            cursor: store.filterStore.getFilterDisabled()
              ? 'not-allowed'
              : disableDeleteChip
                ? 'default'
                : 'pointer'
          }}
        >
          {!hideFilterChips && (
            <>
              {FilterChips.map(
                (
                  {
                    filterVariant,
                    filterName,
                    filterValue,
                    filterDisplay,
                    filterAttribute
                  },
                  index
                ) => (
                  <FilterChip
                    key={index}
                    variant={filterVariant}
                    name={filterName}
                    value={
                      isArray(filterDisplay) ? filterDisplay : [filterDisplay]
                    }
                    onDeleteMainChip={(name: string) => {
                      handleDeleteMainChip(name)
                      gaEventTracker(gaEventConstants.CLEAR_EACH_FILTER)
                    }}
                    onDeleteSubChip={(value: any) => {
                      handleDeleteSubChip(filterAttribute, value)
                    }}
                    disableDeleteChip={disableDeleteChip}
                    textTransform={
                      filterName === 'URL Template' ? 'none' : 'capitalize'
                    }
                  />
                )
              )}
            </>
          )}
        </Left>

        <Right>
          <Menu
            open={openMenu}
            anchorEl={filterAnchor}
            onClose={closeFilters}
            id='add-filter-menu'
            MenuListProps={{
              'aria-labelledby': 'add-filters'
            }}
            PaperProps={{ style: { borderRadius: 8 } }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            elevation={0}
            onClick={() => {
              secondaryAnchor && setSecondaryAnchor(null)
            }}
            sx={{ marginTop: '15px' }}
            classes={{ list: classes.list }}
          >
            <DropdownContainer>
              {dropdownLoader ? (
                <div className=' flex justify-center items-center w-[8vw] h-[26vh]'>
                  <Spinner />
                </div>
              ) : (
                <div>
                  <TypographyDDS.Title type='h5'>
                    {dropdownLabel}
                  </TypographyDDS.Title>
                  {options?.map((option: any, index: any) => (
                    <FilterItem
                      key={index}
                      onClick={(e: any) => {
                        if (
                          option.isExpandable ||
                          option.type === 'DATE_RANGE'
                        ) {
                          setSearchData('')
                          setMenuOptions({})
                          setMenuOptions(option)
                          handleClickForSecondaryBox(e)
                          e.stopPropagation()
                        } else {
                          setSearchData('')
                          setMenuOptions({})
                        }
                      }}
                    >
                      <>
                        <StyledParagraph
                          size='para'
                          boldOnHover={
                            option.isExpandable || option.type === 'DATE_RANGE'
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          {option.isExpandable ||
                          option.type === 'DATE_RANGE' ? (
                            <ArrowIconWrapper>
                              <LeftArrow />
                              <StyledParagraph size='para'>
                                {option.heading}
                              </StyledParagraph>
                            </ArrowIconWrapper>
                          ) : (
                            <StyledTitle type='h5' variant='medium'>
                              {option.heading}
                            </StyledTitle>
                          )}
                        </StyledParagraph>
                        <>
                          {!(
                            option.isExpandable || option.type === 'DATE_RANGE'
                          ) ? (
                            <>{renderFilter(option)}</>
                          ) : (
                            <></>
                          )}
                        </>
                      </>
                    </FilterItem>
                  ))}
                  <Button
                    variant='contained'
                    size='small'
                    margin='10px 0px'
                    boxShadow={false}
                    onClick={() => {
                      applyFilterHandler()
                      gaEventTracker(gaEventConstants.APPLY_FILTERS)
                    }}
                    disabled={isEmpty(FilterRecord)}
                    fullWidth
                  >
                    Apply Filters
                  </Button>
                  <Button
                    variant='outlined'
                    size='small'
                    boxShadow={false}
                    onClick={() => {
                      gaEventTracker(gaEventConstants.CLEAR_FILTERS)
                      clearAllHandler()
                    }}
                    disabled={isEmpty(FilterRecord) && !clearFilterDisabled}
                    fullWidth
                  >
                    Clear Filters
                  </Button>
                </div>
              )}
            </DropdownContainer>

            {!isEmpty(menuOptions) && (
              <Menu
                open={openSecondaryBox}
                anchorEl={secondaryAnchor}
                onClose={closeSecondaryBox}
                onClick={(e) => e.stopPropagation()}
                id='secondary-filters-menu'
                MenuListProps={{
                  'aria-labelledby': 'secondary-filters-menu-button'
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                sx={{ marginLeft: '-25px' }}
                style={{ pointerEvents: 'none' }}
                PaperProps={{ style: { pointerEvents: 'auto' } }}
                elevation={0}
                classes={{ list: classes.list }}
              >
                <SecondaryContainer
                  width={secondaryContainerWidth}
                  type={menuOptions.type}
                >
                  <span
                    style={{
                      fontWeight: palette.fontWeight.bold,
                      fontSize: '14px'
                    }}
                  >
                    {menuOptions.heading}
                  </span>
                  {menuOptions.searchable && (
                    <SearchField
                      value={searchData}
                      onChange={(e: any) => {
                        e.stopPropagation()
                        setSearchData(e.target.value)
                        if (e.target.value !== '')
                          setFieldToBeSearched(menuOptions.heading)
                      }}
                      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                        e.stopPropagation()
                      }}
                    />
                  )}
                  {renderFilter(menuOptions)}
                </SecondaryContainer>
              </Menu>
            )}
          </Menu>
        </Right>
      </Row>
      <FilterContainer
        onClick={(e: any) => {
          gaEventTracker(gaEventConstants.OPEN_FILTER_DROPDOWN)
          handleClick(e)
        }}
        disabled={disabled}
        style={{
          cursor: 'pointer'
        }}
      >
        <Wrapper
          style={{
            display: 'flex',
            width: '350px',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <TypographyDDS.Paragraph
            size='para'
            variant='semiBold'
            color='white'
            style={{ textAlign: 'center' }}
          >
            {primaryText}
          </TypographyDDS.Paragraph>
          {!openMenu && <DownArrow size={16} style={IconStyles} />}
          {openMenu && <UpArrow size={16} style={IconStyles} />}
        </Wrapper>
      </FilterContainer>
    </>
  )
}

export default observer(Filter)
