import React from 'react'
import pallete from '../../../global/newPallete'

interface AlertProps {
  text: any
  textColor: string
  icon: React.ReactNode
}

const Alert: React.FC<AlertProps> = ({ text, textColor, icon }) => {
  return (
    <div
      className={`inline-flex items-center py-[6px] px-[12px]  rounded-md`}
      style={{ backgroundColor: pallete.colors.warningBgOrange }}
    >
      <span className={`mr-[8px]`}>{icon}</span>
      <span style={{ color: textColor }} className={`text-sm `}>
        {text}
      </span>
    </div>
  )
}

export default Alert
