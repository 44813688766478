import { DDSChips, DDSTooltip, DDSTypography } from 'den-design-system'
import React, { useEffect, useState } from 'react'
import { msToTimeUnits, timeZone } from '../../utils/DateTimeUtils'
import { useParams, useSearchParams } from 'react-router-dom'

import APIOverviewCard from './Components/APIOverviewCard'
import APIOverviewTable from './Components/APIOverviewTable'
import ApiGroupingStringConstants from '../../constants/ApiGroupingStringConstants'
import Spinner from '../common/Spinner/Spinner'
import { isUndefined } from 'lodash'
import { mapStatusCodeToColor } from '../../utils/ApiStatusColorCode'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import pallete from '../../global/newPallete'
import { replaceUrl } from '../../utils/UrlUtils'
import routeConstants from '../../constants/RouteConstants'
import { timeFormats } from '../../enum/TIME'
import { useStoreContext } from '../../store/StoreContext'

const ViewOverview: React.FC = () => {
  const store = useStoreContext()
  const { id } = useParams()

  const [loading, setLoading] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const apiDetails = store.apiDetailsStore.getApiData()

  useEffect(() => {
    setLoading(store.apiDetailsStore.getAPILoading())
  }, [store.apiDetailsStore.apiLoading])

  const organizations = store.userStore.getOrganizations()
  const foundOrganization = organizations.find(
    (organization: any) =>
      organization.id === store.scopeStore.getOrganizationId()
  )

  useEffect(() => {
    // Get URL parameters
    const tabFromParams = searchParams.get('tab')
    const tabFromUserParam = searchParams.get('userTab')
    const tabFromUrlParam = searchParams.get('urlViewTab')
    const tabFromAllUserParam = searchParams.get('allUserTab')
    const tabFromParamsOverviewUserTab = searchParams.get('apiOverviewTab')

    // Get organization timezone and setup date filters
    const orgTimeZone =
      store.userStore.getOrganizationPreference()?.timeZone || 'UTC'
    const nowInOrgTimeZone = moment.tz(orgTimeZone)
    const startOfYear = nowInOrgTimeZone.clone().startOf('year').toISOString()
    const endOfCurrentDay = nowInOrgTimeZone.clone().endOf('day').toISOString()

    // Get current filters or set defaults
    const currentFilters: Record<string, string> = {
      'created-from': searchParams.get('created-from') || startOfYear,
      'created-to': searchParams.get('created-to') || endOfCurrentDay,
      'time-zone': searchParams.get('time-zone') || orgTimeZone
    }

    // Create filter query string
    const filterQueryString = new URLSearchParams(currentFilters).toString()

    // Get store data
    const apiData = store.apiDetailsStore.getApiData()
    const userName = localStorage.getItem('apiUserName')
    const scope = store.scopeStore.getScope()

    if (isUndefined(apiData?.urlTemplate)) return

    // Create API monitoring link
    const apiMonitoringLink = replaceUrl(
      routeConstants.API_MONITORING,
      scope['organization-id'] as string,
      scope['project-id'] as string,
      scope['environment-id'] as string
    )

    const breadcrumbOptions = [
      {
        label:
          tabFromParams === '1' || tabFromUserParam === '1'
            ? ApiGroupingStringConstants.GROUPED_API
            : ApiGroupingStringConstants.ALL_API,
        link: `${apiMonitoringLink}${
          tabFromUserParam === '1'
            ? `?tab=1&contextSwitcherTab=2&${filterQueryString}`
            : tabFromAllUserParam === '2'
              ? `?tab=2&contextSwitcherTab=2&${filterQueryString}`
              : tabFromParams === '1'
                ? `?tab=1&${filterQueryString}`
                : `?tab=2&${filterQueryString}`
        }`
      }
    ]

    // Add user breadcrumb if applicable
    if (tabFromUserParam === '1' && userName) {
      breadcrumbOptions.push({
        label: userName,
        link: `${replaceUrl(
          routeConstants.GROUPED_USER_DETAILS,
          scope['organization-id'] as string,
          scope['project-id'] as string,
          scope['environment-id'] as string
        ).replace(
          ':id',
          apiDetails.apiUserId as string
        )}?tab=1&${filterQueryString}`
      })
    }

    // Add API details breadcrumb
    if (foundOrganization?.subscription?.trackApiUsage === true) {
      if (tabFromParamsOverviewUserTab) {
        if (
          tabFromParamsOverviewUserTab === '1' ||
          tabFromParamsOverviewUserTab === '2'
        ) {
          breadcrumbOptions.push({
            label:
              tabFromParamsOverviewUserTab === '1'
                ? 'API Logs'
                : 'API Overview',
            link: `${replaceUrl(
              routeConstants.GROUPED_USER_DETAILS,
              scope['organization-id'] as string,
              scope['project-id'] as string,
              scope['environment-id'] as string
            ).replace(':id', apiDetails.apiUserId as string)}?tab=${
              tabFromParamsOverviewUserTab === '1' ? '1' : '2'
            }&${filterQueryString}`
          })
        }
      } else {
        if (tabFromUrlParam === '1' || tabFromUrlParam === '2') {
          breadcrumbOptions.push({
            label: tabFromUrlParam === '1' ? 'API Logs' : 'API Overview',
            link: `${replaceUrl(
              routeConstants.VIEW_GROUPED_API,
              scope['organization-id'] as string,
              scope['project-id'] as string,
              scope['environment-id'] as string
            ).replace(
              ':id',
              apiDetails.apiGroupId as string
            )}?tab=${tabFromUrlParam}&${filterQueryString}`
          })
        }
      }
    }

    // Add URL template and overview breadcrumbs
    breadcrumbOptions.push(
      {
        label: apiData?.urlTemplate as string,
        link:
          tabFromUserParam || tabFromAllUserParam
            ? `${replaceUrl(
                routeConstants.GROUPED_USER_API_LOGS_DETAILS,
                scope['organization-id'] as string,
                scope['project-id'] as string,
                scope['environment-id'] as string
              ).replace(
                ':apiGroupId/:apiUserId',
                `${apiDetails.apiGroupId}/${apiDetails.apiUserId}` as string
              )}?tab=1&${filterQueryString}`
            : `${replaceUrl(
                routeConstants.VIEW_GROUPED_API,
                scope['organization-id'] as string,
                scope['project-id'] as string,
                scope['environment-id'] as string
              ).replace(
                ':id',
                apiDetails.apiGroupId as string
              )}?tab=1&${filterQueryString}`
      },
      {
        label: ApiGroupingStringConstants.OVER_VIEW,
        link: '/'
      }
    )

    // Set breadcrumb options in store
    store.breadcrumbStore.setMultipleBreadcrumbsOptions(breadcrumbOptions)

    // Cleanup function
    return () => {
      store.breadcrumbStore.reset()
    }
  }, [
    searchParams,
    store.apiDetailsStore.getApiData(),
    store.breadcrumbStore,
    store.apiDetailsStore.getUserName(),
    store.userStore,
    store.scopeStore
  ])

  const apiData = [
    {
      id: '1',
      heading: ApiGroupingStringConstants.RESPONSE_TIME,
      content: apiDetails.responseTimeInMilliseconds
        ? `${msToTimeUnits(apiDetails.responseTimeInMilliseconds)}`
        : '-'
    },
    {
      id: '2',
      heading: ApiGroupingStringConstants.HTTP_METHOD,
      content: apiDetails.httpMethod ? apiDetails.httpMethod : '-'
    },
    {
      id: '3',
      heading: ApiGroupingStringConstants.START_TIME,
      content: apiDetails.startTime
        ? timeZone(
            store.userStore.getOrganizationPreference().timeZone,
            apiDetails.startTime,
            timeFormats.fullMonthDateYearTimeWithSeconds
          )
        : '-'
    },

    {
      id: '4',
      heading: ApiGroupingStringConstants.END_TIME,
      content: apiDetails.endTime
        ? timeZone(
            store.userStore.getOrganizationPreference().timeZone,
            apiDetails.endTime,
            timeFormats.fullMonthDateYearTimeWithSeconds
          )
        : '-'
    },
    {
      id: '5',
      heading: ApiGroupingStringConstants.STATUS_MESSAGE,
      content: apiDetails.responseStatusMessage
        ? apiDetails.responseStatusMessage
        : '-'
    },
    {
      id: '6',
      heading: ApiGroupingStringConstants.USER_AGENT,
      content: apiDetails.userAgent ? apiDetails.userAgent : '-'
    },
    {
      id: '7',
      heading: ApiGroupingStringConstants.IP,
      content: apiDetails.ip ? apiDetails.ip : '-'
    },
    {
      id: '8',
      heading: ApiGroupingStringConstants.HTTP_VERSION,
      content: apiDetails.httpVersion ? apiDetails.httpVersion : '-'
    }
  ]

  const pathDetails = [
    {
      id: '1',
      heading: ApiGroupingStringConstants.ORIGINAL_URL,
      content: apiDetails.originalUrl
    },
    {
      id: '2',
      heading: ApiGroupingStringConstants.BASE_URL,
      content: apiDetails.baseUrl
    },
    {
      id: '3',
      heading: ApiGroupingStringConstants.PATH,
      content: apiDetails.path
    }
  ]

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className='flex h-full w-full'>
          <div className='py-[24px] w-full'>
            <div className='pb-[16px]'>
              <div className='flex justify-between pb-[12px]'>
                <div>
                  <DDSTooltip
                    id='tooltip-overview'
                    position='top'
                    shape='roundedRectangle'
                    className={`override-tooltip-arrow ${
                      typeof apiDetails.url === 'string' &&
                      apiDetails.url.length > 30
                        ? apiDetails.url
                        : '!hidden'
                    }`}
                    style={{
                      backgroundColor: pallete.colors.surface1,
                      zIndex: 999999
                    }}
                    label={
                      <DDSTypography.Paragraph
                        size='para'
                        variant='regular'
                        color={pallete.colors.textDark4}
                      >
                        {apiDetails.url}
                      </DDSTypography.Paragraph>
                    }
                  >
                    <DDSTypography.Title
                      type='h3'
                      variant='semiBold'
                      className='truncate w-[600px] overflow-hidden '
                      style={{
                        display: 'inline-block'
                      }}
                      color={pallete.colors.textDark3}
                    >
                      {apiDetails.url || apiDetails.url === 0
                        ? apiDetails.url
                        : '-'}
                    </DDSTypography.Title>
                  </DDSTooltip>
                </div>

                <div className='flex justify-end items-center'>
                  <div className='pr-[8px] flex'>
                    <DDSTypography.Title
                      type='h5'
                      variant='semiBold'
                      color={pallete.colors.textDark3}
                    >
                      {ApiGroupingStringConstants.RESPONSE_STATUS}:
                    </DDSTypography.Title>
                  </div>
                  <div className='override-statusCode-center override-status-chip'>
                    <DDSChips
                      label={apiDetails.responseStatusCode}
                      type='filled'
                      shape='rounded'
                      size='small'
                      style={{
                        backgroundColor: mapStatusCodeToColor(
                          apiDetails.responseStatusCode
                        ),
                        borderColor: 'transparent',
                        height: '24px',
                        width: '60px',
                        justifyContent: 'center'
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='flex justify-start items-start'>
                <div className='flex justify-between items-center'>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='medium'
                    color={pallete.colors.textDark6}
                    className='w-[100%] whitespace-nowrap'
                  >
                    {ApiGroupingStringConstants.CLIENT_VERSION}:
                  </DDSTypography.Paragraph>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                    className='ml-[4px] whitespace-nowrap'
                  >
                    {apiDetails.clientVersion || apiDetails.clientVersion === 0
                      ? apiDetails.clientVersion
                      : '-'}
                  </DDSTypography.Paragraph>
                </div>
                <div className='mx-[12px] border-r border-gray-300 h-[20px] '></div>{' '}
                {/* Vertical line */}
                <div className='flex justify-between items-center'>
                  <DDSTypography.Paragraph
                    size='caption'
                    variant='medium'
                    color={pallete.colors.textDark6}
                    className='w-[100%] whitespace-nowrap'
                  >
                    {ApiGroupingStringConstants.URL_TEMPLATE}:
                  </DDSTypography.Paragraph>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                    className='ml-[4px] whitespace-nowrap'
                  >
                    {apiDetails.urlTemplate || apiDetails.urlTemplate === 0
                      ? apiDetails.urlTemplate
                      : '-'}
                  </DDSTypography.Paragraph>
                </div>
              </div>
            </div>
            <div className=''>
              <hr style={{ color: pallete.colors.stroke2 }} />
            </div>
            <div className='pt-[32px]'>
              <div className='grid grid-cols-4 gap-[16px]'>
                {apiData.map((item) => (
                  <div key={item.id}>
                    <APIOverviewCard
                      id={item.id}
                      heading={item.heading}
                      content={item.content}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className='pt-[32px]'>
                <APIOverviewTable tableData={pathDetails} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(ViewOverview)
