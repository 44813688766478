const pallete = {
  colors: {
    // PRIMARY COLORS
    primary: '#001D46',
    primary1: '#003682',
    primary2: '#066CFF',
    black: '#000000',
    white: '#ffffff',
    lightWhite: '#808EA2',
    stroke1: '#066CFF',
    stroke2: '#D9E0EA',
    stroke3: '#ECEFF5',
    stroke4: '#97A3B4',
    stroke5: '#E1E6EE',
    stroke6: '#D9D9D9',
    stroke7: '#DADADA',
    darkGrey: '#595959',
    darkBlue: ' #001737',
    darkPrimary: '#001635',

    // TEXT COLORS
    textDark1: '#FFFFFF',
    textDark2: '#066CFF',
    textDark3: '#222222',
    textDark4: '#414141',
    textDark5: '#586679',
    textDark6: '#808EA2',
    textDark7: '#BAC2CD',
    textDark8: '#B2B4B5',
    textDark9: '#B9B9B9',
    textDark10: '#808080',
    //SURFACE COLORS
    surface1: '#FFFFFF',
    surface2: '#003682',
    surface3: '#001D46',
    surface4: '#EDF5FF',
    surface5: '#F2F5F8',
    surface6: '#F5F7F9',
    surface7: '#FFFFFF',
    surface8: '#FFFFFF',
    surface9: '#CDE2FF',
    // BUTTON COLORS
    buttonOutlineGrey1: '#B7C3D5',

    outButton: '#066CFF',
    outButtonDis: '#ACCEFF',
    outButtonHover: '#EDF5FF',
    refreshButton: '#B7C3D5',

    FillButton: '#066CFF',
    FillButtonDis: '#ACCEFF',
    fillButtonhover: '#003682',

    neuButtonStroke: '#B7C3D5',
    neuButtonText: '#5D6473',
    neuButtonStokeDis: '#CBD1D9',
    neuButtontextDis: '#CBD1D9',
    neuButtonHover: '#F5F7F9',

    textButtonDis: '#CBD1D9',
    textButtonText: '#5D6473',

    dangerButton: '#D71414',
    dangerButtonDis: '#FFC2C2',
    dangerButtonHover: '#FFEEEE',

    warningRed1: '#B20707',
    warningRed2: '#D71414',
    warningRed3: '#FF8585',
    warningRed5: '#FFEEEE',

    red200: '#BD1212',
    yellow200: '#FFA800',
    green200: '#4CA31A',

    warningBlue: '#5EA1FF',

    majorSeverity: '#FF9900',
    criticalSeverity: '#B20707',
    minorSeverity: '#BAC2CD',
    moderateSeverity: '#5EA1FF',

    titleColor: '#595959',
    warningOrange: '#FF9900',
    warningLightOrange: '#FFF3E3',
    warningGreen: '#56B320',
    warningGreenBg: '#EAF6E4',
    warningLightGreen: '#EAF6E4',
    warningBgOrange: '#FFF3E3',

    //table
    tableBorder: '#eeeeee',

    //toast colors
    toastGreen: '#EAF6E4',
    toastRed: '#FFEEEE',
    toastWarning: '#FFF3E3',
    toastNormal: '#EDF5FF',
    alert: '#88570E',

    yellow: '#FFA800'
  }
}
export default pallete
