import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import SidebarLayout, { SidebarRef } from '../Sidenavbar/SidebarLayout'
import {
  findOrganization,
  getOrganizationProjectsThingsToDo
} from '../../../api/organization/Organization.service'
import {
  fullStackTourSteps,
  mulesoftTourSteps,
  salesforceTourSteps
} from './LayoutConstant/LayoutConstants'
import { isEmpty, isNull, isUndefined } from 'lodash'
import pallete, { default as palette } from '../../../global/newPallete'

import { AccessRoleConstants } from '../../../constants/AccessRoleConstants'
import { Alert } from '@mui/material'
import BottomSection from '../Sidenavbar/BottomSection'
import Breadcrumbs from '../../common/DDS/Breadcrumbs/Breadcrumbs'
import { Button } from '../Button/Button'
import CircularProgress from '../Sidenavbar/CircularProgressBar'
import { DDSTypography } from 'den-design-system'
import HttpConstants from '../../../constants/HttpConstants'
import OrganizationSection from '../Sidenavbar/OrganizationSection'
import { OrganizationUserRoles } from '../../../enum/OrganizationUserRoles.enum'
import { ProjectType } from '../../../enum/ProjectType.enum'
import SecureComponent from '../../Layout/SecureComponent'
import Spinner from '../../common/Spinner/Spinner'
import StringConstants from '../../../constants/StringConstants'
import ToastNotification from '../../common/DDS/Toast/Toast'
import TopBarProfileSection from '../TopBar/TobBarProfileSection'
import TopBarSelectSection from '../TopBar/TopBarSelectSection'
import Tour from 'reactour'
import { addQueryParamsToUrl } from '../../../utils/UrlGenerator'
import { isAuthenticatedUser } from '../../../utils/AuthUtils'
import { observer } from 'mobx-react-lite'
import packageJson from '../../../../package.json'
import { replaceUrl } from '../../../utils/UrlUtils'
import routeConstants from '../../../constants/RouteConstants'
import { sandboxUserEmailCapture } from '../../../api/sandbox/sandbox.service'
import styled from '@emotion/styled'
import { useStoreContext } from '../../../store/StoreContext'
import vigilIcon from '../../../assets/icons/bottomVigilLogo.svg'

const getBackgroundColor = (severity: any) => {
  if (severity === 'warning') {
    return '#FDF2DC'
  } else if (severity === 'error') {
    return '#F3D8D8'
  } else {
    return 'inherit'
  }
}

const StyledAlert = styled(Alert)<{ severity?: string }>`
  background-color: ${(props) => getBackgroundColor(props.severity)};
  font-weight: 600;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 16px;
`

enum BannerDetailsSeverity {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

type BannerDetails = {
  title: string
  message: string
  severity: BannerDetailsSeverity
}

const Layout = () => {
  const store = useStoreContext()

  const [isAlertVisible, setAlertVisibility] = useState(true)
  const [bannerDetailsValue, setBannerDetailsValue] = useState<BannerDetails>()
  const organizations = store.userStore.getOrganizations()
  const { uiStore, userStore } = useStoreContext()
  const [isTourOpen, setIsTourOpen] = useState(false)
  const sidebarRef = useRef<SidebarRef>(null)
  const [loading, setLoading] = useState(false)
  const [showBottomBar, setShowBottomBar] = useState<boolean>(true)
  const isDemo = window.location.host.includes('demo.vigilnow.com')
  const navigate = useNavigate()
  const location = useLocation()
  const isAddCheckRoute = location.pathname.includes('add-check')
  const isUpdateCheckRoute = location.pathname.includes('update-check')
  const shouldShowSideNavbar = store.userStore.getOrganizations().length > 0
  const isNoProject =
    userStore.getSelectedOrganization().projectCount === 0 ||
    window.location.pathname.includes('noProject')
  const handleClose = () => {
    setAlertVisibility(false)
    store.uiStore.setIsAlertVisible(false)
    localStorage.setItem(
      StringConstants.LOCAL_STORAGE.BANNER_CLOSED_FLAG,
      'true'
    )
  }

  const renderBannerMessage = (message: string): React.ReactNode => {
    const contactLink = (
      <a href={`mailto:${StringConstants.SUPPORT_EMAIL}`}>Vigil Support</a>
    )
    return message.includes('${Contact}') ? (
      <>
        {message.split('${Contact}')[0]}
        {contactLink}
        {message.split('${Contact}')[1]}
      </>
    ) : (
      message
    )
  }

  useEffect(() => {
    store.uiStore.setGlobalLoader(true)
    findOrganization()
      .then((organizationData: any) => {
        const storedTimeZone = organizationData?.data
        store.userStore.setOrganizationPreference(storedTimeZone)
        // Mark as updated only if it's a non-default value
      })
      .catch((error) => {
        ToastNotification({
          type: 'error',
          message: error || 'Unable to fetch organization data'
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
      })
  }, [])

  React.useEffect(() => {
    if (isAuthenticatedUser()) {
      if (
        window.location.pathname === routeConstants.INVITES ||
        window.location.pathname === routeConstants.NOTIFICATIONS
      ) {
        uiStore.setNotificationSideNavbar(true)
      }

      uiStore.setSidebarExpanded(true)
    } else navigate(routeConstants.SIGN_IN)
    // }, [isAuthenticatedUser()])
  }, [])

  React.useEffect(() => {
    setAlertVisibility(store.uiStore.getIsAlertVisible())
  }, [store.uiStore.getIsAlertVisible()])
  React.useEffect(() => {
    const isBannerClosed =
      localStorage.getItem(StringConstants.LOCAL_STORAGE.BANNER_CLOSED_FLAG) ===
      'true'

    // If the flag is present, don't show the banner
    if (isBannerClosed) {
      setAlertVisibility(false)
      store.uiStore.setIsAlertVisible(false)
    }
    store.uiStore.setGlobalLoader(true)
    const organizations = store.userStore.getOrganizations()
    const foundOrganization = organizations.find(
      (organization: any) =>
        organization.id === store.userStore.getSelectedOrganization().id
    )
    let bannerDetails_: BannerDetails | undefined
    if (foundOrganization) {
      bannerDetails_ = foundOrganization.bannerDetails
    }
    if (!isUndefined(bannerDetails_) && !isNull(bannerDetails_.message)) {
      {
        setAlertVisibility(true)
        store.uiStore.setIsAlertVisible(true)
        setBannerDetailsValue(bannerDetails_)
        store.uiStore.setBannerDetails(bannerDetails_)
      }
    }
  }, [store.userStore.getSelectedOrganization().id])

  const bannerDetails = { ...store.uiStore.getBannerDetails() }

  useEffect(() => {
    if (
      localStorage.getItem('tour_disabled') &&
      localStorage.getItem('tour_disabled') !== 'true'
    )
      setIsTourOpen(true)
  }, [])

  React.useEffect(() => {
    const isBannerClosed =
      localStorage.getItem(StringConstants.LOCAL_STORAGE.BANNER_CLOSED_FLAG) ===
      'true'

    // If the flag is present, don't show the banner
    if (isBannerClosed) {
      setAlertVisibility(false)
    }
    store.uiStore.setGlobalLoader(true)
    const organizations = store.userStore.getOrganizations()
    const foundOrganization = organizations.find(
      (organization: any) =>
        organization.id === store.scopeStore.getOrganizationId()
    )
    let bannerDetails_: BannerDetails | undefined
    if (foundOrganization) {
      bannerDetails_ = foundOrganization.bannerDetails
    }
    if (!isUndefined(bannerDetails_) && !isNull(bannerDetails_.message)) {
      setBannerDetailsValue(bannerDetails_)
      store.uiStore.setBannerDetails(bannerDetails_)
    }
  }, [])

  useEffect(() => {
    if (store.scopeStore.getScope()['organization-id']) {
      const organizationId = store.scopeStore.getScope()['organization-id']
      const organization = organizations.filter(
        (record: any) => record.id === organizationId
      )
      if (organization.length > 0) {
        store.userStore.setSelectedOrganization(organization[0])
        if (!organization[0].subscription) {
          store.userStore.setNoActiveSubscription(true)
        } else {
          store.userStore.setNoActiveSubscription(false)
        }
      }
    }
    // TODO - remove once we start page integration
    store.uiStore.setGlobalLoader(true)
  }, [store.scopeStore.getOrganizationId()])

  useEffect(() => {
    if (store.scopeStore.getProjectId()?.length) {
      handleThingsToDo(store.scopeStore.getSelectedProject())
    }
  }, [])
  let organizationId = ''
  if (store.scopeStore.getScope()['organization-id']) {
    organizationId = store.scopeStore.getScope()['organization-id'] as string
  }
  const projectId = store.scopeStore.getScope()['project-id'] as string
  const environmentId = store.scopeStore.getScope()['environment-id'] as string
  const navigateToOverview = () => {
    if (window.location.href.includes('things-to-do')) {
      navigate(
        replaceUrl(
          routeConstants.OVERVIEW,
          store.scopeStore.getScope()['organization-id'] as string,
          store.scopeStore.getScope()['project-id'] as string,
          store.scopeStore.getScope()['environment-id'] as string
        )
      )
      localStorage.setItem(
        StringConstants.ACTIVE_TAB,
        StringConstants.TAB_VALUES.OVERVIEW
      )
    }
  }
  const handleThingsToDo = (project: any) => {
    if (
      project &&
      (project.type == ProjectType.SALESFORCE_ONLY ||
        project.type == ProjectType.MULESOFT_ONLY) &&
      Number(project.thingsToDo) ==
        StringConstants.THINGS_TO_DO.SALESFORCE_ECOSYSTEM_PROJECT_PROGRESS_VALUE
    ) {
      store.thingsToDoStore.setShowThingsToDo(false)
      navigateToOverview()
    } else if (
      project &&
      project.type == ProjectType.FULL_STACK_ONLY &&
      Number(project.thingsToDo) ==
        StringConstants.THINGS_TO_DO.FULLSTACK_PROJECT_PROGRESS_VALUE
    ) {
      store.thingsToDoStore.setShowThingsToDo(false)
      navigateToOverview()
    } else if (
      store.userStore.getUserRole() === OrganizationUserRoles.BILLING
    ) {
      store.thingsToDoStore.setShowThingsToDo(false)
    } else {
      if (
        store.scopeStore.getScope()['organization-id']?.length &&
        store.scopeStore.getScope()['project-id']?.length
      ) {
        getOrganizationProjectsThingsToDo()
          .then((response: any) => {
            store.thingsToDoStore.setProgressData(response.data)
            if (
              (response?.data?.progress == 3 &&
                (project.type == ProjectType.SALESFORCE_ONLY ||
                  project.type == ProjectType.MULESOFT_ONLY)) ||
              (response?.data?.progress == 5 &&
                project.type == ProjectType.FULL_STACK_ONLY)
            ) {
              store.thingsToDoStore.setShowThingsToDo(false)
              navigateToOverview()
            } else {
              store.thingsToDoStore.setShowThingsToDo(true)
            }
          })
          .catch((error) => console.log(error))
          .finally(() => {
            store.uiStore.setGlobalLoader(false)
          })
      }
      if (
        project &&
        (project.type == ProjectType.SALESFORCE_ONLY ||
          project.type == ProjectType.MULESOFT_ONLY)
      ) {
        store.thingsToDoStore.setTotalProgressCount(3)
      } else {
        store.thingsToDoStore.setTotalProgressCount(5)
      }
    }
  }
  const closeTour = () => {
    setIsTourOpen(false)
    localStorage.setItem('tour_disabled', 'true')
  }
  //Breadcrumbs
  const showBreadCrumbs = () => {
    const sidebarItem = store.uiStore.getSidebarItem()
    const accountSetting = store.uiStore.getSidebarItem()?.title as string

    if (
      sidebarItem.title ===
        StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.OVERVIEW ||
      sidebarItem.title ===
        StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.GET_STARTED ||
      sidebarItem.title ===
        StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.NOTIFICATIONS ||
      sidebarItem.title ===
        StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.INVITATIONS ||
      accountSetting == 'Profile'
    ) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    const sidebarItem = store.uiStore.getSidebarItem()
    const accountSetting = store.uiStore.getSidebarItem()?.title as string

    if (
      !(
        sidebarItem.title ===
          StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.OVERVIEW ||
        sidebarItem.title ===
          StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.SETTINGS ||
        sidebarItem.title ===
          StringConstants.SIDE_NAV_BAR.FULL_STACK_PROJECTS.GET_STARTED ||
        accountSetting == 'Profile'
      ) &&
      isEmpty(store.breadcrumbStore.getBreadcrumbsOptions())
    ) {
      const flag = store.breadcrumbStore.breadcrumbsOptions.find(
        (option: any) => option.label === sidebarItem?.title
      )
      if (!flag) {
        store.breadcrumbStore.setBreadcrumbsOptions({
          label: sidebarItem.title as string,
          link: sidebarItem.link as string
        })
      }
    }
  }, [store.uiStore.getSidebarItem()])
  const [currentStepFullStack, setCurrentStepFullStack] = useState(0)
  const [currentStepSalesforce, setCurrentStepSalesForce] = useState(0)
  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY
  const isSalesforceProject = projectType === ProjectType.SALESFORCE_ONLY
  const handleCurrentActionFullStack = () => {
    setCurrentStepFullStack(
      (prevStep: any) => (prevStep + 1) % fullStackTourSteps.length
    )
  }
  const handlePreviousActionFullStack = () => {
    setCurrentStepFullStack(
      (prevStep: any) => (prevStep - 1) % fullStackTourSteps.length
    )
  }
  const handleCurrentActionSaleForce = () => {
    setCurrentStepSalesForce(
      (prevStep: any) => (prevStep + 1) % fullStackTourSteps.length
    )
  }
  const handlePreviousActionSalesForce = () => {
    setCurrentStepSalesForce(
      (prevStep: any) => (prevStep - 1) % fullStackTourSteps.length
    )
  }
  const toggleSidebar = () => {
    const currentExpandedState = store.uiStore.getSidebarExpanded()
    store.uiStore.setSidebarExpanded(!currentExpandedState)
  }
  function handleResize() {
    if (window.innerWidth < 1100 && window.innerWidth > 450) {
      store.uiStore.setSidebarExpanded(false)
    }
  }
  window.addEventListener('resize', handleResize)
  const switchRole = () => {
    sandboxUserEmailCapture({
      email: localStorage.getItem('sandboxuser_email') || '',
      role:
        localStorage.getItem('sandboxuser_role') == OrganizationUserRoles.MEMBER
          ? OrganizationUserRoles.OWNER
          : OrganizationUserRoles.MEMBER,
      projectType: localStorage.getItem('project_type') || ''
    })
      .then((response: any) => {
        const url = addQueryParamsToUrl(
          routeConstants.REDIRECT,
          {
            token: response.data.accessToken
          },
          HttpConstants.GET_METHOD
        )
        localStorage.setItem(
          'sandboxuser_role',
          localStorage.getItem('sandboxuser_role') ==
            OrganizationUserRoles.MEMBER
            ? OrganizationUserRoles.OWNER
            : OrganizationUserRoles.MEMBER
        )
        navigate(url)
      })
      .catch((err) => {
        console.log('err-----', err)
      })
  }

  return (
    <div id='main-layout' className='flex flex-col relative h-screen w-full'>
      {/* Sidebar */}
      {shouldShowSideNavbar && (
        <div
          id='side-nav-bar'
          className={`flex-shrink-0 ${
            store.uiStore.getSidebarExpanded() ? 'w-[260px]' : 'w-[76px]'
          } h-full z-[99999]`}
          style={{
            backgroundColor: palette.colors.primary,
            position: 'fixed',
            top: 0
          }}
        >
          <div
            className='flex flex-col h-full pt-[12px]'
            style={{ justifyContent: 'space-between' }}
          >
            <div className='flex flex-col h-full '>
              <OrganizationSection
                isSidebarExpanded={store.uiStore.getSidebarExpanded()}
                key={store.userStore.getSelectedOrganization()}
              />
              {/* Render sidebar tabs */}
              <hr
                className='w-full border border-solid border-t-[1px] border-b-[0px] border-l-[0px] border-r-[0px]'
                style={{ borderColor: palette.colors.surface2 }}
              />

              <div className='flex flex-col h-full pt-[16px] justify-between'>
                <div
                  className={`flex flex-col  overflow-x-hidden overflow-y-auto ${
                    store.uiStore.getMenuExpanded()
                      ? '2xl:h-[100px]'
                      : '2xl:h-[100vh]'
                  } ${isNoProject && 'pointer-events-none opacity-50'}`}
                  style={{
                    height: store.uiStore.getMenuExpanded()
                      ? 'calc(100vh - 470px) '
                      : '',
                    cursor: store.uiStore.getGlobalLoader()
                      ? 'not-allowed'
                      : 'pointer'
                  }}
                >
                  {!loading && shouldShowSideNavbar && (
                    <SidebarLayout
                      ref={sidebarRef}
                      isSidebarExpanded={store.uiStore.getSidebarExpanded()}
                      palette={palette}
                      organizations={store.userStore.getOrganizations()}
                      projectType={store.scopeStore.getSelectedProject().type}
                    />
                  )}
                </div>
                <div>
                  <hr
                    className='w-full border border-solid border-t-[1px] bottom-0 border-b-[0px] border-l-[0px] border-r-[0px]'
                    style={{ borderColor: palette.colors.surface2 }}
                  />
                  {/* //TODO uncomment the below lines when we need circular progress bar - ThingToDoSection */}
                  <div
                    className={` pt-[12px] pb-[16px] ${
                      store.uiStore.getMenuExpanded()
                        ? 'h-[25vh] 2xl:h-[50vh] '
                        : 'h-[25vh] 2xl:h-[35vh]'
                    } ${
                      store.uiStore.getMenuExpanded()
                        ? 'lg:justify-center items-center 2xl:align-top top-0 '
                        : 'lg:justify-center items-center 2xl:align-top top-0'
                    }`}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${vigilIcon})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: store.uiStore.getMenuExpanded()
                          ? 'calc(100vh - 660px)'
                          : 'calc(100vh - 660px)'
                      }}
                    >
                      {!isDemo && (
                        <CircularProgress
                          isSidebarExpanded={store.uiStore.getSidebarExpanded()}
                          toggleSidebar={toggleSidebar}
                          progressValue={
                            store.thingsToDoStore.getProgressData().progress
                          }
                          percentageFilled={
                            store.thingsToDoStore.getProgressData().progress /
                            store.thingsToDoStore.getTotalProgressCount()
                          }
                        />
                      )}

                      <div className='mt-[8px] mb-[8px]'>
                        <BottomSection
                          isSidebarExpanded={store.uiStore.getSidebarExpanded()}
                          toggleSidebar={toggleSidebar}
                          key={store.userStore.getSelectedOrganization()}
                        />
                      </div>

                      <div
                        className={`absolute bottom-0 left-0 pb-[16px] right-0 text-white text-center select-none ${
                          store.uiStore.getSidebarExpanded() &&
                          'py-[8px] px-[24px]'
                        }`}
                      >
                        <DDSTypography.Paragraph
                          size='caption'
                          variant='medium'
                          className='whitespace-nowrap select-none'
                          color={palette.colors.textDark1}
                        >
                          {store.uiStore.getSidebarExpanded()
                            ? `Version ${packageJson.version}`
                            : `V ${packageJson.version}`}
                        </DDSTypography.Paragraph>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Content Container */}
      <div
        className={`flex flex-col flex-1 bg-white overflow-y-auto ${
          shouldShowSideNavbar
            ? store.uiStore.getSidebarExpanded()
              ? 'ml-[260px]'
              : 'ml-[76px]'
            : ''
        } `}
      >
        {/* Top Bar */}
        {window.location.host.includes('demo.vigilnow.com') && (
          <div
            className='flex flex-row justify-center p-[8px_44px] items-center gap-[44px] w-full'
            style={{ backgroundColor: pallete.colors.surface3 }}
          >
            <DDSTypography.Paragraph
              size='caption'
              variant='medium'
              className='text-center'
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
              color={pallete.colors.white}
            >
              {`To switch to ${
                localStorage.getItem('sandboxuser_role') ==
                OrganizationUserRoles.MEMBER
                  ? 'Owner'
                  : 'Member'
              } role,`}
              <p
                onClick={switchRole}
                style={{
                  marginLeft: '4px',
                  textDecoration: 'underline',
                  fontSize: 12,
                  fontWeight: 700,
                  cursor: 'pointer'
                }}
              >
                {' '}
                Click Here!
              </p>
            </DDSTypography.Paragraph>
          </div>
        )}

        {isAlertVisible &&
          bannerDetailsValue &&
          bannerDetailsValue.title &&
          !isDemo && (
            <StyledAlert
              onClose={handleClose}
              severity={bannerDetailsValue?.severity}
            >
              <span style={{ fontWeight: 'bold', marginRight: '8px' }}>
                {bannerDetails?.title}
              </span>
              {renderBannerMessage(bannerDetailsValue?.message)}
            </StyledAlert>
          )}
        {isAlertVisible &&
          bannerDetailsValue &&
          bannerDetailsValue.message &&
          !isDemo && (
            <StyledAlert
              onClose={handleClose}
              severity={bannerDetailsValue?.severity}
            >
              {renderBannerMessage(bannerDetailsValue?.message)}
            </StyledAlert>
          )}

        <div
          className={`flex row ${
            isNoProject ? 'justify-end' : 'justify-between'
          } h-16 z-100 px-[32px] py-[16px]`}
          style={{
            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
            borderBottom:
              store.userStore.getSelectedOrganization().projectCount !== 0 &&
              localStorage.getItem(StringConstants.ENVIRONMENT_TYPE) ==
                StringConstants.ENVIRONMENT_TYPE_VALUES.NON_PROD &&
              localStorage.getItem(StringConstants.ACTIVE_TAB) !==
                StringConstants.TAB_VALUES.SETTINGS &&
              localStorage.getItem(StringConstants.ACTIVE_TAB) !==
                StringConstants.TAB_VALUES.GETTING_STARTED
                ? '4px solid var(--Warning-Orange, #F90)'
                : '4px solid transparent'
          }}
        >
          <TopBarSelectSection
            isSidebarExpanded={store.uiStore.getSidebarExpanded()}
            icon={store.uiStore.getSidebarItem()?.topIcon}
            title={store.uiStore.getSidebarItem()?.title as string}
          />
          <TopBarProfileSection />
        </div>
        {!showBreadCrumbs() && (
          <div className='px-[32px] pt-[16px] pb-[4px]'>
            <Breadcrumbs
              disabled={store.uiStore.getGlobalLoader()}
              breadcrumbsOption={store.breadcrumbStore.getBreadcrumbsOptions()}
              onClick={(link: string, e: any) => {
                let isLastIndex = false
                store.breadcrumbStore
                  .getBreadcrumbsOptions()
                  .forEach((value, index) => {
                    if (value.label === e.target.textContent) {
                      if (
                        index ===
                        store.breadcrumbStore.getBreadcrumbsOptions().length - 1
                      ) {
                        isLastIndex = true
                      } else
                        store.breadcrumbStore.sliceBreadcrumbOptions(0, index)
                      return
                    }
                  })
                if (!isLastIndex) {
                  navigate(
                    replaceUrl(link, organizationId, projectId, environmentId)
                  )
                }
              }}
            />
          </div>
        )}
        {/* Main Content */}
        {store.uiStore.getprojectEnvironmentLoader() ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <div
            className={`flex-1 h-[100%] ${
              !isAddCheckRoute && !isUpdateCheckRoute && 'px-[32px]'
            } overflow-y-auto`}
          >
            <Outlet />
          </div>
        )}
        {!store.uiStore.getprojectEnvironmentLoader() && (
          <div>
            {isFullStackProject ? (
              <Tour
                steps={fullStackTourSteps}
                isOpen={isTourOpen}
                onRequestClose={closeTour}
                rounded={10}
                showNumber={false}
                getCurrentStep={(step) => setCurrentStepFullStack(step)}
                goToStep={currentStepFullStack}
                nextStep={() => {
                  handleCurrentActionFullStack()
                }}
                lastStepNextButton={
                  <Button
                    label='Done'
                    type='filled'
                    size='medium'
                    id='end-tour-button'
                  />
                }
                closeWithMask={false}
                prevStep={() => {
                  handlePreviousActionFullStack()
                }}
                disableDotsNavigation={true}
                disableKeyboardNavigation={true}
                showButtons={!store.uiStore.getprojectEnvironmentLoader()}
              />
            ) : isSalesforceProject ? (
              <Tour
                steps={salesforceTourSteps}
                isOpen={isTourOpen}
                onRequestClose={closeTour}
                rounded={10}
                showNumber={false}
                getCurrentStep={(step) => setCurrentStepSalesForce(step)}
                goToStep={currentStepSalesforce}
                nextStep={() => {
                  handleCurrentActionSaleForce()
                }}
                lastStepNextButton={
                  <Button
                    label='Done'
                    type='filled'
                    size='medium'
                    id='end-tour-button'
                  />
                }
                closeWithMask={false}
                prevStep={() => {
                  handlePreviousActionSalesForce()
                }}
                disableDotsNavigation={true}
                disableKeyboardNavigation={true}
                showButtons={!store.uiStore.getprojectEnvironmentLoader()}
              />
            ) : (
              <Tour
                steps={mulesoftTourSteps}
                isOpen={isTourOpen}
                onRequestClose={closeTour}
                rounded={10}
                showNumber={false}
                getCurrentStep={(step) => setCurrentStepSalesForce(step)}
                goToStep={currentStepSalesforce}
                nextStep={() => {
                  handleCurrentActionSaleForce()
                }}
                lastStepNextButton={
                  <Button
                    label='Done'
                    type='filled'
                    size='medium'
                    id='end-tour-button'
                  />
                }
                closeWithMask={false}
                prevStep={() => {
                  handlePreviousActionSalesForce()
                }}
                disableDotsNavigation={true}
                disableKeyboardNavigation={true}
                showButtons={!store.uiStore.getprojectEnvironmentLoader()}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export default SecureComponent(observer(Layout), AccessRoleConstants.ALL)
