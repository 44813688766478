import React, { useEffect, useState } from 'react'
import {
  deleteOrganizationMember,
  findAllMembers,
  getRoleFilters
} from '../../../../../../api/organization/Organization.service'
import {
  findUserInvites,
  getUserInviteFilters,
  resendUserInvite,
  revokeUserInvite
} from '../../../../../../api/userInvites/UserInvites.service'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../../../../utils/UrlUtils'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button } from '../../../../../../components/New/Button/Button'
import ContextSwitcherTabs from '../../../../../../components/common/DDS/ContextSwitcherTab/ContextSwitcherTab'
import { DDSTypography } from 'den-design-system'
import ExistingMember from './ExistingMember'
import Filter from '../../../../../../components/Filter/Filter'
import InviteOrganizationMemberPopup from './Components/InviteOrganizationMemberPopup'
import InvitedMember from './InvitedMember'
import NoFilterImage from '../../../../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../../../../assets/images/folder-not-found 1.svg'
import { OrganizationUserRoles } from '../../../../../../enum/OrganizationUserRoles.enum'
import { SettingAddPopup } from '../../../SettingPopups/SettingAddPopup'
import { SettingDeletePopup } from '../../../SettingPopups/SettingDeletePopup'
import Spinner from '../../../../../../components/common/Spinner/Spinner'
import StringConstants from '../../../../../../constants/StringConstants'
import { ToastMessageConstants } from '../../../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../../../components/common/DDS/Toast/Toast'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../../../global/newPallete'
import routeConstants from '../../../../../../constants/RouteConstants'
import { useStoreContext } from '../../../../../../store/StoreContext'

const MemberOrganizationSetting = () => {
  const [tabIndex, setTabIndex] = useState<string>('1')
  const [searchParams, setSearchParams] = useSearchParams()
  const [invitePopupOpen, setInvitePopupOpen] = React.useState<boolean>(false)
  const mainTab = searchParams.get('tab')
  const [filterCache, setFilterCache] = useState<{ [key: string]: any[] }>({})
  const [deleteExistingMemberPopupOpen, setDeleteExistingMemberPopupOpen] =
    React.useState<boolean>(false)
  const [resendInvitePopupOpen, setResendInvitePopupOpen] =
    React.useState<boolean>(false)
  const [filterLoading, setFilterLoading] = useState<boolean>(false)

  const [revokeInvitePopup, setRevokeInvitePopup] =
    React.useState<boolean>(false)
  const [activeRemoveMember, setActiveRemoveMember] = useState<any>(null)

  const [totalCount, setTotalCount] = useState<number>()
  const [loading, setLoading] = useState(false)
  const [dataTabMap, setDataTabMap] = useState<any>([])
  const pageSize = StringConstants.DEFAULT_TABLE_SIZE
  const [updateMembersRole, setUpdateMembersRole] = useState(false)

  const [invitedUser, setInvitedUser] = useState<boolean>(false)
  const [page, setPage] = useState<number>(
    parseInt(searchParams.get('page') as string) || StringConstants.DEFAULT_PAGE
  )
  const store = useStoreContext()
  const [token, setToken] = useState<string>('')
  const [resendInvite, setResendInvite] = useState<boolean>(false)
  const [activeResendInviteMember, setActiveResendInviteMember] = useState<
    any | null
  >(null)

  const handleRemoveMember = (member: any) => {
    setToken(member.token)
    setActiveRemoveMember(member)
    setDeleteExistingMemberPopupOpen(true)
  }

  const handleConfirmRevokeInvite = (member: any) => {
    setToken(member.token)
    setActiveResendInviteMember(member)
    setRevokeInvitePopup(true)
  }
  const handleResendInvite = (member: any) => {
    setActiveResendInviteMember(member)
    handleConfirmResendInvite(member)
  }
  const [options, setOptions] = React.useState<any>([])

  const navigate = useNavigate()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tabFromUrl = queryParams.get('contextSwitcherTab')

    if (tabFromUrl) {
      setTabIndex(tabFromUrl)
    }
  }, [])

  useEffect(() => {
    store.breadcrumbStore.setMultipleBreadcrumbsOptions([
      {
        label: (
          <div
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            className='cursor-not-allowed '
          >
            <div
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
              className='pointer-events-none'
            >
              Manage Organization
            </div>
          </div>
        ),
        link:
          replaceUrl(
            routeConstants.MANAGE_ORGANIZATION,
            store.scopeStore.getScope()['organization-id'] as string
          ) + '?tab=2'
      },
      {
        label: 'Members',
        link:
          replaceUrl(
            routeConstants.MANAGE_ORGANIZATION,
            store.scopeStore.getScope()['organization-id'] as string
          ) + '?tab=2'
      }
    ])
  }, [store.breadcrumbStore.getBreadcrumbsOptions()])

  useEffect(() => {
    setLoading(true)
    store.filterStore.setFilterDisabled(true)
    setSearchParams(
      {
        page: page.toString(),
        tab: mainTab as string,
        contextSwitcherTab: tabIndex.toString()
      },
      { replace: true }
    )
  }, [tabIndex])

  const loadFilters = (key: string, apiCall: () => void) => {
    if (filterCache[key]) {
      setOptions(filterCache[key])
    } else {
      apiCall()
    }
  }

  const getRoleFiltersData = () => {
    setFilterLoading(true)
    store.filterStore.setFilterDisabled(true)

    getRoleFilters()
      .then((resp: any) => {
        setOptions(resp.data)
        setFilterCache((prev) => ({
          ...prev,
          getRoleFiltersData: resp.data
        }))
      })
      .catch((error) => {
        if (error === 'Unauthorized') {
          navigate(routeConstants.UNAUTHORIZED_PAGE)
        }
      })
      .finally(() => {
        setFilterLoading(false)
      })
  }

  const getUserInviteFiltersData = () => {
    setFilterLoading(true)
    store.filterStore.setFilterDisabled(true)

    getUserInviteFilters()
      .then((resp: any) => {
        setOptions(resp.data)
        setFilterCache((prev) => ({
          ...prev,
          getUserInviteFiltersData: resp.data
        }))
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setFilterLoading(false)
      })
  }
  const handleFilterLoadUrl = () =>
    loadFilters('getRoleFiltersData', getRoleFiltersData)
  const handleFilterLoadUser = () =>
    loadFilters('getUserInviteFiltersData', getUserInviteFiltersData)

  useEffect(() => {
    setDataTabMap([])
    const tab = searchParams.get('contextSwitcherTab') ?? '1'
    if (tab === '1' && !loading) {
      fetchExistingMemberRecords()
    } else if (tab === '2' && !loading) {
      fetchInvitedMemberRecords()
    }
  }, [searchParams, store.scopeStore.getScope()])

  const handleConfirmRemove = () => {
    setLoading(true)

    deleteOrganizationMember(activeRemoveMember.user.id)
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.SETTINGS.MEMBERS.DELETE_MEMBERS.SUCCESS
        })

        const isCurrentUser = store.userStore.getId() === activeRemoveMember.id

        if (isCurrentUser) {
          navigate(routeConstants.REDIRECT)
        } else {
          fetchExistingMemberRecords()
        }

        setActiveRemoveMember(null)
        setDeleteExistingMemberPopupOpen(false)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: err
        })

        setActiveRemoveMember(null)
        setDeleteExistingMemberPopupOpen(false)

        fetchExistingMemberRecords()
        setLoading(false)
      })
  }

  const handleRevokeInvite = () => {
    setLoading(true)

    revokeUserInvite({ $token: token as string })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.SETTINGS.MEMBERS.REVOKE_INVITE.SUCCESS
        })

        setActiveRemoveMember(null)
        setRevokeInvitePopup(false)

        fetchInvitedMemberRecords()
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SETTINGS.MEMBERS.REVOKE_INVITE.ERROR
        })

        setActiveRemoveMember(null)
        setRevokeInvitePopup(false)

        fetchInvitedMemberRecords()
        setLoading(false)
      })
  }

  const handleConfirmResendInvite = (activeMember: any) => {
    setLoading(true)
    resendUserInvite({ $id: activeMember?.id })
      .then(() => {
        ToastNotification({
          type: 'success',
          message: ToastMessageConstants.SETTINGS.MEMBERS.RESEND_INVITE.SUCCESS
        })

        fetchInvitedMemberRecords()
        setActiveResendInviteMember(null)
        setResendInvite(false)
        setResendInvitePopupOpen(false)
      })
      .catch(() => {
        ToastNotification({
          type: 'error',
          message: ToastMessageConstants.SETTINGS.MEMBERS.RESEND_INVITE.ERROR
        })

        setResendInvite(false)
        setResendInvitePopupOpen(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updatingMemberRole = (flag: boolean) => {
    setUpdateMembersRole(flag)
  }

  function fetchInvitedMemberRecords() {
    setLoading(true)
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    findUserInvites({
      ...queryParams,
      status: 'PENDING',
      size: StringConstants.DEFAULT_TABLE_SIZE
    })
      .then((invitedMembersData: any) => {
        if (
          invitedMembersData.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        } else {
          store.filterStore.setFilterDisabled(true)
        }
        setDataTabMap(invitedMembersData.data)
        setTotalCount(invitedMembersData.totalCount)
        setInvitedUser(true)
        setLoading(false)
        store.filterStore.setFilters(invitedMembersData.filters)
      })
      .catch((err) => {
        setLoading(false)
        ToastNotification({
          type: 'error',
          message:
            ToastMessageConstants.SETTINGS.MEMBERS.FIND_INVITED_MEMBERS.ERROR
        })
      })
      .finally(() => store.uiStore.setGlobalLoader(false))
  }

  function fetchExistingMemberRecords() {
    setLoading(true)
    const queryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    findAllMembers({
      ...queryParams,
      'expand-user': true,
      size: StringConstants.DEFAULT_TABLE_SIZE
    })
      .then((membersData: any) => {
        if (
          membersData.data.length > 0 ||
          store.filterStore.getFiltersApplied()
        ) {
          store.filterStore.setFilterDisabled(false)
        } else {
          store.filterStore.setFilterDisabled(true)
        }
        setDataTabMap(membersData.data)
        setTotalCount(membersData.totalCount)
        setInvitedUser(false)
        setLoading(false)
        store.filterStore.setFilters(membersData.filters)
        store.filterStore.setFilterDisabled(false)
      })
      .catch((err) => {
        setLoading(false)
        if (err === 'Unauthorized Exception') {
          navigate(routeConstants.UNAUTHORIZED_PAGE)
        } else {
          ToastNotification({
            type: 'error',
            message: ToastMessageConstants.SETTINGS.MEMBERS.FIND_MEMBERS.ERROR
          })
        }
      })
      .finally(() => store.uiStore.setGlobalLoader(false))
  }

  const ContextSwitcherTab = [
    {
      id: '1',
      label: 'Existing Members',
      children: (
        <div>
          {tabIndex === '1' && (loading || store.uiStore.getGlobalLoader()) ? (
            <Spinner />
          ) : totalCount === 0 ? (
            <NoRecord
              style={{ height: '70vh' }}
              imageSrc={
                store.filterStore.getFiltersApplied()
                  ? NoFilterImage
                  : NoRecordImage
              }
              text={
                <>
                  <DDSTypography.Title
                    type='h4'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                  >
                    No members have been invited to this organization at the
                    moment
                  </DDSTypography.Title>
                </>
              }
            />
          ) : (
            <ExistingMember
              invitedUser={invitedUser}
              data={dataTabMap}
              loading={loading}
              handleRemoveMember={handleRemoveMember}
              handleResendInvite={handleResendInvite}
              rowsPerPage={pageSize}
              currentPage={page}
              onPageChange={(page: number) => {
                const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                  location.search
                )
                setSearchParams({ ...allQueryParams, page: page.toString() })
                setPage(page)
              }}
              totalCount={totalCount}
              fetchMembers={fetchExistingMemberRecords}
              updateMembersRole={updatingMemberRole}
            />
          )}
        </div>
      )
    },
    {
      id: '2',
      label: 'Invited Members',
      children: (
        <div>
          {tabIndex === '2' && (loading || store.uiStore.getGlobalLoader()) ? (
            <Spinner />
          ) : totalCount === 0 ? (
            <NoRecord
              style={{ height: '70vh' }}
              imageSrc={
                store.filterStore.getFiltersApplied()
                  ? NoFilterImage
                  : NoRecordImage
              }
              text={
                <>
                  <DDSTypography.Title
                    type='h4'
                    variant='semiBold'
                    color={pallete.colors.textDark3}
                  >
                    No members have been invited to this organization at the
                    moment
                  </DDSTypography.Title>
                </>
              }
            />
          ) : (
            <InvitedMember
              handleConfirmRevokeInvite={handleConfirmRevokeInvite}
              invitedUser={invitedUser}
              data={dataTabMap}
              loading={loading}
              handleRemoveMember={handleRemoveMember}
              handleResendInvite={handleResendInvite}
              rowsPerPage={pageSize}
              currentPage={page}
              onPageChange={(page: number) => {
                const allQueryParams = getAllQueryParamsAsObjectFromUrl(
                  location.search
                )
                setSearchParams({ ...allQueryParams, page: page.toString() })
                setPage(page)
              }}
              totalCount={totalCount}
              fetchMembers={fetchExistingMemberRecords}
              updateMembersRole={updatingMemberRole}
            />
          )}
        </div>
      )
    }
  ]

  return (
    <>
      <div className='pt-[24px]'>
        <div className='flex items-center justify-between w-full'>
          <div className='flex-grow'>
            <ContextSwitcherTabs
              tabOptions={ContextSwitcherTab}
              shape='roundedRectangle'
              tabStyle={{
                border: '1px solid',
                borderColor: pallete.colors.stroke2,
                userSelect: 'none',
                width: '251px'
              }}
              tabTitleClassName='text-center select-none'
              activeTab={tabIndex}
              onChange={(index: any) => {
                setTabIndex(index)
                const queryParams = getAllQueryParamsAsObjectFromUrl(
                  location.search
                )

                setSearchParams({
                  ...queryParams,
                  page: '1',
                  tab: mainTab,
                  contextSwitcherTab: index
                })
              }}
              disabled={
                loading || store.uiStore.getGlobalLoader() || filterLoading
              }
              className='override-view-more-tabs override-height'
            />
          </div>
          <div className='flex items-center space-x-[8px] absolute right-0 top-[72px]'>
            <div className='flex w-full items-center custom-filter'>
              <Filter
                dropdownLabel='Add Filter'
                onLoadOption={
                  tabIndex === '1' ? handleFilterLoadUrl : handleFilterLoadUser
                }
                dropdownLoader={filterLoading}
                options={options}
                disabled={
                  totalCount === 0 ||
                  loading ||
                  store.uiStore.getGlobalLoader() ||
                  store.userStore.getNoActiveSubscription() ||
                  filterLoading
                }
              />
            </div>
            <Button
              id='invite member'
              size='small'
              label='Invite Member'
              onClick={() => setInvitePopupOpen(true)}
              type='filled'
              disabled={
                store.userStore.getNoActiveSubscription() ||
                store.userStore.getUserRole() !== OrganizationUserRoles.OWNER ||
                loading ||
                store.uiStore.getGlobalLoader() ||
                filterLoading
              }
            />
          </div>
        </div>

        <InviteOrganizationMemberPopup
          open={invitePopupOpen}
          setOpen={setInvitePopupOpen}
          loading={store.uiStore.getGlobalLoader()}
        />

        <SettingDeletePopup
          showHeader
          title='Delete Member'
          modalOpen={deleteExistingMemberPopupOpen}
          setModalOpen={setDeleteExistingMemberPopupOpen}
          primaryButtonText='Yes'
          primaryButtonDisabled={loading}
          secondaryButtonDisabled={loading}
          loading={loading}
          secondaryButtonText='No'
          primaryButtonClick={handleConfirmRemove}
          secondaryButtonClick={() => {
            setDeleteExistingMemberPopupOpen(false)
          }}
          showFooter={true}
          content={
            <div className='flex flex-col p-[24px] gap-[24px]'>
              <div>
                <DDSTypography.Title
                  variant='semiBold'
                  type='h4'
                  color={pallete.colors.textDark3}
                >
                  Are you sure you want to delete{' '}
                  <span
                    style={{ color: pallete.colors.textDark3, fontWeight: 700 }}
                  >
                    {activeRemoveMember?.user?.firstName +
                      ' ' +
                      activeRemoveMember?.user?.lastName}
                  </span>{' '}
                  from the organization?
                </DDSTypography.Title>
              </div>
            </div>
          }
        />

        <SettingDeletePopup
          showHeader
          title='Revoke Invite'
          modalOpen={revokeInvitePopup}
          setModalOpen={setResendInvitePopupOpen}
          primaryButtonText='Yes'
          primaryButtonDisabled={loading}
          secondaryButtonDisabled={loading}
          loading={loading}
          secondaryButtonText='No'
          primaryButtonClick={handleRevokeInvite}
          secondaryButtonClick={() => {
            setRevokeInvitePopup(false)
          }}
          showFooter={true}
          content={
            <div className='flex flex-col p-[24px] gap-[24px]'>
              <div>
                <DDSTypography.Title
                  variant='semiBold'
                  type='h4'
                  color={pallete.colors.textDark3}
                >
                  Are you sure you want to revoke invite to
                  <span
                    style={{ color: pallete.colors.textDark3, fontWeight: 700 }}
                  >
                    {''} {activeResendInviteMember?.invitee} {''}
                  </span>
                  ?
                </DDSTypography.Title>
              </div>
            </div>
          }
        />
      </div>
    </>
  )
}

export default observer(MemberOrganizationSetting)
