import {
  Bookmark,
  BookmarkFilled,
  CaretDown,
  CaretUp,
  ChevronRight
} from '@carbon/icons-react'
import { DDSTooltip, DDSTypography } from 'den-design-system'
import {
  ExceptionStatusTypes,
  SeverityTypes
} from '../../../../api/exception/Exception.types'
import React, { useEffect, useRef, useState } from 'react'
import { createSearchParams, useNavigate, useParams } from 'react-router-dom'
import {
  getAllQueryParamsAsObjectFromUrl,
  replaceUrl
} from '../../../../utils/UrlUtils'
import {
  getExceptionFilters,
  getExceptionLogsOfGroup,
  updateAnException,
  updateSelectedExceptions
} from '../../../../api/exception/Exception.service'

import ConfirmationPopup from '../../../../components/common/Exceptions/ConfirmationPopup'
import { Link } from '@mui/material'
import NoFilterImage from '../../../../assets/images/folder-not-found 1.svg'
import NoRecord from '../../../../components/common/NoContent/NoRecord'
import NoRecordImage from '../../../../assets/images/setting.svg'
import { ProjectType } from '../../../../enum/ProjectType.enum'
import Search from '../../../../components/New/Search/Search'
import SeverityDropDown from '../../../../components/common/Exceptions/SeverityDropdown'
import Spinner from '../../../../components/common/Spinner/Spinner'
import StatusDropDown from '../../../../components/common/Exceptions/StatusDropdown'
import StringConstants from '../../../../constants/StringConstants'
import Table from '../../../../components/common/DDS/Table/Table'
import { ToastMessageConstants } from '../../../../constants/ToastMessageConstants'
import ToastNotification from '../../../../components/common/DDS/Toast/Toast'
import Tooltip from '../../../../components/New/Tooltip/Tooltip'
import emptyAvatar from '../../../../assets/images/EmptyAvatar.svg'
import { generateRandomId } from '../../../../utils/CommonUtils'
import { observer } from 'mobx-react-lite'
import pallete from '../../../../global/newPallete'
import routeConstants from '../../../../constants/RouteConstants'
import { severityColorMap } from '../../../../constants/ExceptionsPageConstants'
import { timeFormats } from '../../../../enum/TIME'
import { timeZone } from '../../../../utils/DateTimeUtils'
import { useSearchParams } from 'react-router-dom'
import { useStoreContext } from '../../../../store/StoreContext'

type SortOrder = 'ASC' | 'DESC' | 'CLEAR'
type SortState = null | SortOrder | 'CLEAR'

const GroupedExceptionsLogs: React.FC = () => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [currentGroupId, setCurrentGroupId] = useState<any>('')
  const [preference, setPreference] = React.useState<string>('')
  const [tableData, setTableData] = useState<any>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const tableRef = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const store = useStoreContext()
  const [selectedRows, setSelectedRows] = useState<any>([])
  const { id } = useParams()
  const navigate = useNavigate()
  const [filterCache, setFilterCache] = useState<{ [key: string]: any[] }>({})

  const projectType = store.scopeStore.getSelectedProject().type
  const isFullStackProject = projectType === ProjectType.FULL_STACK_ONLY
  const [filterOptions, setFilterOptions] = useState<any>([])
  const [searchTerm, setSearchTerm] = useState('') // Holds the search term entered by the user
  const [showDropdown, setShowDropdown] = useState(false)
  const isSalesforceProject = projectType === ProjectType.SALESFORCE_ONLY
  const [searchRecordLength, setSearchRecordLength] = useState<number>(0)
  const [activeSortOrder, setActiveSortOrder] = useState<{
    field: string | null
    order: SortOrder | null
  }>({
    field: null,
    order: null
  })
  const rowDisabled = useRef(false)

  const fetchAllExceptionLogs = () => {
    setLoading(true)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)

    const searchParams = new URLSearchParams(location.search)

    // Retrieve sort parameters from URL
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')

    const params: any = {
      exceptionGroupId: id,
      ...allQueryParams,
      size: StringConstants.DEFAULT_TABLE_SIZE
    }

    if (urlSortField && urlSortOrder && urlSortOrder !== 'CLEAR') {
      params[urlSortField] = urlSortOrder
    }
    getExceptionLogsOfGroup(params).then((response: any) => {
      if (response.data.length > 0 || store.filterStore.getFiltersApplied()) {
        store.filterStore.setFilterDisabled(false)
      } else {
        store.filterStore.setFilterDisabled(true)
      }
      setTableData(response.data)
      setTotalCount(response.totalCount)
      if (urlSortField && urlSortOrder) {
        setActiveSortOrder({
          field: urlSortField,
          order: urlSortOrder as SortOrder
        })
      }
      store.exceptionStore.setIsRefreshed(false)
      if (response.filters) {
        {
          store.filterStore.setFilters(response.filters)
        }
      } else {
        store.filterStore.setFilters({})
      }

      setLoading(false)
    })
  }

  const sortData = (sortField: string, sortOrder: SortOrder) => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    store.uiStore.setGlobalLoader(true)
    setLoading(true)

    const params: any = {
      exceptionGroupId: id,
      ...allQueryParams,
      size: StringConstants.DEFAULT_TABLE_SIZE
    }
    Object.keys(params).forEach((key) => {
      if (key.startsWith('sort-')) {
        delete params[key]
      }
    })
    if (sortOrder !== 'CLEAR') {
      params[sortField] = sortOrder
      // Persist sort information in URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('sortField', sortField)
      searchParams.set('sortOrder', sortOrder)
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    } else {
      delete params[sortField]
      // Remove sort params from URL
      const searchParams = new URLSearchParams(location.search)
      searchParams.delete('sortField')
      searchParams.delete('sortOrder')
      window.history.replaceState({}, '', `?${searchParams.toString()}`)
    }
    setSearchParams(params)
    getExceptionLogsOfGroup(params).then((response: any) => {
      if (response.data.length > 0 || store.filterStore.getFiltersApplied()) {
        store.filterStore.setFilterDisabled(false)
      } else {
        store.filterStore.setFilterDisabled(true)
      }
      setTableData(response.data)
      setTotalCount(response.totalCount)
      store.exceptionStore.setIsRefreshed(false)
      if (response.filters) {
        {
          store.filterStore.setFilters(response.filters)
        }
      } else {
        store.filterStore.setFilters({})
      }

      store.uiStore.setGlobalLoader(false)
      setLoading(false)
    })
  }

  const handleSortCycle = (sortField: string) => {
    // Determine the next order synchronously
    let nextOrder: SortOrder = 'ASC'
    if (activeSortOrder.field === sortField) {
      nextOrder =
        activeSortOrder.order === 'ASC'
          ? 'DESC'
          : activeSortOrder.order === 'DESC'
            ? 'CLEAR'
            : 'ASC'
    }

    // Update the sort state and call sortData immediately
    setActiveSortOrder({
      field: nextOrder !== 'CLEAR' ? sortField : null,
      order: nextOrder
    })

    sortData(sortField, nextOrder)
  }

  // Initialize sorting from URL on component mount
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const urlSortField = searchParams.get('sortField')
    const urlSortOrder = searchParams.get('sortOrder')

    if (urlSortField && urlSortOrder) {
      // Initialize the sort state from URL
      setActiveSortOrder({
        field: urlSortField,
        order: urlSortOrder as SortOrder
      })
    }
  }, [location.search])

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.clientHeight)
    }
  }, [tableData, loading])

  const fetchExceptionFilters = () => {
    getExceptionFilters()
      .then((response: any) => {
        const statusFilters = response.data[2]
        statusFilters.values = statusFilters.values.filter(
          (value: any) =>
            value.displayText !== ExceptionStatusTypes.SNOOZED &&
            value.displayText !== ExceptionStatusTypes.IGNORED
        )
        response.data[2] = statusFilters
        setFilterOptions(response.data)
      })
      .catch((err) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.EXCEPTION_FILTERS.ERROR
            : ToastMessageConstants.ERROR.ERROR_FILTERS.ERROR
        })
      })
  }
  useEffect(() => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const searchString = allQueryParams['search-string'] || ''

    if (searchString) {
      setSearchTerm(searchString)
    }
    if (!allQueryParams['search-string']) {
      setSearchTerm('')
    }

    fetchAllExceptionLogs()
    fetchExceptionFilters()
  }, [store.exceptionStore.getIsRefreshed(), currentPage, location.search])

  const routeExceptionLog = (id: number, type: string) => {
    if (!rowDisabled.current) {
      store.exceptionStore.setId(id.toString())
      store.exceptionStore.setType(type)

      const route = replaceUrl(
        routeConstants.ERROR_DETAILS,
        store.scopeStore.getScope()['organization-id'] as string,
        store.scopeStore.getScope()['project-id'] as string,
        store.scopeStore.getScope()['environment-id'] as string
      ).replace(':id', String(id))
      const queryParams = {
        tab: searchParams.get('tab') as string,
        innerTab: searchParams.get('innerTab') as string
      }
      navigate({
        pathname: route,
        search: `?${createSearchParams(queryParams)}`
      })
    }
  }

  const updateSeverity = () => {
    const resolvedRows = selectedRows.filter(
      (row: any) => row.exceptionStatus === 'Resolved'
    )
    if (resolvedRows.length > 0) {
      ToastNotification({
        type: 'error',
        message:
          resolvedRows.length === 1
            ? `Update failed. ${resolvedRows.length} selected record has been resolved.`
            : `Update failed. ${resolvedRows.length} selected records have been resolved.`
      })
      setModalOpen(false)
      return
    }
    if (selectedRows.length === 0) {
      const isSameSeverity =
        tableData.filter((data: any) => data.id === currentGroupId)[0]
          .severity === preference
      setLoading(true)
      if (!isSameSeverity) {
        updateAnException({
          severity: preference as SeverityTypes,
          $exceptionLogId: currentGroupId
        })
          .then(() => {
            fetchAllExceptionLogs()
            ToastNotification({
              type: 'success',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
            })
            setCurrentGroupId('')
            setPreference('')
            setModalOpen(false)
          })
          .catch(() => {
            ToastNotification({
              type: 'error',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
            })
            setCurrentGroupId('')
            setPreference('')
            setModalOpen(false)
            setLoading(false)
          })
      }
    } else {
      const exceptionIds: string[] = []
      selectedRows.forEach((exception: any) => exceptionIds.push(exception.id))
      setLoading(true)

      updateSelectedExceptions({
        ids: exceptionIds,
        severity: preference
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
          })
          setCurrentGroupId('')
          setPreference('')
          setModalOpen(false)
          setSelectedRows([])
          fetchAllExceptionLogs()
        })

        .catch((err: any) => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
          })
          setModalOpen(false)
          setLoading(false)
        })
    }
  }
  const updateStatus = (id: string, status: ExceptionStatusTypes) => {
    if (selectedRows.length === 0) {
      const isSameStatus =
        tableData.filter((data: any) => data.id === id)[0].exceptionStatus ===
        status
      if (!isSameStatus) {
        setLoading(true)

        updateAnException({
          exceptionStatus: status,
          $exceptionLogId: id
        })
          .then(() => {
            ToastNotification({
              type: 'success',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
            })
            fetchAllExceptionLogs()
          })
          .catch(() => {
            ToastNotification({
              type: 'error',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
            })
          })
      }
    } else {
      const exceptionIds: string[] = []

      selectedRows.forEach((exception: any) => exceptionIds.push(exception.id))

      setLoading(true)

      updateSelectedExceptions({
        ids: exceptionIds,
        exceptionStatus: status
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
          })

          fetchAllExceptionLogs()
        })
        .catch(() => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
          })
          setLoading(false)
        })
    }
  }
  const updateBookmark = (id: string, bookmarkState: boolean) => {
    setLoading(true)
    if (selectedRows.length === 0) {
      const isSameBookmarkState =
        tableData.filter((data: any) => data.id === id)[0].isBookmarked ===
        bookmarkState
      if (!isSameBookmarkState) {
        updateAnException({
          isBookmarked: bookmarkState,
          $exceptionLogId: id
        })
          .then(() => {
            ToastNotification({
              type: 'success',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
            })
            fetchAllExceptionLogs()
          })
          .catch(() => {
            ToastNotification({
              type: 'error',
              message: isFullStackProject
                ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
                : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
            })
          })
      }
    } else {
      const exceptionIds: string[] = []
      selectedRows.forEach((exception: any) => exceptionIds.push(exception.id))
      setLoading(true)
      updateSelectedExceptions({
        ids: exceptionIds,
        isBookmarked: bookmarkState
      })
        .then(() => {
          ToastNotification({
            type: 'success',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.SUCCESS
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.SUCCESS
          })

          fetchAllExceptionLogs()
        })
        .catch((err: any) => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.UPDATE_AN_EXCEPTION.ERROR
              : ToastMessageConstants.ERROR.UPDATE_AN_ERROR.ERROR
          })
        })
    }
  }
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, 50)
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    if (value === '' && allQueryParams['search-string']) {
      setSearchTerm('')
      setShowDropdown(false)
      setCurrentPage(currentPage)

      const params: any = {
        ...allQueryParams,
        size: StringConstants.DEFAULT_TABLE_SIZE,
        page: currentPage
      }

      // Only remove search-related parameters
      delete params['search-string']
      delete params['search-type']
      setSearchParams({ ...params, searchTerm: '' })

      // Move loading states before API call
      setLoading(true)
      store.uiStore.setGlobalLoader(true)

      getExceptionLogsOfGroup({ exceptionGroupId: id })
        .then((response: any) => {
          setTableData(response.data)
          setCurrentPage(response.page)
          setTotalCount(response.totalCount)
        })
        .catch((e) => {
          ToastNotification({
            type: 'error',
            message: isFullStackProject
              ? ToastMessageConstants.EXCEPTIONS.GROUP_LOG_SEARCH.ERROR
              : ToastMessageConstants.ERROR.GET_ALL_ERROR.ERROR
          })
        })
        .finally(() => {
          store.uiStore.setGlobalLoader(false)
          setLoading(false)
        })
    }

    const isValidInput = /^[\w\s!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/.test(
      value
    )
    if (!isValidInput) {
      return
    }
    setSearchTerm(value)
    if (value.trim()) {
      setShowDropdown(true)
    }
  }

  const handleOptionSelect = (searchString: string, searchType: string) => {
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    setCurrentPage(1)

    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const params: any = {
      exceptionGroupId: id,
      ...allQueryParams, // This preserves sort parameters if they exist
      size: StringConstants.DEFAULT_TABLE_SIZE,
      'search-string': searchString,
      'search-type': searchType,
      page: 1
    }

    setSearchParams({ ...params, searchTerm: searchTerm })

    getExceptionLogsOfGroup(params)
      .then((response: any) => {
        setTableData(response.data)
        setCurrentPage(response.page)
        setSearchRecordLength(response.totalCount)
        setTotalCount(response.totalCount)
      })
      .catch((e) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.GROUP_LOG_SEARCH.ERROR
            : ToastMessageConstants.ERROR.GET_ALL_ERROR.ERROR
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })

    setShowDropdown(false)
  }

  const handleSearchEnter = (searchValue: string) => {
    if (!searchValue || searchValue.trim() === '') {
      return
    }
    setLoading(true)
    store.uiStore.setGlobalLoader(true)
    setCurrentPage(1)

    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    const params = {
      exceptionGroupId: id,
      ...allQueryParams, // This preserves sort parameters if they exist
      'search-string': searchValue || '',
      'search-type': 'any',
      page: 1,
      size: StringConstants.DEFAULT_TABLE_SIZE
    }

    setSearchParams({ ...params, searchTerm: searchTerm })

    getExceptionLogsOfGroup(params)
      .then((response: any) => {
        setTableData(response.data)
        setSearchRecordLength(response.totalCount)
        setCurrentPage(response.page)
        setTotalCount(response.totalCount)
      })
      .catch((error: any) => {
        ToastNotification({
          type: 'error',
          message: isFullStackProject
            ? ToastMessageConstants.EXCEPTIONS.GROUP_LOG_SEARCH.ERROR
            : ToastMessageConstants.ERROR.GET_ALL_ERROR.ERROR
        })
      })
      .finally(() => {
        store.uiStore.setGlobalLoader(false)
        setLoading(false)
      })

    setShowDropdown(false)
  }

  const LogTableColumns = [
    {
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => {
        const isDropdownDisabled =
          selectedRows.length > 0 &&
          !selectedRows.some(
            (selectedRow: any) => selectedRow.id === fullItem.id
          )
        return (
          <Tooltip
            id={generateRandomId()}
            label={fullItem['isBookmarked'] ? 'Bookmarked' : 'Bookmark'}
          >
            <div
              onClick={() => {
                rowDisabled.current = true
                setTimeout(() => {
                  rowDisabled.current = false
                }, 2000)
                if (!isDropdownDisabled) {
                  updateBookmark(fullItem['id'], !fullItem['isBookmarked'])
                }
              }}
              className={`${isDropdownDisabled} ? 'cursor-none' : 'cursor-pointer'`}
            >
              {fullItem['isBookmarked'] ? (
                <BookmarkFilled color={pallete.colors.primary2} />
              ) : (
                <Bookmark color={pallete.colors.stroke4} />
              )}
            </div>
          </Tooltip>
        )
      }
    },

    {
      heading: (
        <div className='flex whitespace-nowrap space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              variant='semiBold'
              size='caption'
              className='text-start flex whitespace-nowrap'
              color={pallete.colors.textDark6}
            >
              {isFullStackProject ? 'Exception Message' : 'Error Message'}
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-log-exception-message')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-log-exception-message' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-log-exception-message' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-log-exception-message' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-log-exception-message' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'exceptionMessage',
      render: (text: any) => (
        <div className='flex justify-start text-start row items-start max-w-[200px]'>
          <div>
            <Tooltip
              id={`tooltip-name-${text}`}
              label={text}
              className={`override-tooltip-arrow ${
                typeof text === 'string' && text.length > 15 ? text : '!hidden'
              }`}
            >
              <div>
                <DDSTypography.Paragraph
                  size='para'
                  variant='medium'
                  color={pallete.colors.textDark4}
                  className='tooltip-container sm:block overflow-hidden truncate max-w-[200px]'
                >
                  {text || text === 0 ? text : '-'}
                </DDSTypography.Paragraph>
              </div>
            </Tooltip>
          </div>
        </div>
      )
    },
    {
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              variant='semiBold'
              size='caption'
              className='text-center flex whitespace-nowrap'
              color={pallete.colors.textDark6}
            >
              Client Version
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-log-exception-client-version')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field ===
                    'sort-log-exception-client-version' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field ===
                          'sort-log-exception-client-version' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field ===
                    'sort-log-exception-client-version' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field ===
                          'sort-log-exception-client-version' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'clientVersion',
      render: (text: any) => (
        <div className='flex justify-center'>
          <DDSTypography.Paragraph
            variant='medium'
            size='para'
            className='text-center  whitespace-nowrap'
          >
            {text ? text : '-'}
          </DDSTypography.Paragraph>
        </div>
      )
    },
    {
      columnHeaderStyle: { width: '160px' },
      columnDataStyle: { width: '160px' },
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              variant='semiBold'
              size='caption'
              className='text-center flex whitespace-nowrap'
              color={pallete.colors.textDark6}
            >
              Reported at
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-log-reported-time')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-log-reported-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-log-reported-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-log-reported-time' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-log-reported-time' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'reportedAt',
      render: (text: any) => (
        <DDSTooltip
          id={`${text}`}
          label={
            <DDSTypography.Paragraph
              size='para'
              variant='regular'
              color={pallete.colors.textDark4}
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                text,
                timeFormats.fullMonthDateYearTime
              )}
            </DDSTypography.Paragraph>
          }
          shape='roundedRectangle'
          position='top'
          className={`override-tooltip-arrow ${
            typeof text === 'string' && text.length > 24 ? text : '!hidden'
          }`}
          style={{
            backgroundColor: pallete.colors.surface1,
            zIndex: 999999
          }}
        >
          <div className='flex justify-center text-center row items-center'>
            <DDSTypography.Paragraph
              variant='medium'
              size='para'
              className='tooltip-container sm:block overflow-hidden truncate max-w-[160px]'
            >
              {timeZone(
                store.userStore.getUserPreference().timeZone,
                text,
                timeFormats.fullMonthDateYearTime
              )}
            </DDSTypography.Paragraph>
          </div>
        </DDSTooltip>
      )
    },
    {
      heading: (
        <DDSTypography.Paragraph
          variant='semiBold'
          size='caption'
          className='text-center whitespace-nowrap flex justify-center'
          color={pallete.colors.textDark6}
        >
          Assigned To
        </DDSTypography.Paragraph>
      ),
      dataLabel: 'assignedTo',
      render: (text: any, fullItem: any) => (
        <div className='flex flex-row justify-center w-full'>
          {fullItem.assignedTo?.user?.avatar ? (
            <DDSTooltip
              label={
                fullItem.assignedTo?.user?.firstName +
                ' ' +
                fullItem.assignedTo?.user?.lastName
              }
              position='top'
              shape='roundedRectangle'
              id={generateRandomId()}
              theme='light'
              className={`border override-tooltip-arrow `}
              style={{ borderColor: pallete.colors.stroke2, zIndex: 99999 }}
            >
              <div className='flex justify-center w-full'>
                <img
                  src={fullItem.assignedTo?.user?.avatar}
                  className='rounded-full h-[24px] w-[24px]'
                />
              </div>
            </DDSTooltip>
          ) : (
            <img src={emptyAvatar} className='rounded-full h-[24px] w-[24px]' />
          )}
        </div>
      )
    },
    {
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              variant='semiBold'
              size='caption'
              className='text-center flex whitespace-nowrap'
              color={pallete.colors.textDark6}
            >
              Severity
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-log-preferred-severity')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-log-preferred-severity' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-log-preferred-severity' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-log-preferred-severity' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-log-preferred-severity' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'severity',
      render: (text: any, fullItem: any) => {
        const isDropdownDisabled =
          selectedRows.length > 0 &&
          !selectedRows.some(
            (selectedRow: any) => selectedRow.id === fullItem.id
          )
        const setSeverity = (severity: SeverityTypes) => {
          setCurrentGroupId(fullItem['id'])
          setPreference(severity)
          if (selectedRows.length === 0 && fullItem['severity'] !== severity) {
            setModalOpen(true)
          } else if (selectedRows.length > 0) {
            setModalOpen(true)
          }
        }
        return (
          <div className=' flex justify-center items-center '>
            <SeverityDropDown
              label={text}
              onSelect={setSeverity}
              disabled={
                fullItem.exceptionStatus === 'Resolved' || isDropdownDisabled
              }
              disableNoSubscription={store.userStore.getNoActiveSubscription()}
            />
          </div>
        )
      }
    },
    {
      heading: (
        <div className='flex whitespace-nowrap justify-center space-x-[8px] items-center'>
          <div>
            <DDSTypography.Paragraph
              variant='semiBold'
              size='caption'
              className='text-center flex whitespace-nowrap'
              color={pallete.colors.textDark6}
            >
              Status
            </DDSTypography.Paragraph>
          </div>
          <div
            className='flex flex-col justify-center -space-y-[6px]'
            onClick={() => handleSortCycle('sort-log-exception-status')}
          >
            <CaretUp
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-log-exception-status' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.FillButton
                    : activeSortOrder.field === 'sort-log-exception-status' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.outButtonDis
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
            <CaretDown
              size={12}
              style={{
                color:
                  activeSortOrder.field === 'sort-log-exception-status' &&
                  activeSortOrder.order === 'ASC'
                    ? pallete.colors.outButtonDis
                    : activeSortOrder.field === 'sort-log-exception-status' &&
                        activeSortOrder.order === 'DESC'
                      ? pallete.colors.FillButton
                      : pallete.colors.textDark6
              }}
              className='cursor-pointer'
            />
          </div>
        </div>
      ),
      dataLabel: 'exceptionStatus',
      render: (text: any, fullItem: any) => {
        const isDropdownDisabled =
          selectedRows.length > 0 &&
          !selectedRows.some(
            (selectedRow: any) => selectedRow.id === fullItem.id
          )
        const setStatus = (status: ExceptionStatusTypes) => {
          if (!isDropdownDisabled) {
            updateStatus(fullItem['id'], status)
          }
        }
        return (
          <div
            className={`'flex justify-center items-center' ${isDropdownDisabled} ? 'cursor-none' : 'cursor-pointer'`}
          >
            <StatusDropDown
              label={text}
              onSelect={setStatus}
              disabled={isDropdownDisabled}
            />
          </div>
        )
      }
    },
    {
      columnHeaderStyle: { width: '20px' },
      columnDataStyle: { width: '20px' },
      heading: '',
      dataLabel: '',
      render: (text: any, fullItem: any) => {
        const isDropdownDisabled =
          selectedRows.length > 0 &&
          !selectedRows.some(
            (selectedRow: any) => selectedRow.id === fullItem.id
          )
        return (
          <div>
            <ChevronRight
              size={16}
              color={pallete.colors.primary2}
              className={`${isDropdownDisabled} ? 'cursor-none' : 'cursor-pointer'`}
              onClick={() => {
                if (!isDropdownDisabled) {
                  routeExceptionLog(fullItem['id'], fullItem['exceptionType'])
                }
              }}
            />
          </div>
        )
      }
    }
  ]
  if (loading) return <Spinner />

  return (
    <div className='my-[24px]' ref={tableRef}>
      {/* TODO Not needed in this release*/}
      {/* <div className='mb-[24px] flex flex-row custom-filter'>
        <Filter
          options={filterOptions}
          disabled={false}
          primaryText='Add Filters'
        />
      </div> */}
      <div className='relative w-full pb-[12px]'>
        <Search
          id='search'
          name='Search'
          placeholder='Search'
          value={searchTerm}
          onChange={handleSearchChange}
          autoComplete='off'
          width='32vw'
          disabled={loading}
          onPressEnter={() => handleSearchEnter(searchTerm)}
          onBlur={() => {
            setTimeout(() => setShowDropdown(false), 200)
          }}
        />
        {showDropdown && (
          <div
            className='absolute z-[99999] mt-[12px] flex flex-col bg-white border items-start p-[12px] rounded-md w-[32vw] shadow-lg'
            style={{ borderColor: pallete.colors.stroke2 }}
          >
            <div
              className='flex p-[8px] gap-[8px] items-center cursor-pointer'
              onClick={() => handleOptionSelect(searchTerm, 'message')}
            >
              <DDSTooltip
                id={generateRandomId()}
                label={searchTerm}
                className={`border override-tooltip-arrow max-w-[90%] break-all ${
                  typeof searchTerm === 'string' && searchTerm.length > 38
                    ? searchTerm
                    : '!hidden'
                }`}
                theme='light'
                style={{
                  borderColor: pallete.colors.stroke2,
                  zIndex: 99999
                }}
              >
                <div className='flex flex-row  gap-[8px]'>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark4}
                    className='flex-start truncate  w-[250px] cursor-default'
                  >
                    {searchTerm}
                  </DDSTypography.Paragraph>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark6}
                  >
                    in message column
                  </DDSTypography.Paragraph>
                </div>
              </DDSTooltip>
            </div>
            <div
              className='flex p-[8px] gap-[8px] items-center cursor-pointer'
              onClick={() => handleOptionSelect(searchTerm, 'any')}
            >
              <DDSTooltip
                id={generateRandomId()}
                label={searchTerm}
                className={`border override-tooltip-arrow max-w-[90%] break-all ${
                  typeof searchTerm === 'string' && searchTerm.length > 38
                    ? searchTerm
                    : '!hidden'
                }`}
                theme='light'
                style={{
                  borderColor: pallete.colors.stroke2,
                  zIndex: 99999
                }}
              >
                <div className='flex flex-row  gap-[8px]'>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark4}
                    className='flex-start truncate  w-[250px] cursor-default'
                  >
                    {searchTerm}
                  </DDSTypography.Paragraph>
                  <DDSTypography.Paragraph
                    size='para'
                    variant='medium'
                    color={pallete.colors.textDark6}
                  >
                    in all column
                  </DDSTypography.Paragraph>
                </div>
              </DDSTooltip>
            </div>
          </div>
        )}
      </div>
      {tableData.length > 0 ? (
        <Table
          data={tableData}
          columns={LogTableColumns}
          totalCount={totalCount}
          rowsPerPage={10}
          currentPage={currentPage}
          onPageChange={(page) => {
            const allQueryParams = getAllQueryParamsAsObjectFromUrl(
              location.search
            )

            setSearchParams({ ...allQueryParams, page: page.toString() })
            setCurrentPage(page)
            setLoading(true)
          }}
          selectable
          tableStyle={{ overflowX: 'scroll' }}
          //loading={loading}
          selectionType='checkbox'
          onSelect={(row: any) => {
            setSelectedRows(row)
          }}
          clickType='GroupExceptionLog'
          clickable={routeExceptionLog}
        />
      ) : searchRecordLength === 0 && searchTerm != '' ? (
        <NoRecord
          style={{ height: '70vh' }}
          imageSrc={NoFilterImage}
          text='No results found'
        />
      ) : (
        <NoRecord
          imageSrc={
            store.filterStore.getFiltersApplied()
              ? NoFilterImage
              : NoRecordImage
          }
          style={{ height: '74vh' }}
          text={
            store.filterStore.getFiltersApplied() ? (
              StringConstants.NO_RECORDS_FILTER
            ) : (
              <>
                Configure {isFullStackProject ? 'Exception' : 'Error'}
                {''} Monitoring.
                <Link
                  href={
                    isFullStackProject
                      ? StringConstants.FULLSTACK_URL
                      : isSalesforceProject
                        ? StringConstants.SALESFORCE_URL
                        : StringConstants.MULESOFT
                  }
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ marginLeft: '4px' }}
                >
                  Learn more
                </Link>
              </>
            )
          }
        />
      )}
      <ConfirmationPopup
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title='Severity Type'
        loading={loading}
        primaryButtonDisabled={loading}
        secondaryButtonDisabled={loading}
        primaryButtonClick={() => updateSeverity()}
        secondaryButtonClick={() => {
          setModalOpen(false)
        }}
        primaryButtonText='Yes'
        secondaryButtonText='No'
        content={
          <div className='flex flex-col w-full gap-y-[24px] items-center justify-center '>
            <div>
              <DDSTypography.Title
                variant='semiBold'
                type='h4'
                className='text-center flex flex-row whitespace-nowrap gap-x-[4px]'
              >
                {isFullStackProject
                  ? 'This will change severity type for selected exceptions to'
                  : 'This will change severity type for selected errors to'}
                <DDSTypography.Title
                  type='h4'
                  variant='semiBold'
                  color={severityColorMap[preference]}
                >
                  {preference}
                </DDSTypography.Title>
              </DDSTypography.Title>
            </div>
          </div>
        }
      />
    </div>
  )
}
export default observer(GroupedExceptionsLogs)
