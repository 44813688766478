import React, { useEffect } from 'react'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom'

import { AccessRoleConstants } from '../../../constants/AccessRoleConstants'
import DocumentHeader from '../../../components/Helmet/DocumentHeader'
import { HeaderConstants } from '../../../constants/HeaderConstants'
import JMDashboard from './JMDashboard'
import JMOverviewLogs from './JMOverviewLogs'
import { ProjectType } from '../../../enum/ProjectType.enum'
import SecureComponent from '../../../components/Layout/SecureComponent'
import StringConstants from '../../../constants/StringConstants'
import { getAllQueryParamsAsObjectFromUrl } from '../../../utils/UrlUtils'
import { getUserDetails } from '../../../api/user/User.service'
import { observer } from 'mobx-react-lite'
import routeConstants from '../../../constants/RouteConstants'
import { useStoreContext } from '../../../store/StoreContext'

const JMRouter: React.FC = () => {
  const store = useStoreContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const allQueryParams = getAllQueryParamsAsObjectFromUrl(location.search)
    setSearchParams({ ...allQueryParams, tab: '1' })

    localStorage.setItem(
      StringConstants.ACTIVE_TAB,
      StringConstants.TAB_VALUES.JOB_MONITORING
    )
    getUserDetails().then((userResponse: any) => {
      const organizationIndex = userResponse.data.organizations.findIndex(
        (org: any) => {
          return org.id === store.userStore.getSelectedOrganization().id
        }
      )
      if (organizationIndex === -1) {
        navigate(routeConstants.UNAUTHORIZED_PAGE)
      }
    })
  }, [])

  return (
    <>
      <DocumentHeader
        header={
          store.scopeStore.getSelectedProject().type ===
          ProjectType.FULL_STACK_ONLY
            ? HeaderConstants.SCHEDULE_JOBS
            : HeaderConstants.APEX_JOBS
        }
      />
      <Routes>
        <>
          <Route index element={<JMDashboard />} />
          <Route path=':id' element={<JMOverviewLogs />} />
        </>
      </Routes>
    </>
  )
}

export default SecureComponent(
  observer(JMRouter),
  AccessRoleConstants.MEMBER_LEVEL
)
